import { useGetOverviewMerchantsQuery } from "../api/adminControllerApi";

export const useGetOverviewMerchants = (params: string) => {
  const paramsReplacedPartnersToPartner = params.replace(
    "partnerIds",
    "partnerId"
  );

  const { data, error, isLoading, isSuccess, status } =
    useGetOverviewMerchantsQuery(paramsReplacedPartnersToPartner);

  return {
    overviewMerchantsData: data,
    error,
    isLoading,
    isSuccess,
    status,
  };
};
