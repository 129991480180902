import { useGetTransactionAggregateAdminQuery } from "../api/transactionApi";

export const useGetTransactionsAggregate = (
  searchParams: URLSearchParams,
  limitFilter: string
) => {
  validateSearchParamsTypes(searchParams);

  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess } =
    useGetTransactionAggregateAdminQuery(
      validateSearchParamsTypes(searchParams),
      {
        skip: skipRequest,
      }
    );

  const transactionsAggregateData = data?.length ? data : [];
  //for pagination check
  let dataWithoutLastItem = [...transactionsAggregateData];
  limitFilter && dataWithoutLastItem.splice(parseInt(limitFilter) - 1, 1);

  return {
    transactionsAggregateData,
    dataWithoutLastItem,
    data,
    error,
    isLoading,
    isSuccess,
    requestStatus: status,
  };
};

const validateSearchParamsTypes = (searchParams: URLSearchParams) => {
  let params = searchParams.toString();
  if (!searchParams.get("types")) {
    transactionTypes.forEach((type) => {
      params += `&types=${type}`;
    });
  }
  return params;
};

const transactionTypes = [
  "CANCEL",
  "CHARGEBACK",
  "MANUAL",
  "PAYMENT",
  "REFUND",
];
