import {Form as AntForm, Select, Row, Col, Divider, Checkbox, InputNumber} from "antd";
import {IMerchantPricingModelsFormPart} from "./types";
import React, {useState} from "react";
import {parseStringToNumber} from "../../utils/isNumeric";

export const MerchantPricingModelsTab: React.FC<IMerchantPricingModelsFormPart> = ({
  validator,
  initialData
}) => {



    const [inStoreEnabled, setInStoreEnabled] = useState<boolean>(true);
    const [onlineEnabled, setOnlineEnabled] = useState<boolean>(false);
    const [onlineGlobalEnabled, setOnlineGlobalEnabled] = useState<boolean>(false);

    type FeeType = "BLENDED_FEE" | "DETAILED_FEE";
    enum Fee {
        BLENDED_FEE = "BLENDED_FEE",
        DETAILED_FEE = "DETAILED_FEE",
    }

    const IN_STORE_PERCENTAGE_FEE = 1.5;
    const ONLINE_PERCENTAGE_FEE = 2;

    const BLENDED_FIX_FEE = 0;
    const DETAILED_FIX_FEE = 0.1;

    enum Currency {
        GBP = "GBP",
        EUR = "EUR",
        USD = "USD",
    }

    // const initialValues: IMerchantPricingModelsFormPart = {
    //     pmCurrency : Currency.GBP,
    //     pmInStore: {
    //         enabled: inStoreEnabled,
    //         feeTxPercentage: 1.5,
    //         feeTxFix: BLENDED_FIX_FEE,
    //     },
    //     pmOnline: {
    //         enabled: onlineEnabled,
    //         feeTxPercentage: 2,
    //         feeTxFix: DETAILED_FIX_FEE,
    //     },
    //     pmOnlineGlobal: {
    //         enabled: onlineGlobalEnabled,
    //     },
    // };


    const [inStoreFeeType, setInStoreFeeType] = useState<FeeType>(Fee.BLENDED_FEE);
    const [onlineFeeType, setOnlineFeeType] = useState<FeeType>(Fee.DETAILED_FEE);

    const [inStorePercentageFee, setInStorePercentageFee] = useState<number>(IN_STORE_PERCENTAGE_FEE);
    const [onlinePercentageFee, setOnlinePercentageFee] = useState<number>(ONLINE_PERCENTAGE_FEE);

    const [inStoreFixFee, setInStoreFixFee] = useState<number>(BLENDED_FIX_FEE);
    const [onlineFixFee, setOnlineFixFee] = useState<number>(DETAILED_FIX_FEE);


    const pmCurrencyInit = initialData?.pmCurrency ? initialData.pmCurrency : Currency.GBP;
    let pmInStoreEnabledInit = initialData?.pmInStore?.enabled ? initialData.pmInStore.enabled : inStoreEnabled;
    const pmInStoreFeeTypeInit = initialData?.pmInStore?.feeType ? initialData.pmInStore.feeType : Fee.BLENDED_FEE;
    const pmInStoreFeeTxPercentageInit = initialData?.pmInStore?.feeTxPercentage ? initialData.pmInStore.feeTxPercentage : inStorePercentageFee;
    const pmInStoreFeeTxFixInit = initialData?.pmInStore?.feeTxPercentage ? initialData.pmInStore.feeTxPercentage : inStoreFixFee;

    const pmOnlineEnabledInit = initialData?.pmOnline?.enabled ? initialData.pmOnline.enabled : onlineEnabled;
    const pmOnlineFeeTypeInit = initialData?.pmOnline?.feeType ? initialData.pmOnline.feeType : Fee.DETAILED_FEE;
    const pmOnlineFeeTxPercentageInit = initialData?.pmOnline?.feeTxPercentage ? initialData.pmOnline.feeTxPercentage : onlinePercentageFee;
    const pmOnlineFeeTxFixInit = initialData?.pmOnline?.feeTxPercentage ? initialData.pmOnline.feeTxPercentage : onlineFixFee;

    const pmOnlineGlobalEnabledInit = initialData?.pmOnlineGlobal?.enabled ? initialData.pmOnlineGlobal.enabled : onlineGlobalEnabled;


    // const handleInStoreEnable = (enabled : boolean) => {
    //     console.log("CHECKED :" + enabled);
    //     setInStoreEnabled(enabled);
    //
    // }

    const handleChangeInStoreFeeType = (value: FeeType) => {
        if (value === Fee.BLENDED_FEE) {
            setInStoreFixFee(BLENDED_FIX_FEE);
        } else if (value === Fee.DETAILED_FEE) {
            setInStoreFixFee(DETAILED_FIX_FEE);
        }

        setInStoreFeeType(value);
    };

    const handleChangeOnlineFeeType = (value: FeeType) => {

        if (value === Fee.BLENDED_FEE) {
            setOnlineFixFee(BLENDED_FIX_FEE);

        } else if (value === Fee.DETAILED_FEE) {
            setOnlineFixFee(DETAILED_FIX_FEE);

        }

        setOnlineFeeType(value);
    };

    return (

        <Row>
        <Col span={24}>
            Product & Pricing

            <Row justify="space-between" align="bottom" gutter={5}>
                <Divider orientation={"left"}>
                    Processing currency
                </Divider>

                <Col span={24}>
                    <AntForm.Item label="Select currency" name={"pmCurrency"} initialValue={pmCurrencyInit}>

                        <Select>

                            <Select.Option
                                key={Currency.GBP}
                                value={Currency.GBP}
                            >
                                UK Pounds (GBP)
                            </Select.Option>
                            <Select.Option
                                key={Currency.EUR}
                                value={Currency.EUR}
                            >
                                Euro (EUR)
                            </Select.Option>
                            <Select.Option
                                key={Currency.USD}
                                value={Currency.USD}
                            >
                                US Dollars (USD)
                            </Select.Option>
                        </Select>
                    </AntForm.Item>
                </Col>
            </Row>

            <Row justify="space-between" align="bottom" gutter={5}>
                <Divider orientation={"left"}>

                        InStore UK+EU / Visa & MasterCard

                </Divider>
                <Col span={2}>

                    <AntForm.Item id={"pmInStoreEnabled"} name={["pmInStore", "enabled"]} valuePropName="checked" initialValue={pmInStoreEnabledInit}>

                        <Checkbox
                            checked={pmInStoreEnabledInit}
                            onChange={(event) =>
                                setInStoreEnabled(event.target.checked)
                            }
                        />


                    </AntForm.Item>
                </Col>
                <Col span={10}>
                    <AntForm.Item label="Pricing model" name={["pmInStore", "feeType"]} initialValue={pmInStoreFeeTypeInit}>
                        <Select
                            onChange={handleChangeInStoreFeeType}
                        >
                            <Select.Option
                                key={Fee.BLENDED_FEE}
                                value={Fee.BLENDED_FEE}
                            >
                                Blended rate
                            </Select.Option>
                            <Select.Option
                                key={Fee.DETAILED_FEE}
                                value={Fee.DETAILED_FEE}
                            >
                                IC++
                            </Select.Option>
                        </Select>
                    </AntForm.Item>
                </Col>
                {inStoreFeeType === Fee.BLENDED_FEE && (
                    <Col span={11}>
                        <AntForm.Item label="Per cent fee" name={["pmInStore", "feeTxPercentage"]} initialValue={pmInStoreFeeTxPercentageInit}>
                            <InputNumber
                                step={0.01}
                                max={5}
                                min={1.2}
                                formatter={(value) => `${parseStringToNumber(value).toFixed(2)}%`}
                                parser={(value) => value!.replace("%", "")}
                            />
                        </AntForm.Item>
                    </Col>
                )}
                {inStoreFeeType === Fee.DETAILED_FEE && (
                    <Col span={11}>
                        <Row
                            justify="space-between"
                            align="bottom"
                            gutter={5}
                        >
                            <Col span={11}>
                                <AntForm.Item label="Per cent fee" name={["pmInStore", "feeTxPercentage"]} initialValue={pmInStoreFeeTxPercentageInit}>
                                    <InputNumber
                                        step={0.01}
                                        max={100}
                                        min={0}
                                        formatter={(value) => `${parseStringToNumber(value).toFixed(2)}%`}
                                        parser={(value) => value!.replace("%", "")}
                                    />
                                </AntForm.Item>
                            </Col>
                            <Col span={11}>
                                <AntForm.Item label="Auth fee" name={["pmInStore", "feeTxFix"]} initialValue={pmInStoreFeeTxFixInit}>
                                    <InputNumber
                                        step={0.01}
                                        max={100}
                                        min={0}
                                        formatter={(value) => `${parseStringToNumber(value).toFixed(2)}%`}
                                        parser={(value) => value!.replace("%", "")}
                                    />
                                </AntForm.Item>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>

            <Row justify="space-between" align="bottom" gutter={5}>
                <Divider orientation={"left"}>

                        Online UK+EU Visa MasterCard e-Com Payments

                </Divider>
                <Col span={2}>
                    <AntForm.Item name={["pmOnline", "enabled"]} valuePropName="checked" initialValue={pmOnlineEnabledInit}>
                        <Checkbox
                            checked={pmOnlineEnabledInit}
                            onChange={(event) =>
                                setOnlineEnabled(event.target.checked)
                            }
                        />
                    </AntForm.Item>
                </Col>
                <Col span={10}>
                    <AntForm.Item label="Pricing model" name={["pmOnline", "feeType"]} initialValue={pmOnlineFeeTypeInit}>
                        <Select
                            onChange={handleChangeOnlineFeeType}
                        >
                            <Select.Option
                                key={Fee.BLENDED_FEE}
                                value={Fee.BLENDED_FEE}
                            >
                                Blended rate
                            </Select.Option>
                            <Select.Option
                                key={Fee.DETAILED_FEE}
                                value={Fee.DETAILED_FEE}
                            >
                                IC++
                            </Select.Option>
                        </Select>
                    </AntForm.Item>
                </Col>
                {onlineFeeType === Fee.BLENDED_FEE && (
                    <Col span={11}>
                        <AntForm.Item label="Per cent fee" name={["pmOnline", "feeTxPercentage"]} initialValue={pmOnlineFeeTxPercentageInit}>

                            <InputNumber
                                step={0.01}
                                max={100}
                                min={0}
                                formatter={(value) => `${parseStringToNumber(value).toFixed(2)}%`}
                                parser={(value) => value!.replace("%", "")}
                            />
                        </AntForm.Item>
                    </Col>
                )}
                {onlineFeeType === Fee.DETAILED_FEE && (
                    <Col span={11}>
                        <Row
                            justify="space-between"
                            align="bottom"
                            gutter={5}
                        >
                            <Col span={11}>
                                <AntForm.Item label="Per cent fee" name={["pmOnline", "feeTxPercentage"]} initialValue={pmOnlineFeeTxPercentageInit}>
                                    <InputNumber
                                        step={0.01}
                                        max={100}
                                        min={0}
                                        formatter={(value) => `${parseStringToNumber(value).toFixed(2)}%`}
                                        parser={(value) => value!.replace("%", "")}
                                    />
                                </AntForm.Item>
                            </Col>
                            <Col span={11}>
                                <AntForm.Item label="Auth fee" name={["pmOnline", "feeTxFix"]} initialValue={pmOnlineFeeTxFixInit}>
                                    <InputNumber
                                        step={0.01}
                                        max={100}
                                        min={0}
                                        formatter={(value) => `${parseStringToNumber(value).toFixed(2)}%`}
                                        parser={(value) => value!.replace("%", "")}
                                    />
                                </AntForm.Item>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>

            <Row justify="space-between" align="bottom" gutter={5}>
                <Divider orientation={"left"}>

                        Online Global e-Com Payments AMEX DCI JCB UPI 80+ APMs

                </Divider>
                <Col span={2}>
                    <AntForm.Item name={["pmOnlineGlobal", "enabled"]} valuePropName="checked" initialValue={pmOnlineGlobalEnabledInit}>
                        <Checkbox
                            checked={pmOnlineGlobalEnabledInit}
                            onChange={(event) =>
                                setOnlineGlobalEnabled(event.target.checked)
                            }
                        />
                    </AntForm.Item>

                </Col>


            </Row>

        </Col>
    </Row>
    );
};
