import * as yup from "yup";
import { generalSchema } from "./general";
import { profileSchema } from "./profile";

export const merchantEditSchema = yup.object().concat(generalSchema);
// .concat(profileSchema);

export const yupSyncMerchantEdit = {
  async validator({ field }, value) {
    await merchantEditSchema.validateSyncAt(field, { [field]: value });
  },
};
