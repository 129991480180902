import { createApi } from "@reduxjs/toolkit/query/react";
import { EmailTestResponse } from "../types/emailTestTypes";
import {emailTestQuery} from "../../../services/api/config";


export const emailTestApi = createApi({
    reducerPath: "emailTestApi",
    baseQuery: emailTestQuery,
    tagTypes: ["emailTestTag"],
    endpoints: (builder) => ({

        emailTest: builder.mutation<EmailTestResponse[], string>({
            query: (credential) => ({
                url: "/test/email",
                body: credential,
                method: "POST",
            }),
            invalidatesTags: (result) => (result ? ["emailTestTag"] : []),
        }),

    }),
});
export const {
    useEmailTestMutation,
} = emailTestApi;
