import React, { useEffect } from "react";
import { Button, Col, Row } from "antd";
import { useSearchParams } from "react-router-dom";
import { FilterOutlined } from "@ant-design/icons";
import { SearchTableFilter } from "../../../features/filters";
import { useChangeSearchParams } from "../../../shared/hooks/useChangeSearchParams";
import { PartnersFindFilter } from "../../../components/PartnerFindFilter/PartnersFindFilter";
import { ICrmGetParams } from "../types";
import { RangePickerFilter } from "../../../features/filters/RangePickerFilter";

export const Filters: React.FC = () => {
  let [searchParams] = useSearchParams();

  const { setInitialParams, resetToInitialParams } = useChangeSearchParams();

  const initialFilters = {};

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [searchParams]);

  return (
    <>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row>
            <Col xxl={8} xl={10} lg={14}>
              <Row gutter={20}>
                <Col span={12}>
                  <RangePickerFilter />
                </Col>
                <Col span={12}>
                  <PartnersFindFilter />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col xxl={8} xl={10} lg={14}>
              <Row gutter={20}>
                <Col span={12}>
                  <SearchTableFilter<ICrmGetParams>
                    placeholder={"Search by name"}
                    filterName={"companyName"}
                  />
                </Col>
                <Col span={12}>
                  <SearchTableFilter<ICrmGetParams>
                    placeholder={"Search by email"}
                    filterName={"email"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Button
                icon={<FilterOutlined />}
                style={{ marginRight: 15 }}
                onClick={() => resetToInitialParams(initialFilters)}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
