import { notification } from "antd";
import { useRunStatementPullerMutation } from "../api/statementIndexesApi";

export const useRunStatementIndexesPuller = (
  statementIndexesRefetch: () => void
) => {
  const [runStatementPuller, { data, isLoading }] =
    useRunStatementPullerMutation();

  const onRunStatementIndexesPuller = () => {
    runStatementPuller("").then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Run statement indexes success",
          type: "success",
        });
        statementIndexesRefetch();
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    onRunStatementIndexesPuller,
    isLoading,
  };
};
