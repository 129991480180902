import { crateApiPath } from "../../../services/api/config";

export const downloadApi = (id: number, token: string | null) => {
  return fetch(`${crateApiPath}/payouts/download-statement/${id}`, {
    method: "GET",
    headers: new Headers({
      authorization: `Bearer ${token}`,
    }),
  });
};
