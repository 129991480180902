import React, { useMemo } from "react";
import { ITransactionResponse } from "../../data-layer/transaction/types/ITransaction";
import { Button, TablePaginationConfig } from "antd";
import { Styled as TableStyled } from "shared/style";
import getSymbolFromCurrency from "currency-symbol-map";
import { Link, useSearchParams } from "react-router-dom";
import { SorterResult } from "antd/es/table/interface";
import { IMerchantResponse } from "../../data-layer/merchant/types/merchantTypes";
import { useHandleSortColumn } from "../../common/hooks/useHandleSortColumn";
import { MerchantFindFilter } from "../../components/MerchantFindFilter/MerchantFindFilter";
import { SearchOutlined } from "@ant-design/icons";
import { SearchTableFilter } from "../../components/SearchTableFilter";
import { TRANSACTIONS_TYPES_MAP } from "../../common/constants/constants";
import { MultiselectTableFilter } from "../../components/MultiselectTableFilter";
import { RangeFilter } from "../../components/RangeFilter";

interface ITableProps {
  tableData: ITransactionResponse[];
  isLoading: boolean;
  requestStatus: string;
  handleLoadMoreTransactions: () => void;
  onRowClick: (payoutInfo: ITransactionResponse) => void;
  pagination: boolean;
}

export const Table: React.FC<ITableProps> = ({
  tableData,
  isLoading,
  requestStatus,
  handleLoadMoreTransactions,
  onRowClick,
  pagination,
}) => {
  let [searchParams] = useSearchParams();

  const { setDefaultColumnsSort, handleSortColumn } = useHandleSortColumn();

  const sortFilter = searchParams.get("orderBy[0].field");
  const sortDirectionFilter = searchParams.get("orderBy[0].direction");

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, any>,
    sorter: SorterResult<any>
  ) => {
    const { field, order } = sorter;
    // @ts-ignore
    handleSortColumn(field, order);
  };

  const columnsWithSort = useMemo(
    () =>
      setDefaultColumnsSort(
        sortFilter || "",
        sortDirectionFilter || "",
        columns
      ),
    []
  );

  return (
    <>
      {/*@ts-ignore*/}
      <TableStyled.Table
        loading={requestStatus === "pending" || isLoading}
        columns={columnsWithSort}
        dataSource={tableData}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
        onChange={handleTableChange}
        onRow={(record) => {
          return {
            onClick: (event) => onRowClick(record),
          };
        }}
      />
      {!isLoading && tableData.length > 0 && pagination && (
        <Button
          onClick={handleLoadMoreTransactions}
          loading={requestStatus === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more transactions
        </Button>
      )}
    </>
  );
};

const columns = [
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    sorter: true,
    render: (info: string, record: ITransactionResponse) =>
      `${info} ${getSymbolFromCurrency(record.currency)}`,
    filterDropdown: ({ confirm }) => (
      <div style={{ padding: 8, width: 300 }}>
        <RangeFilter
          filterNameFirst={"amountMin"}
          filterNameSecond={"amountMax"}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  },
  {
    title: "DateTime",
    dataIndex: "dateTime",
    key: "dateTime",
    sorter: true,
  },
  {
    title: "Merchant",
    dataIndex: "merchant",
    key: "merchant",
    sorter: true,
    render: (info: IMerchantResponse) => (
      <Link to={`/dashboard/merchant-edit/${info.id}`}>
        {info.merchantName}
      </Link>
    ),
    filterDropdown: ({ confirm }) => (
      <div style={{ padding: 8, width: 400 }}>
        <MerchantFindFilter onSelect={confirm} />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  },
  {
    title: "Employee name",
    dataIndex: "employeeName",
    key: "employeeName",
    sorter: true,
    filterDropdown: ({ confirm, close }) => (
      <div style={{ padding: 8, width: 250 }}>
        <SearchTableFilter
          placeholder={"Search by employee name"}
          filterName={"employeeName"}
          onSearch={() => {
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    filterDropdown: ({ confirm }) => (
      <div style={{ padding: 8, width: 400 }}>
        <MultiselectTableFilter
          placeholder={"Filter by type"}
          filterName={"types"}
          optionItems={TRANSACTIONS_TYPES_MAP}
          name={"name"}
          valueName={"value"}
        />
      </div>
    ),
  },
];
