import React from "react";
import { Button } from "antd";
import { Styled as TableStyled } from "shared/style";
import { ITerminalsResponse } from "../../data-layer/terminals/types/ITerminals";

interface ITableProps {
  tableData: ITerminalsResponse[];
  isLoading: boolean;
  requestStatus: string;
  handleLoadMoreTerminals: () => void;
  pagination: boolean;
}

export const Table: React.FC<ITableProps> = ({
  tableData,
  isLoading,
  requestStatus,
  handleLoadMoreTerminals,
  pagination,
}) => {
  return (
    <>
      <TableStyled.Table
        loading={requestStatus === "pending" || isLoading}
        columns={columns}
        dataSource={tableData}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
      />
      {!isLoading && tableData.length > 0 && pagination && (
        <Button
          onClick={handleLoadMoreTerminals}
          loading={requestStatus === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more terminals
        </Button>
      )}
    </>
  );
};

export const columns = [
  {
    title: "ID",
    dataIndex: "Id",
    key: "Id",
  },
  {
    title: "Activation date",
    dataIndex: "activationDate",
    key: "activationDate",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Cct tid",
    dataIndex: "cctTid",
    key: "cctTid",
  },
  {
    title: "Country numeric code",
    dataIndex: "countryNumericCode",
    key: "countryNumericCode",
  },
  {
    title: "DeviceId",
    dataIndex: "deviceId",
    key: "deviceId",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Employee status",
    dataIndex: "employeeStatus",
    key: "employeeStatus",
  },

  {
    title: "Kernel profile ID",
    dataIndex: "kernelProfileId",
    key: "kernelProfileId",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "deviceId",
  },
  {
    title: "Serial number",
    dataIndex: "serialNumber",
    key: "serialNumber",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Store profile ID",
    dataIndex: "storeProfileId",
    key: "storeProfileId",
  },
  {
    title: "Surname",
    dataIndex: "surname",
    key: "surname",
  },
  {
    title: "Tid",
    dataIndex: "tid",
    key: "tid",
  },
  {
    title: "Vac device ID",
    dataIndex: "vacDeviceId",
    key: "vacDeviceId",
  },
];
