import { notification } from "antd";
import { useUpdateUserMutation } from "../api/userApi";
import { IUserResponse } from "../types/IUser";

export const useSetStatusEmployee = () => {
  const [updateUser] = useUpdateUserMutation();

  const onBlockUser = (block: boolean, data: IUserResponse) => {
    const userData = {
      ...data,
      status: block ? "SUSPENDED" : "ACTIVATED",
    };

    const notifyStatus = block ? "blocked" : "unblocked";

    updateUser(userData).then((info) => {
      if ("data" in info) {
        notification.open({
          message: `User has been successfully ${notifyStatus}`,
          type: "success",
        });
      } else if ("error" in info) {
        notification.open({
          message: "Something went wrong",
          type: "error",
        });
      }
    });
  };

  return {
    onBlockUser,
  };
};
