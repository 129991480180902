import React, { useEffect } from "react";
import { Button, Col, DatePicker, Row, Select } from "antd";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import {
  FIRST_DATE_MOMENT_FORMAT,
  LAST_DATE_MOMENT_FORMAT,
  TRANSACTIONS_TYPES_MAP,
} from "../../common/constants/constants";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import utc from "dayjs/plugin/utc";
import { MerchantFindFilter } from "../../components/MerchantFindFilter/MerchantFindFilter";
import { MultiselectTableFilter } from "../../components/MultiselectTableFilter";
import { FilterOutlined } from "@ant-design/icons";
import { PartnersFindFilter } from "../../components/PartnerFindFilter/PartnersFindFilter";
import { DateQuickFilters } from "../../components/DateQuickFilters";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

export const Filters: React.FC = () => {
  const { RangePicker } = DatePicker;

  let [searchParams] = useSearchParams();

  const { changeSearchParams, setInitialParams, resetToInitialParams } =
    useChangeSearchParams();

  const initialFilters = {
    from: dayjs().utc().subtract(30, "days").format(FIRST_DATE_MOMENT_FORMAT),
    to: dayjs().utc().format(LAST_DATE_MOMENT_FORMAT),
    merchantIds: "",
    limit: 11,
    offset: 0,
    amountMax: "",
    amountMin: "",
  };

  const dateFromFilter = searchParams.get("from");
  const dateToFilter = searchParams.get("to");

  const rangeFilter = [
    dateFromFilter && dayjs(dateFromFilter).utc(),
    dateToFilter && dayjs(dateToFilter).utc(),
  ];

  const handleDateChange = (momentRange: any) => {
    if (momentRange === null) {
      changeSearchParams({
        from: "",
        to: "",
      });
    }
    changeSearchParams({
      from: momentRange[0].utc().format(FIRST_DATE_MOMENT_FORMAT),
      to: momentRange[1].utc().format(LAST_DATE_MOMENT_FORMAT),
    });
  };

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [searchParams]);

  return (
    <>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row>
            <Col xxl={8} xl={10} lg={14}>
              <Row gutter={25}>
                <Col span={12}>
                  <MerchantFindFilter />
                </Col>
                <Col span={12}>
                  <RangePicker
                    format={"DD MMM"}
                    onChange={handleDateChange}
                    value={rangeFilter}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col xxl={8} xl={10} lg={14}>
              <Row gutter={25}>
                <Col span={12}>
                  <MultiselectTableFilter
                    placeholder={"Filter by type"}
                    filterName={"types"}
                    optionItems={TRANSACTIONS_TYPES_MAP}
                    name={"name"}
                    valueName={"value"}
                  />
                </Col>
                <Col span={12}>
                  <PartnersFindFilter />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <DateQuickFilters />
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Button
                icon={<FilterOutlined />}
                style={{ marginRight: 15 }}
                onClick={() => resetToInitialParams(initialFilters)}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
