import styled from "styled-components";
import { Card } from "antd";

const getHeightForCard = (size?: "small" | "large") => {
  let result = "auto";
  if (size === "small") result = "320px";
  else if (size === "large") result = "655px";
  return result;
};

const CardWrapper = styled(Card)`
  border-radius: 10px;
  margin-bottom: 15px;
  height: ${(props) => getHeightForCard(props.size)};
  padding-bottom: 10px;
  background: aliceblue;
  overflow: scroll;
  border: 2px solid #e8eaed;
`;

export const Styled = {
  CardWrapper,
};
