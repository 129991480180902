import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../services/api/config";
import { IMerchantResponse } from "../types/merchantTypes";
import { IMerchantDetail } from "scenarios/merchant/types";

export const merchantApi = createApi({
  reducerPath: "merchantApi",
  baseQuery,
  endpoints: (builder) => ({
    findMerchants: builder.mutation<IMerchantResponse[], string>({
      query: (params) => ({
        url: `/merchants/find?${params}&limit=20&offset=0`,
        method: "GET",
      }),
    }),
    updateMerchantsData: builder.mutation<IMerchantResponse, IMerchantResponse>(
      {
        query: (params) => ({
          url: `/merchants/admin`,
          method: "PUT",
          body: params,
        }),
      }
    ),
    getMerchantById: builder.query<IMerchantResponse, string>({
      query: (params) => ({
        url: `/merchants/${params}`,
        method: "GET",
      }),
    }),
    findMerchantById: builder.mutation<IMerchantResponse, string>({
      query: (params) => ({
        url: `/merchants/${params}`,
        method: "GET",
      }),
    }),
    getMerchants: builder.query<IMerchantResponse[], string>({
      query: (params) => {
        if (!params) {
          params = "limit=20&offset=0";
        }

        return {
          url: `/merchants/find?${params}`,
        }
      },
    }),
    getMerchantDetailById: builder.query<IMerchantDetail, string>({
      query: (params) => ({
        url: `/merchants/${params}`,
        method: "GET",
      }),
    }),
    createMerchant: builder.mutation<number, IMerchantDetail>({
      query: (params) => ({
        url: `/merchants/create`,
        method: "POST",
        body: params,
      }),
    }),
    updateMerchant: builder.mutation<IMerchantDetail, IMerchantDetail>({
      query: (params) => ({
        url: `/merchants/admin`,
        method: "PUT",
        body: params,
      }),
    }),
    reCreateMSA: builder.mutation<{}, {}>({
      query: (params) => ({
        url: `/merchants/recreate-msa?merchantId=${params}`,
        method: "POST",
      }),
    }),
  }),
});
export const {
  useFindMerchantsMutation,
  useFindMerchantByIdMutation,
  useGetMerchantsQuery,
  useGetMerchantByIdQuery,
  useUpdateMerchantsDataMutation,
  useGetMerchantDetailByIdQuery,
  useCreateMerchantMutation,
  useUpdateMerchantMutation,
  useReCreateMSAMutation,
} = merchantApi;
