import { ICountries } from "../../../shared/api/dictionary/types";

export enum crmStatuses {
  NONE = "NONE",
  INVITED = "INVITED",
  SIGNED_UP = "SIGNED_UP",
  APPLICATION_SUBMITTED = "APPLICATION_SUBMITTED",
  APPLICATION_APPROVED = "APPLICATION_APPROVED",
  APPLICATION_REJECTED = "APPLICATION_REJECTED",
  FIRST_EMPLOYEE_SETUP_DONE = "FIRST_EMPLOYEE_SETUP_DONE",
  FIRST_TRANSACTION_DONE = "FIRST_TRANSACTION_DONE",
  FIRST_PAYOUT_DONE = "FIRST_PAYOUT_DONE",
}

export interface ICrmGetParams {
  partnerIds: string;
  email: string;
  companyName: string;
  from: string;
  to: string;
}

export interface ICrmResponse {
  companyName: string;
  country: ICountries;
  crmStatus: crmStatuses;
  partnerName: string;
  userEmail: string;
  userId: 0;
}
