import styled from "styled-components";

const InfoBlockContainer = styled.div`
  padding: 15px 20px;
  background: #fff1f0;
`;

const InfoCount = styled.p`
  font-size: 38px;
  line-height: 46px;
  font-weight: 400;
  margin-bottom: 0;
`;

const InfoText = styled.p`
  font-size: 20px;
  line-height: 28px;
  color: #8c8c8c;
`;

const InfoTitle = styled.h2`
  font-size: 25px;
  line-height: 35px;
`;

const InfoItemsWrapper = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 25px;
`;

const PartnerInfoWrapper = styled.div`
  margin-bottom: 64px;
  margin-top: 30px;
`;

export const Styled = {
  InfoBlockContainer,
  InfoCount,
  InfoText,
  InfoItemsWrapper,
  InfoTitle,
  PartnerInfoWrapper,
};
