import React, { useEffect, useState } from "react";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { Input } from "antd";
import { useSearchParams } from "react-router-dom";

interface ISearchTableFilter<T> {
  placeholder: string;
  filterName: keyof T;
  onSearch?: () => void;
}

export const SearchTableFilter = <T,>({
  placeholder,
  filterName,
  onSearch,
}: ISearchTableFilter<T>) => {
  const { changeSearchParams } = useChangeSearchParams();
  let [searchParams] = useSearchParams();

  const handleSearchFilter = (search: string) => {
    changeSearchParams({ [filterName]: search });
    onSearch && onSearch();
  };

  const searchFilter = searchParams.get(filterName as string);

  const [search, setSearch] = useState(searchFilter || undefined);

  useEffect(() => {
    setSearch(searchFilter as string);
  }, [searchFilter]);

  const { Search } = Input;

  return (
    <Search
      allowClear
      onKeyDown={(e) => e.stopPropagation()}
      placeholder={placeholder}
      onSearch={handleSearchFilter}
      enterButton={false}
      defaultValue={searchFilter || ""}
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />
  );
};
