import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
// import { HelmetProvider } from "react-helmet-async";

import { Spin } from "antd";
import { AppRouter } from "../routes/AppRouter";
import { Dev } from "./dev";

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <button
        className="mt-4"
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresh
      </button>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  //todo сделать нормальный лоадинг на каждый компонент
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spin />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {/*<HelmetProvider>*/}
        <Dev>{children}</Dev>
        <AppRouter />
        {/*</HelmetProvider>*/}
      </ErrorBoundary>
    </React.Suspense>
  );
};
