import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";
import {
  IApplicationReviewRequest,
  IApplicationResponse,
  IApplicationPersistRequest,
} from "../types/applicationTypes";
import moment from "moment";

export const applicationApi = createApi({
  reducerPath: "applicationApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getApplications: builder.query<IApplicationResponse[], string>({
      query: () => `/application`,
    }),
    getApplicationProfileById: builder.query<IApplicationResponse[], string>({
      query: (id) => `/application/profile/${id}`,
      transformResponse: (response: IApplicationResponse[]) => {
        if (response && response.length) {
          response[0].data = {
            ...response[0].data,
            REGISTRATION_DATE: moment(response[0].data["REGISTRATION_DATE"]),
          };
        }
        return response;
      },
    }),
    getApplicationById: builder.query<IApplicationResponse, string>({
      query: (id) => `/application/admin/${id}`,
    }),
    postApplicationReview: builder.mutation<
      IApplicationResponse,
      IApplicationReviewRequest
    >({
      query: (credentials) => ({
        url: `/application/review`,
        method: "POST",
        body: credentials,
      }),
    }),
    postApplicationPersist: builder.mutation<
      IApplicationResponse,
      IApplicationPersistRequest
    >({
      query: (credentials) => ({
        url: `/application/persist/admin`,
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});
export const {
  useGetApplicationsQuery,
  useGetApplicationProfileByIdQuery,
  useGetApplicationByIdQuery,
  usePostApplicationReviewMutation,
  usePostApplicationPersistMutation,
} = applicationApi;
