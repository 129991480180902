import {
  useReconciliationPayoutsMutation,
  useReconciliationPayoutsPartnerMutation,
} from "../api/payoutApi";
import { notification } from "antd";

export const useCreatePayout = (payoutRefetch: () => void) => {
  const [createPayout] = useReconciliationPayoutsMutation();
  const [createPayoutPartner] = useReconciliationPayoutsPartnerMutation();

  const onSubmitCreatePayoutMerchant = () => {
    createPayout().then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Success",
          type: "success",
        });
        payoutRefetch();
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  const onSubmitCreatePayoutPartner = () => {
    createPayoutPartner().then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Success",
          type: "success",
        });
        payoutRefetch();
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    onSubmitCreatePayoutMerchant,
    onSubmitCreatePayoutPartner,
  };
};
