import { useCreateLocaleMutation } from "../api/i18settingsApi";
import { notification } from "antd";
import { I18settingsResponse } from "../types/i18settingsTypes";

export const useCreateLocale = () => {
  const [createLocale] = useCreateLocaleMutation();

  const onCreateLocale = (
    params: I18settingsResponse,
    clearForm: (fields?: (string | number)[] | undefined) => void
  ) => {
    createLocale(params).then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Locale created",
          type: "success",
        });
        clearForm();
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    onCreateLocale,
  };
};
