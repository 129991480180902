import React from "react";
import { Form, Modal, Button, Space, Radio } from "antd";
import { IPartnerResponse } from "../../data-layer/partner/types/partnerTypes";

interface IConnectPartnerModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSubmit: (data: any) => void;
  partnerList: IPartnerResponse[];
}

export const ConnectPartnerModal: React.FC<IConnectPartnerModal> = ({
  visible,
  setVisible,
  onSubmit,
  partnerList,
}) => {
  return (
    <Modal
      open={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      title="Connect with partner"
    >
      <Form
        onFinish={(data) => {
          onSubmit(data);
          setVisible(false);
        }}
      >
        <Form.Item name={"partner"}>
          <Radio.Group>
            <Space direction="vertical">
              {partnerList.map((item) => (
                <Radio key={item.id} value={item.id}>{item.name}</Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Space>
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button type="primary" htmlType={"submit"}>
              Connect
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};
