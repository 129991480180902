import { AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { useFindMerchants } from "../../data-layer/merchant/hooks/useFindMerchants";
import { useSearchParams } from "react-router-dom";

interface IMerchantFindInput {
  onSelect?: () => void;
  disabled?: boolean;
}

export const MerchantFindFilter: React.FC<IMerchantFindInput> = ({
  onSelect,
  disabled,
}) => {
  const { changeSearchParams } = useChangeSearchParams();
  const { getFindMerchants, searchData } = useFindMerchants();

  let [searchParams] = useSearchParams();

  const merchantIdFilter = searchParams.get("merchantIds");
  const merchantNameFilter = searchData.find(
    (item) => item.key == merchantIdFilter
  )?.value;

  const [merchantValue, setMerchantValue] = useState(merchantNameFilter);

  const onSearch = (value: string) => {
    getFindMerchants(`name=${value}`);
  };

  const onSelectMerchant = (
    res: string,
    { key, value }: { key: number; value: string }
  ) => {
    changeSearchParams({ merchantIds: key });
    onSelect && onSelect();
  };

  const onClearMerchantSelect = () => {
    changeSearchParams({ merchantIds: "" });
  };

  useEffect(() => {
    setMerchantValue(merchantNameFilter);
  }, [merchantNameFilter]);

  useEffect(() => {
    getFindMerchants(`name=`);
  }, []);

  return (
    <AutoComplete
      disabled={disabled}
      allowClear
      value={merchantValue}
      options={searchData}
      onSelect={onSelectMerchant}
      onSearch={onSearch}
      onChange={setMerchantValue}
      placeholder="Enter merchant name"
      style={{ width: "100%" }}
      onClear={onClearMerchantSelect}
    />
  );
};
