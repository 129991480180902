import React from "react";
import { Button, Dropdown, Menu, Switch } from "antd";
import { Styled as TableStyled } from "shared/style";
import { SearchOutlined } from "@ant-design/icons";
import { IEmployeeResponse } from "../types";
import {
  MerchantFindFilter,
  SearchTableFilter,
} from "../../../features/filters";
import { EMPLOYEE_STATUS_MAP } from "../../../shared/constants/statuses";
import { MultiselectTableFilter } from "../../../features/filters";

interface ITableProps {
  tableData: IEmployeeResponse[];
  isLoading: boolean;
  requestStatus: string;
  pagination: boolean;
  handleLoadMoreEmployee: () => void;
  onRowClick: (employeeInfo: IEmployeeResponse) => void;
  onBlockEmployee: (block: boolean, employeeId: number) => void;
}

export const Table: React.FC<ITableProps> = ({
  tableData,
  isLoading,
  requestStatus,
  handleLoadMoreEmployee,
  onRowClick,
  pagination,
  onBlockEmployee,
}) => {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by email"}
            filterName={"emailLike"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by name"}
            filterName={"nameLike"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by surname"}
            filterName={"nameLike"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Merchant name",
      key: "merchantName",
      render: (item) => item.merchant.merchantName,
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8, width: 400 }}>
          <MerchantFindFilter onSelect={confirm} />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8, width: 400 }}>
          <MultiselectTableFilter
            placeholder={"Filter by type"}
            filterName={"statuses"}
            optionItems={EMPLOYEE_STATUS_MAP}
            name={"name"}
            valueName={"value"}
          />
        </div>
      ),
    },

    {
      title: "TID",
      dataIndex: "tid",
      key: "tid",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by tid"}
            filterName={"tid"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      fixed: "right",
      render: (data: IEmployeeResponse) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: 145,
                  }}
                >
                  <span>Block user</span>
                  <Switch
                    defaultChecked={data.status === "SUSPENDED"}
                    onClick={(checked, event) => {
                      onBlockEmployee(checked, data.id);
                      event.stopPropagation();
                    }}
                  />
                </div>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          key={data.id}
        >
          <Button onClick={(event) => event.stopPropagation()}>...</Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <TableStyled.Table
        loading={requestStatus === "pending" || isLoading}
        columns={columns}
        dataSource={tableData}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
        onRow={(record) => {
          return {
            onClick: (event) => onRowClick(record),
          };
        }}
      />
      {!isLoading && tableData.length > 0 && pagination && (
        <Button
          onClick={handleLoadMoreEmployee}
          loading={requestStatus === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more employee
        </Button>
      )}
    </>
  );
};
