import React from "react";
import { Form, Input, Select } from "antd";
import { FormCard } from "../../../../components/FormCard/FormCard";
import { ICountries } from "../../../../data-layer/dictionary/types/IDictionary";

interface IOfficeAddress {
  countries: ICountries[];
}

export const OfficeAddress: React.FC<IOfficeAddress> = ({ countries }) => {
  const { Option } = Select;

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  return (
    <FormCard title={"Office address"} size={"small"}>
      <Form.Item
        name={["officeAddressData", "addressLine1"]}
        id={"officeAddressData.addressLine1"}
        label={"Address line 1"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["officeAddressData", "addressLine2"]}
        id={"officeAddressData.addressLine2"}
        label={"Address line 2"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["officeAddressData", "city"]}
        id={"officeAddressData.city"}
        label={"City"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["officeAddressData", "country"]}
        id={"officeAddressData.country"}
        label={"Country"}
      >
        <Select showSearch filterOption={filterOption} allowClear>
          {countries.map((item) => (
            <Option key={item.callingCode}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["officeAddressData", "postCode"]}
        id={"officeAddressData.postCode"}
        label={"Post code"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["officeAddressData", "postTown"]}
        id={"officeAddressData.postTown"}
        label={"Post town"}
      >
        <Input />
      </Form.Item>
    </FormCard>
  );
};
