import { useGetLocalesQuery } from "../api/i18settingsApi";

export const useGetLocales = (searchParams: string, limitFilter: string) => {
  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess, refetch } =
    useGetLocalesQuery(`${searchParams}`, {
      skip: skipRequest,
    });

  const localesData = data?.length ? data : [];

  let dataWithoutLastItem = [...localesData];
  limitFilter && dataWithoutLastItem.splice(parseInt(limitFilter) - 1, 1);

  return {
    localesData,
    dataWithoutLastItem,
    data,
    error,
    isLoading,
    isSuccess,
    refetch,
    requestStatus: status,
  };
};
