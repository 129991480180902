import { useEffect, useState } from "react";
import { useFindPartnersMutation } from "../api/partnerApi";
import { IPartnerResponse } from "../types/partnerTypes";

export const useFindPartners = () => {
  const [findResultCache, setFindResultCache] = useState<
    { value: string; key: number }[]
  >([]);

  const [getFindPartners, { data }] = useFindPartnersMutation({
    fixedCacheKey: "partnersMutation",
  });

  useEffect(() => {
    if (data !== undefined) {
      setFindResultCache(validateSearchData(data));
    }
  }, [data]);

  const validateSearchData = (data: IPartnerResponse[]) => {
    return data.map((item) => {
      const { id, name } = item;
      return {
        key: id,
        value: name,
      };
    });
  };

  return {
    getFindPartners,
    searchData: findResultCache,
  };
};
