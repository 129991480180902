import { Form, Input, Row, Col, Select, InputNumber } from "antd";
import React from "react";
import { Styled } from "../styled";
import {
  IDictionaryMccResponse,
  IDictionaryResponse,
} from "../../../data-layer/dictionary/types/IDictionary";
import { PhoneInput } from "../../../common/phoneInput";

interface ICompanyDescription {
  dictionaryData: IDictionaryResponse;
  isDisableForm: boolean;
  paymentHistory: { [name: string]: any }[];
  expectedTurnover: { [name: string]: any }[];
  averageTxValueMap: { [name: string]: any }[];
  dictionaryMccData: IDictionaryMccResponse;
  pmOnline?: boolean;
  pmOnlineGlobal?: boolean;
}

export const CompanyDescription: React.FC<ICompanyDescription> = ({
  dictionaryData,
  paymentHistory,
  expectedTurnover,
  averageTxValueMap,
  isDisableForm,
  dictionaryMccData,
  pmOnline,
  pmOnlineGlobal,
}) => {
  const { Option } = Select;
  const filterOption = (input: string, option: any) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <>
      <Styled.SectionTitle>COMPANY DESCRIPTION</Styled.SectionTitle>
      <Row gutter={[110, 0]}>
        <Col span={12}>
          <Form.Item
            label={"Company name :"}
            labelCol={{ span: 24 }}
            name={"COMPANY_NAME"}
            rules={[
              {
                required: true,
                message: "required",
              },
              {
                max: 120,
                message: "length must be less or equal 120",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"DBA (Trading As) :"}
            labelCol={{ span: 24 }}
            name={"DBA"}
            rules={[
              {
                required: true,
                message: "required",
              },
              {
                max: 18,
                message: "length must be less or equal 18",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Company status :"}
            labelCol={{ span: 24 }}
            name={"COMPANY_STATUS"}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Town/City"}
            labelCol={{ span: 24 }}
            name={"CITY"}
            rules={[
              {
                required: true,
                message: "required",
              },
              {
                max: 13,
                message: "length must be less or equal 13",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Primary contact phone number"}
            labelCol={{ span: 24 }}
            name={"PRIMARY_CONTACT_PHONE_NUMBER"}
            rules={[
              {
                max: 20,
                message: "length must be less or equal 20",
              },
            ]}
          >
            {isDisableForm ? (<Input placeholder={"None"} />) : (<PhoneInput country={"gb"} />)}
          </Form.Item>
          <Form.Item
            label={"Nature of business"}
            labelCol={{ span: 24 }}
            name={"TYPE_OF_BUSINESS"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <Select showSearch filterOption={filterOption} placeholder={"None"}>
              {dictionaryData.businessTypes.map((item) => (
                <Option key={item.id}>{item.id}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"Business explanation"}
            labelCol={{ span: 24 }}
            name={"BUSINESS_EXPLANATION"}
          >
            <Input.TextArea placeholder={"Business explanation..."} />
          </Form.Item>
          <Form.Item
            label={"Payment processing history:"}
            labelCol={{ span: 24 }}
            name={"PAYMENT_HISTORY"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <Select showSearch filterOption={filterOption} placeholder={"None"}>
              {paymentHistory.map((item) => (
                <Option key={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"Average transaction value"}
            labelCol={{ span: 24 }}
            name={"AVERAGE_TX_VALUE"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <Select showSearch filterOption={filterOption}>
              {averageTxValueMap.map((item) => (
                <Option key={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"Company number"}
            labelCol={{ span: 24 }}
            name={"COMPANY_NUMBER"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Total Sales"}
            labelCol={{ span: 24 }}
            name={"TOTAL_SALES"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <InputNumber placeholder={"0"} />
          </Form.Item>
          <Form.Item
            label={"Product service sold"}
            labelCol={{ span: 24 }}
            name={"PRODUCT_SERVICE_SOLD"}
            rules={[
              {
                required: true,
                message: "required",
              },
              {
                max: 250,
                message:
                  "Max characters long must be less than or equal to 250",
              },
            ]}
          >
            <Input.TextArea
              placeholder={"Product service sold..."}
              maxLength={250}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={"Registered county"}
            labelCol={{ span: 24 }}
            name={"COUNTY"}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Registered address"}
            labelCol={{ span: 24 }}
            name={"ADDRESS_LINE_1"}
            rules={[
              {
                required: true,
                message: "required",
              },
              {
                max: 50,
                message: "length must be less or equal 50",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Registered address"}
            labelCol={{ span: 24 }}
            name={"ADDRESS_LINE_2"}
            rules={[
              {
                max: 50,
                message: "length must be less or equal 50",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Registered company county"}
            labelCol={{ span: 24 }}
            name={"OFFICE_COUNTY"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Registered company address"}
            labelCol={{ span: 24 }}
            name={"OFFICE_ADDRESS_LINE_1"}
            rules={[
              {
                required: true,
                message: "required",
              },
              {
                max: 50,
                message: "length must be less or equal 50",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Registered company address"}
            labelCol={{ span: 24 }}
            name={"OFFICE_ADDRESS_LINE_2"}
            rules={[
              {
                max: 50,
                message: "length must be less or equal 50",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Postcode"}
            labelCol={{ span: 24 }}
            name={"POSTAL_CODE"}
            rules={[
              {
                required: true,
                message: "required",
              },
              {
                max: 20,
                message: "length must be less or equal 20",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Primary contact first name"}
            labelCol={{ span: 24 }}
            name={"PRIMARY_CONTACT_FIRST_NAME"}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Primary contact last name"}
            labelCol={{ span: 24 }}
            name={"PRIMARY_CONTACT_LAST_NAME"}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Annual turnover"}
            labelCol={{ span: 24 }}
            name={"EXPECTED_TURNOVER"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <Select showSearch filterOption={filterOption}>
              {expectedTurnover.map((item) => (
                <Option key={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Styled.SectionTitle>Terminal information</Styled.SectionTitle>
      <Row gutter={[110, 0]}>
        <Col span={12}>
          <Form.Item
            label={"MCC Code"}
            labelCol={{ span: 24 }}
            name="MCC"
            rules={[
              {
                required: true,
                message: "Please input MCC Code",
              },
              {
                max: 4,
                message: "length must be less or equal 4",
              },
            ]}
          >
            <Select showSearch filterOption={filterOption} placeholder={"None"}>
              {[...new Set(dictionaryMccData?.mccList)].map((item, i) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"Currency"}
            labelCol={{ span: 24 }}
            name="CURRENCY"
            rules={[
              {
                required: true,
                message: "Please input currency",
              },
            ]}
          >
            <Select showSearch filterOption={filterOption} placeholder={"None"}>
              {dictionaryData.currencies.map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
            {(pmOnline || pmOnlineGlobal) && (
                <Form.Item
                    label={"Fiserv ID"}
                    labelCol={{ span: 24 }}
                    name={"FISERV_ID"}
                    rules={[
                        {
                            required: true,
                            message: "required",
                        },
                    ]}

                >
                    <Input placeholder={"None"} max={100}/>
                </Form.Item>
            )}
        </Col>
        <Col span={12}>
          <Form.Item
            label={"Time zone"}
            labelCol={{ span: 24 }}
            name="TIME_ZONE"
            rules={[
              {
                required: true,
                message: "Please input time zone",
              },
            ]}
          >
            <Select showSearch filterOption={filterOption} placeholder={"None"}>
              {dictionaryData.timeZones.map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
