import { duatigaApiPath } from "../../../services/api/config";

export const downloadApi = (downloadPath: string, token: string | null) => {
  return fetch(`${duatigaApiPath}/attachments/download/${downloadPath}`, {
    method: "GET",
    headers: new Headers({
      authorization: `Bearer ${token}`,
    }),
  });
};
