import React from "react";
import { GOOGLE_MAPS_API_KEY } from "../../common/constants/constants";

interface IGoogleMapsStatic {
  width: number;
  height: number;
  zoom?: number;
  lat: string;
  long: string;
}

export const GoogleMapsStatic: React.FC<IGoogleMapsStatic> = ({
  width,
  height,
  zoom,
  lat,
  long,
}) => {
  const src = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${long}&zoom=${
    zoom ? zoom : "13"
  }&size=${width}x${height}&maptype=roadmap&markers=color:red%7Clabel:C%7C${lat},${long}&key=${GOOGLE_MAPS_API_KEY}`;

  const redirectLink = `http://www.google.com/maps/place/${lat},${long}`;

  return (
    <a href={redirectLink} target="_blank">
      <img src={src} alt={"transaction coordinates"} />;
    </a>
  );
};
