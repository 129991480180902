import React from "react";
import { Drawer, Tooltip, Descriptions } from "antd";
import { IPayoutRowInfo } from "./PayoutSC";
import { TransactionsList } from "./TransactionsList";
import { Styled } from "./styles";

interface IPayoutItem {
  visible: boolean;
  onClose: () => void;
  data: IPayoutRowInfo;
  handleApprove: (id: number, onClose: () => void) => void;
  readyToSettlement: boolean;
  beneficiaryTypes: "MERCHANT" | "PARTNER";
}

export const PayoutItem: React.FC<IPayoutItem> = ({
  visible,
  onClose,
  data,
  handleApprove,
  readyToSettlement,
  beneficiaryTypes,
}) => {
  const { amount, id, status, merchantName, created, partner, netAmount } =
    data;

  const TooltipWrapper = readyToSettlement ? React.Fragment : Tooltip;

  const enablePayoutApprove =
    readyToSettlement && status !== "PAID" && status !== "APPLIED";

  const getPartnersDescriptionItems = () => {
    return (
      <>
        <Descriptions.Item label="Id">{id}</Descriptions.Item>
        <Descriptions.Item label="Partner name">
          {partner?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Net amount">{netAmount}</Descriptions.Item>
        <Descriptions.Item label="Status">{status}</Descriptions.Item>
      </>
    );
  };

  const getMerchantsDescriptionItems = () => {
    return (
      <>
        <Descriptions.Item label="Amount">{amount}</Descriptions.Item>
        <Descriptions.Item label="Id">{id}</Descriptions.Item>
        <Descriptions.Item label="Status">{status}</Descriptions.Item>
        <Descriptions.Item label="Merchant name">
          {merchantName}
        </Descriptions.Item>
        <Descriptions.Item label="Created">{created}</Descriptions.Item>
      </>
    );
  };

  return (
    <Drawer
      title="Payout details"
      placement={"right"}
      closable={false}
      onClose={onClose}
      visible={visible}
      width="50%"
      headerStyle={{ border: "none" }}
      // key={placement}
    >
      <Descriptions column={1} bordered style={{ marginBottom: 50 }}>
        {beneficiaryTypes === "MERCHANT"
          ? getMerchantsDescriptionItems()
          : getPartnersDescriptionItems()}
      </Descriptions>

      {beneficiaryTypes === "MERCHANT" && <TransactionsList payoutId={id} />}

      <TooltipWrapper
        placement={"bottom"}
        title={"Merchant did not set up settlement details"}
      >
        <Styled.ApprovePayoutButton
          type={"primary"}
          size={"large"}
          onClick={() => handleApprove(id, onClose)}
          disabled={!enablePayoutApprove}
        >
          Approve
        </Styled.ApprovePayoutButton>
      </TooltipWrapper>
    </Drawer>
  );
};
