import React from "react";
import { Helmet } from "react-helmet";
import { Button, PageHeader, Card, Space, Col } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import * as Yup from "yup";
import { useRunFiservPullerBy } from "data-layer/system/hooks/useRunPullerBy";
import { IRunPullerByRequest } from "data-layer/system/types/ISystem";

export const PullBySchema = Yup.object().shape({
  pathOrFile: Yup.string().required("Required"),
});

const initialValues: IRunPullerByRequest = {
  pathOrFile: "",
};

export const FiservProcessesSc: React.FC = () => {
  const { onRunFiservPullerBy, isLoading: isRunPullerLoading } =
    useRunFiservPullerBy();

  const onSubmit = (value: IRunPullerByRequest) => {
    onRunFiservPullerBy(value);
  };

  return (
    <>
      <Helmet>
        <title>Fiserv Processes</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="Fiserv Processes" />
      <Col span={12}>
        <Card title="Pull By" bordered={false}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={PullBySchema}
          >
            {({ handleSubmit }) => {
              return (
                <>
                  <Form layout={"vertical"}>
                    <Form.Item label="Path or File" name="pathOrFile">
                      <Space.Compact style={{ width: "100%" }}>
                        <Input name="pathOrFile" placeholder={"/path/to/ or /path/to/filename.csv"} />
                        <Button type="primary" loading={isRunPullerLoading} onClick={() => handleSubmit()}>
                          Run puller by
                        </Button>
                      </Space.Compact>
                    </Form.Item>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Card>
      </Col>
    </>
  );
};
