import { useUpdateMerchantsDataMutation } from "../api/merchantApi";
import { IMerchantResponse } from "../types/merchantTypes";
import { notification } from "antd";
import React from "react";

export const useUpdateMerchantsData = () => {
  const [updateMerchants] = useUpdateMerchantsDataMutation();

  const onSaveMerchantForm = (data: IMerchantResponse) => {
    updateMerchants(data).then((info) => {
      if ("data" in info) {
        notification.open({
          message: "Changes saved successfully",
          type: "success",
        });
      } else if ("error" in info) {
        notification.open({
          message: "Something went wrong",
          description: getErrorComponent(
            parseErrorObjectToArray(info.error?.data?.details)
          ),
          type: "error",
        });
      }
    });
  };

  const onBlockMerchant = (block: boolean, data: IMerchantResponse) => {
    const merchantData = {
      ...data,
      status: block ? "SUSPENDED" : "ACTIVATED",
    };

    const notifyStatus = block ? "blocked" : "unblocked";

    updateMerchants(merchantData).then((info) => {
      if ("data" in info) {
        notification.open({
          message: `Merchant has been successfully ${notifyStatus}`,
          type: "success",
        });
      } else if ("error" in info) {
        notification.open({
          message: "Something went wrong",
          description: getErrorComponent(
            parseErrorObjectToArray(info.error?.data?.details)
          ),
          type: "error",
        });
      }
    });
  };

  return {
    onSaveMerchantForm,
    onBlockMerchant,
  };
};

const parseErrorObjectToArray = (errors: { [key: string]: string }) => {
  const result = [];
  for (const errorItem in errors) {
    result.push({
      error: errorItem,
      errorDescription: errors[errorItem],
    });
  }
  return result;
};

const getErrorComponent = (
  errorArr: {
    error: string;
    errorDescription: string;
  }[]
) => {
  return (
    <>
      {errorArr.map((error) => {
        return <p>{`${error.error}: ${error.errorDescription}`}</p>;
      })}
    </>
  );
};
