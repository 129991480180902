import React from "react";
import { Styled } from "./style";

interface IUploadButtonProps {
  handleClick?: VoidFunction;
}

export const UploadButton: React.FC<IUploadButtonProps> = ({ handleClick }) => {
  return (
    <Styled.UploadButton onClick={handleClick} icon={<Styled.UploadIcon />}>
      Upload
    </Styled.UploadButton>
  );
};
