import * as yup from "yup";
import { IPartnerForm } from "data-layer/partner/types/partnerTypes";

const partnerCreateSchema = yup.object<
    Partial<Record<keyof IPartnerForm, yup.AnySchema>>
>({
    name: yup.string().required("Please input Name"),
    address: yup.string().required("Please input Address"),
    feePercent: yup
        .number()
        .typeError("Fee Percent must be number")
        .min(0, "Fee percent can not be lower than 0")
        .max(100, "Fee percent can not be greater that 100")
        .required("Please input Fee Percent"),
    currency: yup.object().shape({
        id: yup.string().required("Please input Currency"),
    }),
    logoImageFilename: yup.string().nullable(),
    settlementDetailsData: yup.object().shape({
        iban: yup.string().nullable(),
        sortCode: yup.string().nullable(),
        bankAccountNumber: yup.string().nullable(),
        bankAccountHolderName: yup.string().nullable(),
    }),
});

export const yupValidator = (
    getFieldsValue: () => IPartnerForm,
) => ({
    async validator({ field }: any) {
        const values = getFieldsValue();
        await partnerCreateSchema.validateAt(field, values);
    },
});