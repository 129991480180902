import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { Button, TablePaginationConfig, Tooltip } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { history } from "routes/AppRouter";
import { Styled } from "../style";
import { useGetProfiles } from "../api/useGetProfiles";
import { useHandleSortColumn } from "shared/hooks/useHandleSortColumn";
import { useChangeSearchParams } from "shared/hooks/useChangeSearchParams";
import {
  SearchTableFilter,
  SelectTableFilter,
} from "../../../features/filters";
import {
  ICountries,
  IDictionaryResponse,
} from "../../../shared/api/dictionary/types";
import { onboardingStatusMap } from "../../../shared/constants/statuses";

interface ITableProps {
  dictionary: IDictionaryResponse;
}

export const Table: React.FC<ITableProps> = ({ dictionary }) => {
  let [searchParams] = useSearchParams();

  const limitFilter = searchParams.get("limit") || "";

  const { data, error, isLoading, status, dataWithoutLastItem } =
    useGetProfiles(searchParams.toString(), limitFilter);

  const { setDefaultColumnsSort, handleSortColumn } = useHandleSortColumn();

  const { changeSearchParams } = useChangeSearchParams();

  const sortFilter = searchParams.get("orderBy[0].field");
  const sortDirectionFilter = searchParams.get("orderBy[0].direction");

  const isPagination = parseInt(limitFilter) === data.length;

  const CustomRow = (props: any) => {
    const { record } = props;
    if (record) {
      const isOnboardingStarted = record.status !== "Not started";
      const TooltipWrapper = isOnboardingStarted ? React.Fragment : Tooltip;
      const tooltipProps = !isOnboardingStarted && {
        title: "Onboarding not started yet",
        color: "gray",
      };
      return (
        // @ts-ignore
        <TooltipWrapper {...tooltipProps}>
          <tr
            {...props}
            record={undefined}
            style={{
              cursor: `${isOnboardingStarted ? "pointer" : "default"}`,
            }}
            onClick={() => onRowClick(isOnboardingStarted, record.id)}
          />
        </TooltipWrapper>
      );
    } else return <tr {...props} />;
  };

  const onRowClick = (isOnboardingStarted: boolean, id: string) => {
    if (isOnboardingStarted) {
      history.push(`/dashboard/onboarding-info/${id}`);
    }
  };

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, any>,
    sorter: SorterResult<any>
  ) => {
    const { field, order } = sorter;
    // @ts-ignore
    handleSortColumn(field, order);
  };

  const handleLoadMoreOnboardings = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };

  const validData = parseToValidData(data, dictionary?.countries || []);

  const columns = [
    {
      title: "Client ID",
      dataIndex: "id",
      key: "id",
      sorter: true,
      width: "10%",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: true,
      width: "10%",
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8, width: 400 }}>
          <SelectTableFilter
            placeholder={"Filter by country"}
            filterName={"countryId"}
            optionItems={dictionary?.countries.map((item) => {
              return { ...item, id: item.id.toString() };
            })}
            name={"name"}
            valueName={"id"}
            onSelect={() => confirm()}
          />
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: "15%",
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8, width: 400 }}>
          <SelectTableFilter
            placeholder={"Filter by status"}
            filterName={"states"}
            optionItems={onboardingStatusMap}
            name={"name"}
            valueName={"value"}
            onSelect={() => confirm()}
          />
        </div>
      ),
    },
    {
      title: "Registration date",
      dataIndex: "created",
      key: "created",
      sorter: true,
      width: "10%",
      defaultSortOrder: "descend",
    },
    {
      title: "Type",
      dataIndex: "kind",
      key: "kind",
      sorter: true,
      width: "10%",
    },
    {
      title: "Manager name",
      dataIndex: "managerName",
      key: "managerName",
      sorter: true,
      width: "20%",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 400 }}>
          <SearchTableFilter
            placeholder={"Search by manager name"}
            filterName={"managerName"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Client name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: "25%",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 400 }}>
          <SearchTableFilter
            placeholder={"Search by company name"}
            filterName={"companyName"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
  ];

  const columnsWithSort = useMemo(
    () =>
      setDefaultColumnsSort(
        sortFilter || "",
        sortDirectionFilter || "",
        columns
      ),
    []
  );

  return (
    <>
      {/*@ts-ignore*/}
      <Styled.Table
        loading={status === "pending" || isLoading}
        columns={columnsWithSort}
        dataSource={validData}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
        onChange={handleTableChange}
        components={{
          body: {
            row: CustomRow,
          },
        }}
        onRow={(record, rowIndex) => {
          return {
            record,
          };
        }}
      />
      {!isLoading && validData.length > 0 && isPagination && (
        <Button
          onClick={handleLoadMoreOnboardings}
          loading={status === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more onboardings
        </Button>
      )}
    </>
  );
};

//todo переместить валидацию из компонента
const parseToValidData = (data: [any], countries: ICountries[]) => {
  return data.map((item) => {
    return {
      ...item,
      country: countries.find((a) => a.id === item.countryId)?.iso,
      created: dayjs(item.created).format("DD MMM, YYYY"),
      key: item.id,
    };
  });
};
