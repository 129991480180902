import { useSearchParams } from "react-router-dom";

export const useChangeSearchParams = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const currentParams = parseQueryParamsToObject(searchParams.toString());

  const changeSearchParams = <T>(params: T) => {
    setSearchParams({ ...currentParams, ...(params as URLSearchParams) });
  };

  const setInitialParams = (initialParams: any) => {
    setSearchParams({ ...initialParams, ...currentParams });
  };

  const resetToInitialParams = (initialParams: any) => {
    setSearchParams({ ...initialParams });
  };

  return {
    changeSearchParams,
    setInitialParams,
    resetToInitialParams,
  };
};

export const parseQueryParamsToObject = (querystring: string) => {
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      // @ts-ignore
      obj[key] = params.getAll(key);
    } else {
      // @ts-ignore
      obj[key] = params.get(key);
    }
  }
  return obj;
};
