import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Row, Select } from "antd";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import {
  FIRST_DATE_MOMENT_FORMAT,
  LAST_DATE_MOMENT_FORMAT,
  PAYOUTS_STATUSES_MAP,
} from "../../common/constants/constants";
import dayjs from "dayjs";
import { FilterOutlined } from "@ant-design/icons";
import { MerchantFindFilter } from "../../components/MerchantFindFilter/MerchantFindFilter";

interface IPayoutFilters {
  beneficiaryTypes: "MERCHANT" | "PARTNER";
}

export const Filters: React.FC<IPayoutFilters> = ({ beneficiaryTypes }) => {
  const { RangePicker } = DatePicker;

  let [searchParams] = useSearchParams();

  const initialPayoutStatuses = PAYOUTS_STATUSES_MAP.map(
    (item) => item.value
  ).filter((status) => status !== "NEW");

  const initialFilters = {
    createdFrom: dayjs().utc().subtract(30, "days").format(FIRST_DATE_MOMENT_FORMAT),
    createdTo: dayjs().utc().format(LAST_DATE_MOMENT_FORMAT),
    statuses: initialPayoutStatuses,
    merchantIds: "",
    limit: 10,
    offset: 0,
    beneficiaryTypes,
  };

  const dateFromFilter = searchParams.get("createdFrom");
  const dateToFilter = searchParams.get("createdTo");
  const statusesFilter = searchParams.getAll("statuses");

  const { changeSearchParams, setInitialParams, resetToInitialParams } =
    useChangeSearchParams();

  const rangeFilter = [
    dateFromFilter && dayjs(dateFromFilter).utc(),
    dateToFilter && dayjs(dateToFilter).utc(),
  ];

  const handleDateChange = (momentRange: any) => {
    changeSearchParams({
      createdFrom: momentRange[0].utc().format(FIRST_DATE_MOMENT_FORMAT),
      createdTo: momentRange[1].utc().format(LAST_DATE_MOMENT_FORMAT),
    });
  };

  const onChangeStatusFilter = (statuses: string[]) => {
    // const statuses = value === undefined ? "" : value;
    changeSearchParams({ statuses });
  };

  const { Option } = Select;

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [beneficiaryTypes]);

  return (
    <>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row>
            <Col xxl={10} xl={12} lg={16}>
              <Row>
                <Col span={12}>
                  <MerchantFindFilter
                    disabled={beneficiaryTypes === "PARTNER"}
                  />
                </Col>
                <Col span={12}>
                  <RangePicker
                    format={"DD MMM"}
                    onChange={handleDateChange}
                    value={rangeFilter}
                    style={{ marginLeft: 25 }}
                    allowClear={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col xxl={10} xl={12} lg={16}>
              <Select
                placeholder="Filter by status"
                allowClear
                style={{ width: "100%" }}
                onChange={onChangeStatusFilter}
                defaultValue={statusesFilter}
                value={statusesFilter}
                mode="multiple"
              >
                {PAYOUTS_STATUSES_MAP.map((status) => {
                  const { value, name } = status;
                  return (
                    <Option key={value} value={value}>
                      {name}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Button
                icon={<FilterOutlined />}
                style={{ marginRight: 15 }}
                onClick={() => resetToInitialParams(initialFilters)}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
