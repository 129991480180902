import React from "react";
import { Button, Card, Col, Empty, List, Row } from "antd";
import { IDocumentsResponse } from "../../../data-layer/documents/types/IDocuments";
import { useDownload } from "../../../data-layer/attachment/hooks/useDownload";
import { ReactComponent as AttachedSvg } from "../../../assets/attached.svg";
import { Styled } from "./styles";
import {
  Authorization,
  ROLES,
} from "../../../providers/authorization/Authorization";

interface IRequestedDocumentsList {
  documents: IDocumentsResponse[];
  setVisibleModal: (visible: boolean) => void;
}

export const RequestedDocumentsList: React.FC<IRequestedDocumentsList> = ({
  documents,
  setVisibleModal,
}) => {
  const { downloadFile, startDownloading } = useDownload();
  return (
    <>
      <Card
        title={`Requested documents`}
        headStyle={{ background: "rgba(240, 153, 130, 0.15)" }}
      >
        <List>
          {documents && documents.length > 0 ? (
            documents.map((documentItem, index: number) => {
              return (
                <List.Item>
                  <Row gutter={15}>
                    <Col span={14}>
                      <Styled.ComplianceOfficerCommentWrapper>
                        <p style={{ fontSize: "16px" }}>
                          Compliance officer comment:
                        </p>
                        <span>{documentItem.message}</span>
                      </Styled.ComplianceOfficerCommentWrapper>
                    </Col>
                    <Col span={10}>
                      <Styled.DownloadedDocumentsAreaWrapper>
                        {documentItem?.attachments?.length ? (
                          <Styled.DownloadedDocumentsWrapper>
                            {documentItem?.attachments.map((attachment) => {
                              return (
                                <Styled.AttachedWrapper>
                                  <AttachedSvg />
                                  <a
                                    onClick={() =>
                                      startDownloading(
                                        attachment.hashFileName,
                                        attachment.originalFileName
                                      )
                                    }
                                  >
                                    {attachment.originalFileName}
                                  </a>
                                </Styled.AttachedWrapper>
                              );
                            })}
                          </Styled.DownloadedDocumentsWrapper>
                        ) : (
                          <span>
                            The user has not uploaded the documents yet
                          </span>
                        )}
                      </Styled.DownloadedDocumentsAreaWrapper>
                    </Col>
                  </Row>
                </List.Item>
              );
            })
          ) : (
            <Empty description={"User has not uploaded any documents yet"} />
          )}
        </List>
        <Authorization
          allowedRoles={[
            ROLES.ADMIN,
            ROLES.COMPLIANCE_OFFICER,
            ROLES.PARTNER,
            ROLES.MERCHANT,
            ROLES.EMPLOYEE,
          ]}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setVisibleModal(true)} disabled={false}>
              Send document request
            </Button>
          </div>
        </Authorization>
      </Card>
    </>
  );
};
