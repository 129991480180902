import React from "react";
import { Helmet } from "react-helmet";
import { PageHeader } from "antd";
import { Filters } from "./Filters";
import { AppliedFilterInput } from "../../components/AppliedFiltersInput";
import { useGetOverviewPartners } from "../../data-layer/adminController/hooks";
import { InfoItems } from "./InfoItems";
import { useGetOverviewMerchants } from "../../data-layer/adminController/hooks/useGetOverviewMerchants";
import { useSearchParams } from "react-router-dom";
import {
  Authorization,
  ROLES,
} from "../../providers/authorization/Authorization";
import { NotFound } from "../../pages/notFound";

export const OverviewSc: React.FC = () => {
  let [searchParams] = useSearchParams();

  const {
    overviewPartnersData,
    isLoading: isLoadingPartners,
    status: statusPartners,
  } = useGetOverviewPartners(searchParams.toString());
  const {
    overviewMerchantsData,
    isLoading: isLoadingMerchants,
    status: statusMerchants,
  } = useGetOverviewMerchants(searchParams.toString());

  const isOverviewLoadingMerchants =
    statusMerchants === "pending" || isLoadingMerchants;

  const isOverviewLoadingPartners =
    statusPartners === "pending" || isLoadingPartners;

  return (
    <Authorization
      allowedRoles={[
        ROLES.PARTNER,
        ROLES.EMPLOYEE,
        ROLES.EMPLOYEE,
        ROLES.ADMIN,
        ROLES.COMPLIANCE_OFFICER,
      ]}
      forbiddenFallback={<NotFound />}
    >
      <Helmet>
        <title>Overview</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="Overview" />
      <Filters />
      <AppliedFilterInput />
      <InfoItems
        overviewMerchantsData={overviewMerchantsData}
        overviewPartnersData={overviewPartnersData}
        isLoadingMerchants={isOverviewLoadingMerchants}
        isLoadingPartners={isOverviewLoadingPartners}
      />
    </Authorization>
  );
};
