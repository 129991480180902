import { useRequestResetPasswordMutation } from "../api/authApi";
import { notification } from "antd";

export const usePasswordRecovery = () => {
  const [passwordRecovery] = useRequestResetPasswordMutation();

  const onPasswordRecovery = (email: string, recaptchaResponse: string) => {
    passwordRecovery({ email, recaptchaResponse }).then((result) => {
      if ("data" in result) {
        notification.open({
          message:
            "A confirmation email has been sent to the specified email address",
          type: "success",
        });
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    onPasswordRecovery,
  };
};
