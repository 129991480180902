import { useGetApplicationProfileByIdQuery } from "../api/applicationApi";
import { useGetProfilesByIdQuery } from "../../profile/api/profileApi";
import { IApplicationResponse } from "../types/applicationTypes";
import { Fee } from "../../../pages/onboardingDetails/PricingModels/types";

export const useApplicationById = (id: string) => {
  let {
    data,
    isLoading,
    refetch: applicationRefetch,
  } = useGetApplicationProfileByIdQuery(id);
  const { data: profileData, refetch: profileRefetch } =
    useGetProfilesByIdQuery(id);
  const profileType = profileData?.kind;

  return {
    profileData,
    profileType,
    data: data && data.length ? validateCountryToString(data) : null,
    isLoading,
    applicationRefetch,
    profileRefetch,
  };
};

const validateCountryToString = (data: IApplicationResponse[]): IApplicationResponse | null => {
  if (!data[0]) {
    return null;
  }

  let applicationData = data[0];
  if (applicationData.data) {
    if ("COUNTRY" in applicationData.data) {
      const { COUNTRY } = applicationData.data;

      applicationData = {
        ...applicationData,
        data: {
          ...applicationData.data,
          COUNTRY: COUNTRY.toString(),
          OFFICE_COUNTRY: COUNTRY.toString(),
        },
      };
    }

    if (!("EXPECTED_TURNOVER" in applicationData.data)) {
      applicationData = {
        ...applicationData,
        data: {
          ...applicationData.data,
          EXPECTED_TURNOVER: 1,
        },
      };
    }

    if (!("FEE_PERCENT" in applicationData.data)) {
      applicationData = {
        ...applicationData,
        data: {
          ...applicationData.data,
          FEE_PERCENT: 0,
        },
      };
    }

    if (!("PM_CURRENCY" in applicationData.data)) {
      applicationData = {
        ...applicationData,
        data: {
          ...applicationData.data,
          PM_CURRENCY: "GBP",
        },
      };
    }

    if (!("PM_IN_STORE" in applicationData.data)) {
      applicationData = {
        ...applicationData,
        data: {
          ...applicationData.data,
          PM_IN_STORE_FEE_TYPE: Fee.DISABLED,
        },
      };
    } else if ("PM_IN_STORE" in applicationData.data) {
      let type = Fee.DISABLED;
      if (applicationData.data["PM_IN_STORE"] === true) {
        type = applicationData.data["PM_IN_STORE_FEE_TX_FIX"] && applicationData.data["PM_IN_STORE_FEE_TX_FIX"] > 0 ? Fee.DETAILED_FEE : Fee.BLENDED_FEE;
      }

      applicationData = {
        ...applicationData,
        data: {
          ...applicationData.data,
          PM_IN_STORE_FEE_TYPE: type,
        },
      };
    }

    if (!("PM_ONLINE" in applicationData.data)) {
      applicationData = {
        ...applicationData,
        data: {
          ...applicationData.data,
          PM_ONLINE_FEE_TYPE: Fee.DISABLED,
        },
      };
    } else if ("PM_ONLINE" in applicationData.data) {
      let type = Fee.DISABLED;
      if (applicationData.data["PM_ONLINE"] === true) {
        type = applicationData.data["PM_ONLINE_FEE_TX_FIX"] && applicationData.data["PM_ONLINE_FEE_TX_FIX"] > 0 ? Fee.DETAILED_FEE : Fee.BLENDED_FEE;
      }
      applicationData = {
        ...applicationData,
        data: {
          ...applicationData.data,
          PM_ONLINE_FEE_TYPE: type,
        },
      };
    }
  }

  return applicationData;
};
