import { useGetReportsQuery } from "./api";

export const useGetReports = (searchParams: string, limitFilter: string) => {
  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess } = useGetReportsQuery(
    `${searchParams}`,
    {
      skip: skipRequest,
    }
  );


  return {
    data: data || [],
    error,
    isLoading,
    isSuccess,
    status,
  };
};
