import { createApi } from "@reduxjs/toolkit/query/react";
import { sekumpulQuery } from "../../../services/api/config";
import { UploadFile as UploadFileType } from "antd/lib/upload/interface";

// Define a service using a base URL and expected endpoints
export const sekumpulApi = createApi({
  reducerPath: "sekumpulApi",
  baseQuery: sekumpulQuery,
  endpoints: (builder) => ({
    uploadFile: builder.mutation<any, FormData>({
      query: (file) => ({
        url: `/upload `,
        method: "POST",
        body: file,
      }),
    }),
    downloadFileByName: builder.mutation<IAttachmentsResponse, string>({
      query: (fileName) => `/?fileName=${fileName}`,
    }),
  }),
});
export const { useDownloadFileByNameMutation, useUploadFileMutation } =
  sekumpulApi;
