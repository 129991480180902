import { useGetDLQMessagesQuery } from "../api/systemApi";

export const useGetDLQ = (searchParams: string, limitFilter: string) => {
  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess, refetch } =
    useGetDLQMessagesQuery(`${searchParams}`, {
      skip: skipRequest,
    });

  let dataWithoutLastItem = data ? [...data] : [];
  limitFilter && dataWithoutLastItem.splice(parseInt(limitFilter) - 1, 1);

  return {
    dataWithoutLastItem,
    data: data || [],
    error,
    isLoading,
    isSuccess,
    requestStatus: status,
    refetch,
  };
};
