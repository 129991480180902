import React, { useEffect, useState } from "react";
import { Table } from "./Table";
import { Filters } from "./Filters";
import { useSearchParams } from "react-router-dom";
import { PageHeader } from "antd";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { Helmet } from "react-helmet";
import { useUser } from "../../data-layer/user/hooks";
import { useRoles } from "../../data-layer/roles/hooks";
import { useAddRole } from "../../data-layer/user/hooks/useAddRole";
import { useDeleteRole } from "../../data-layer/user/hooks/useDeleteRole";
import { CreateNewPasswordModal } from "./CreateNewPasswordModal";
import { IUserResponse } from "../../data-layer/user/types/IUser";
import { useSetNewPassword } from "../../data-layer/auth/hooks/useSetNewPassword";
import { useSetStatusEmployee } from "../../data-layer/user/hooks/useUpdateUser";
import { AppliedFilterInput } from "../../components/AppliedFiltersInput";
import {
  Authorization,
  ROLES,
} from "../../providers/authorization/Authorization";
import { NotFound } from "../../pages/notFound";

export const UsersSc: React.FC = () => {
  let [searchParams] = useSearchParams();
  const [visibleCreatePasswordModal, setVisibleCreatePasswordModal] =
    useState<boolean>(false);

  const [currentUserInfo, setCurrentUserInfo] = useState<IUserResponse>();

  const { changeSearchParams } = useChangeSearchParams();

  const limitFilter = searchParams.get("limit") || "";

  const handleLoadMoreUsers = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };

  const {
    userData,
    dataWithoutLastItem,
    isLoading,
    requestStatus,
    handleSortColumn,
  } = useUser(searchParams.toString(), limitFilter);

  const { onSetNewPassword } = useSetNewPassword();

  const { addUserRole } = useAddRole();

  const { deleteUserRole } = useDeleteRole();

  const { onBlockUser } = useSetStatusEmployee();

  const roles = useRoles();

  const isAllowPagination = parseInt(limitFilter) === userData.length;

  return (
    <Authorization
      allowedRoles={[
        ROLES.PARTNER,
        ROLES.EMPLOYEE,
        ROLES.EMPLOYEE,
        ROLES.ADMIN,
        ROLES.COMPLIANCE_OFFICER,
      ]}
      forbiddenFallback={<NotFound />}
    >
      <Helmet>
        <title>Users</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="Users" />
      <Filters />
      <AppliedFilterInput />
      <Table
        pagination={isAllowPagination}
        tableData={dataWithoutLastItem}
        isLoading={isLoading}
        requestStatus={requestStatus}
        handleLoadMoreUsers={handleLoadMoreUsers}
        roles={roles.data}
        addUserRole={addUserRole}
        deleteUserRole={deleteUserRole}
        handleSortColumn={handleSortColumn}
        setVisibleCreatePasswordModal={setVisibleCreatePasswordModal}
        setCurrentUserInfo={setCurrentUserInfo}
        onBlockUser={onBlockUser}
      />
      <CreateNewPasswordModal
        visibleModal={visibleCreatePasswordModal}
        setVisibleModal={setVisibleCreatePasswordModal}
        currentUserInfo={currentUserInfo}
        onSetNewPassword={onSetNewPassword}
      />
    </Authorization>
  );
};
