import { ReactComponent as UploadSvg } from "assets/upload.svg";
import { Button } from "antd";

import styled from "styled-components";

const UploadIcon = styled(UploadSvg)`
  margin-right: 10px;
`;

const UploadButton = styled(Button)`
  &:hover {
    color: initial;
    border-color: black;
  }
  &:focus {
    color: initial;
    border-color: initial;
  }
`;

export const Styled = {
  UploadIcon,
  UploadButton,
};
