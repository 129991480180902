import { Divider, Form, InputNumber, Select } from "antd";
import { Fee, FeeType, IPricingModels } from "./types";
import { useEffect, useState } from "react";
import {
  BLENDED_FIX_FEE,
  BLENDED_PERCENTAGE,
  DETAILED_FIX_FEE,
  DETAILED_PERCENTAGE,
} from "./constants";
import { parseStringToNumber } from "../../../utils/number";

export const OnlinePricingModel: React.FC<IPricingModels> = ({
  form,
  data,
  onChangeData,
}) => {
  const { Option } = Select;

  const [onlineFeeType, setOnlineFeeType] = useState<FeeType>(
    data.PM_ONLINE_FEE_TYPE || Fee.DETAILED_FEE
  );

  const handleChangeOnlineFeeType = (value: FeeType) => {
    if (
      value === Fee.BLENDED_FEE &&
      data.PM_ONLINE_FEE_TYPE === Fee.BLENDED_FEE
    ) {
      form.setFieldValue(
        "PM_ONLINE_FEE_TX_PERCENTAGE",
        data.PM_ONLINE_FEE_TX_PERCENTAGE
      );
      form.setFieldValue("PM_ONLINE_FEE_TX_FIX", BLENDED_FIX_FEE.toFixed(2));

    } else if (
      value === Fee.BLENDED_FEE &&
      data.PM_ONLINE_FEE_TYPE !== Fee.BLENDED_FEE
    ) {
      form.setFieldValue("PM_ONLINE_FEE_TX_PERCENTAGE", BLENDED_PERCENTAGE);
      form.setFieldValue("PM_ONLINE_FEE_TX_FIX", BLENDED_FIX_FEE.toFixed(2));
    } else if (
      value === Fee.DETAILED_FEE &&
      data.PM_ONLINE_FEE_TYPE !== Fee.DETAILED_FEE
    ) {
      form.setFieldValue("PM_ONLINE_FEE_TX_PERCENTAGE", DETAILED_PERCENTAGE);
      form.setFieldValue("PM_ONLINE_FEE_TX_FIX", DETAILED_FIX_FEE.toFixed(2));
    } else if (
      value === Fee.DETAILED_FEE &&
      data.PM_ONLINE_FEE_TYPE === Fee.DETAILED_FEE
    ) {
      form.setFieldValue(
        "PM_ONLINE_FEE_TX_PERCENTAGE",
        data.PM_ONLINE_FEE_TX_PERCENTAGE
      );
      form.setFieldValue("PM_ONLINE_FEE_TX_FIX", data.PM_ONLINE_FEE_TX_FIX);
    }

    form.setFieldValue("PM_ONLINE_FEE_TYPE", value);
    onChangeData(form.getFieldsValue());
  };

  useEffect(() => {
    setOnlineFeeType(data.PM_ONLINE_FEE_TYPE);
  }, [data]);

  return (
    <>
      <Divider orientation="center" plain>
        Online UK+EU Visa MasterCard e-Com Payments
      </Divider>
      <Form.Item
        label="Type"
        labelCol={{ span: 24 }}
        name={"PM_ONLINE_FEE_TYPE"}
        rules={[
          {
            required: true,
            message: "Please select Online Fee Type",
          },
        ]}
      >
        <Select
          onChange={handleChangeOnlineFeeType}
          placeholder="Please select..."
        >
          <Option key={Fee.BLENDED_FEE} value={Fee.BLENDED_FEE}>
            Blended rate
          </Option>
          <Option
            key={Fee.DETAILED_FEE}
            value={Fee.DETAILED_FEE}
            defaultChecked
          >
            IC++
          </Option>
          <Option key={Fee.DISABLED} value={Fee.DISABLED}>
            Disabled
          </Option>
        </Select>
      </Form.Item>
      {onlineFeeType !== Fee.DISABLED && (
        <>
          <Form.Item
            label="Percent fee"
            labelCol={{ span: 24 }}
            name={"PM_ONLINE_FEE_TX_PERCENTAGE"}
            rules={[
              {
                required: true,
                message: "Please input percent fee",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
                backgroundColor: "#ffffff",
                color: "#000000",
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
              step={0.01}
              max={100}
              min={0}
              formatter={(value) => `${parseStringToNumber(value).toFixed(2)}%`}
              // @ts-ignore
              parser={(value) => value!.replace("%", "")}
            />
          </Form.Item>
          {onlineFeeType === Fee.DETAILED_FEE && (
            <Form.Item
              label="Auth fee"
              labelCol={{ span: 24 }}
              name={"PM_ONLINE_FEE_TX_FIX"}
              rules={[
                {
                  required: true,
                  message: "Please input percent fee",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          )}
        </>
      )}
    </>
  );
};
