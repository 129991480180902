import React, { useEffect, useState } from "react";
import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input,
  List,
  Select,
  Typography,
} from "antd";
import { Styled } from "./styled";
import {
  IPartnerForm,
  IPartnerSettlementDetails,
  IPartnerUpdateRequest,
  IPartnerWithMerchants,
} from "../../data-layer/partner/types/partnerTypes";
import { Link } from "react-router-dom";
import { UploadFile } from "./UploadFile";
import { yupValidator } from "../../common/partnerForm";

interface IPartnerInfoDrawer {
  visible: boolean;
  onClose: () => void;
  data: IPartnerWithMerchants;
  onInviteMerchant: () => void;
  currencyList: string[];
  onSubmitUpdatePartner: any;
}

export const PartnerInfoDrawer: React.FC<IPartnerInfoDrawer> = ({
  visible,
  onClose,
  data,
  onInviteMerchant,
  currencyList,
  onSubmitUpdatePartner,
}) => {
  const { id, merchants, logoImageFilename } = data;

  const [isEditable, setIsEditable] = useState(false);

  const [form] = Form.useForm<IPartnerForm>();
  const validator = yupValidator(form.getFieldsValue);

  const handleCancelClick = () => {
    form.resetFields();
    setIsEditable(false);
  };

  const formatSettlementDetails = (data: IPartnerSettlementDetails | null): IPartnerSettlementDetails | null => {
    if (data && (data.bankAccountHolderName || data.bankAccountNumber || data.sortCode)) {
      return {
        sortCode: data.sortCode || null,
        bankAccountHolderName: data.bankAccountHolderName || null,
        bankAccountNumber: data.bankAccountNumber || null,
      };
    }

    return null;
  }

  const handleSubmit = async (fields: IPartnerForm) => {
    const updateData: IPartnerUpdateRequest = {
      id,
      name: fields.name,
      address: fields.address,
      feePercent: fields.feePercent,
      currencyId: fields.currency.id,
      logoImageFilename: fields.logoImageFilename,
      settlementDetailsData: formatSettlementDetails(fields.settlementDetailsData),
    };

    const updateResult = await onSubmitUpdatePartner(updateData);
    if ("data" in updateResult) {
      setIsEditable(false);
      form.setFieldsValue(updateResult.data);
    }
  };

  const onAfterUpload = (logoImageFilename: string) => {
    form.setFieldValue("logoImageFilename", logoImageFilename);
  };

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Drawer
      title="Partner details"
      placement={"right"}
      onClose={onClose}
      open={visible}
      width="50%"
      headerStyle={{ border: "none" }}
      key={data.id}
    >
      <Styled.ActionButtonWrapper>
        {!isEditable ? (
          <Button
            type={"primary"}
            onClick={() => setIsEditable(true)}
            style={{ width: "100%", height: "100%" }}
          >
            Edit
          </Button>
        ) : (
          <Styled.EditableButtonContainer>
            <Button
              type={"primary"}
              onClick={() => form.submit()}
              style={{ width: 145, height: "100%" }}
            >
              Save
            </Button>
            <Button
              type={"primary"}
              onClick={handleCancelClick}
              style={{ width: 145, height: "100%" }}
            >
              Cancel
            </Button>
          </Styled.EditableButtonContainer>
        )}
      </Styled.ActionButtonWrapper>
      <Styled.PartnerDetailsFormWrapper>
        <Form
          initialValues={data}
          disabled={!isEditable}
          onFinish={handleSubmit}
          form={form}
          name={data?.id?.toString()}
        >
          <Descriptions column={1} bordered style={{ marginBottom: 50 }}>
            <Descriptions.Item label="Id">{id}</Descriptions.Item>
            <Descriptions.Item label="Address">
              <Form.Item name={"address"}>
                <Input />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              <Form.Item name={"name"} rules={[validator]}>
                <Input />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Logo for Dashboard (2000x624):">
              <Form.Item name={"logoImageFilename"} rules={[validator]}>
                <UploadFile
                  name={"logo_partner"}
                  onAfterUpload={onAfterUpload}
                  logoImageFilename={logoImageFilename}
                />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Fee">
              <Form.Item name={"feePercent"} rules={[validator]}>
                <Input type="number" />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Currency">
              <Form.Item name={["currency", "id"]} rules={[validator]}>
                <Select
                  showSearch
                  optionFilterProp="label"
                  placeholder="Currency"
                  options={currencyList?.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                />
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
              title={
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                  Settlement details:
                  <Divider style={{ marginTop: 10, marginBottom: 0 }} />
                </Typography.Title>
              }
              column={1} bordered style={{ marginBottom: 50 }}
          >

            <Descriptions.Item label="Bank account holder name">
              <Form.Item name={["settlementDetailsData", "bankAccountHolderName"]} rules={[validator]}>
                <Input />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Sort code / BIC/ SWIFT">
              <Form.Item name={["settlementDetailsData", "sortCode"]} rules={[validator]}>
                <Input />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Bank account number / IBAN">
              <Form.Item name={["settlementDetailsData", "bankAccountNumber"]} rules={[validator]}>
                <Input />
              </Form.Item>
            </Descriptions.Item>

          </Descriptions>
        </Form>
      </Styled.PartnerDetailsFormWrapper>
      {merchants?.length > 0 && (
        <List
          size={"small"}
          style={{ marginBottom: 15 }}
          header={
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              Connected merchants:
            </Typography.Title>
          }
          itemLayout="horizontal"
          dataSource={merchants}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <Link to={`/dashboard/merchant-edit/${item.id}`}>
                    {item.merchantName}
                  </Link>
                }
                description={`${item.kind}, ${item.merchantContactData.email}`}
              />
            </List.Item>
          )}
        />
      )}

      <Button onClick={onInviteMerchant} disabled={isEditable}>connect merchant</Button>
    </Drawer>
  );
};
