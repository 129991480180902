import { useUpdateLocaleMutation } from "../api/i18settingsApi";
import { notification } from "antd";
import { I18settingsResponse } from "../types/i18settingsTypes";

export const useUpdateLocale = () => {
  const [updateLocale] = useUpdateLocaleMutation();

  const onUpdateLocale = async (params: I18settingsResponse) => {
    const result = await updateLocale(params);
    if ("data" in result) {
      notification.open({
        message: "Locale updated",
        type: "success",
      });
    } else if (result.error) {
      notification.open({
        message: "Something went wrong",
        description: result.error?.data?.error,
        type: "error",
      });
    }

    return result;
  };

  return {
    onUpdateLocale,
  };
};
