import { IProfile } from "../types";
import { useGetProfilesQuery } from "./api";

export const useGetProfiles = (searchParams: string, limitFilter: string) => {
  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess } = useGetProfilesQuery(
    `${searchParams}`,
    {
      skip: skipRequest,
    }
  );

  let dataWithoutLastItem = [...validateProfiles(data || [])];
  limitFilter && dataWithoutLastItem.splice(parseInt(limitFilter) - 1, 1);

  return {
    data: validateProfiles(data || []),
    dataWithoutLastItem,
    error,
    isLoading,
    isSuccess,
    status,
  };
};

const validateProfiles = (data: IProfile[]) => {
  return data.map((item) => {
    const { status, kind } = item;
    return {
      ...item,
      status: statusMap[status],
      kind: typeMap[kind],
    };
  });
};

const statusMap = {
  NOT_STARTED: "Not started",
  NOT_FINISHED: "Not finished",
  DOCUMENT_REQUESTED: "Document requested",
  UNDER_REVIEW: "Under review",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

const typeMap = {
  COMPANY: "Company",
  SOLE_TRADER: "Sole trader",
};
