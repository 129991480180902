import {
  Form as AntForm,
  Space,
  Input,
  Row,
  Col,
  Radio,
  InputNumber,
} from "antd";
import { IMerchantIntegrationDetailsFormPart } from "./types";

export const MerchantIntegrationDetailsTab: React.FC<
  IMerchantIntegrationDetailsFormPart
> = ({ validator, initialData }) => {
  const exportCCTOptions = [
    { label: "Export to CCT", value: true },
    { label: "DON'T export to CCT", value: false },
  ];

  return (
    <Row gutter={24}>
      <Col span={16}>
        <AntForm.Item
          rules={[validator]}
          name={"fsrvExternalId"}
          label={"Fiserv External ID"}
          initialValue={initialData?.fsrvExternalId}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"vatID"}
          label={"VAT-ID"}
          initialValue={initialData?.vatID}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"onboardingDetailsTotalSales"}
          label={"Onboarding Details Total Sales"}
          initialValue={initialData?.onboardingDetailsTotalSales}
        >
          <InputNumber />
        </AntForm.Item>
        <AntForm.Item
            rules={[validator]}
            name={"onboardingDetailsProductServiceSold"}
            label={"Onboarding Details Product ServiceSold"}
            initialValue={initialData?.onboardingDetailsProductServiceSold}

        >
          <Input.TextArea style={{ height: 80, resize: 'none' }}/>
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"cdxExternalId"}
          label={"Credorax External ID"}
          initialValue={initialData?.cdxExternalId}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"cctExternalId"}
          label={"CCT External ID"}
          initialValue={initialData?.cctExternalId}
        >
          <InputNumber />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"tidPrefix"}
          label={"TID Prefix"}
          initialValue={initialData?.tidPrefix}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"mid"}
          label={"MID"}
          initialValue={initialData?.mid}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["needExportData", "toCct"]}
          label={"Export"}
          initialValue={initialData?.needExportData?.toCct}
        >
          <Space direction="vertical">
            {initialData?.needExportData ? (
              <Radio.Group
                options={exportCCTOptions}
                optionType="button"
                buttonStyle="solid"
                value={initialData?.needExportData?.toCct}
              />
            ) : (
              <Radio.Group
                options={exportCCTOptions}
                optionType="button"
                buttonStyle="solid"
              />
            )}
          </Space>
        </AntForm.Item>
      </Col>
    </Row>
  );
};
