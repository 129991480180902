import React from "react";
import { Button, Form, Form as AntForm, Input, Modal } from "antd";
import { useDocumentRequest } from "../../../data-layer/documents/hooks/useDocumentRequest";

interface IDocumentRequestModal {
  visibleModal: boolean;
  setVisibleModal: (visible: boolean) => void;
  profileId: string;
  documentRequestRefetch: any;
}

export const DocumentRequestModal: React.FC<IDocumentRequestModal> = ({
  visibleModal,
  setVisibleModal,
  profileId,
  documentRequestRefetch,
}) => {
  const [form] = AntForm.useForm();

  const { onDocumentRequestSubmit } = useDocumentRequest();

  return (
    <>
      <Modal
        open={visibleModal}
        title={"Document request"}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button form="sendDocumentRequest" key="submit" htmlType="submit">
            Send Request
          </Button>,
        ]}
      >
        <Form
          id={"sendDocumentRequest"}
          form={form}
          layout={"vertical"}
          onFinish={(fields) => {
            onDocumentRequestSubmit({
              documentRequestTextArea: form.getFieldValue("comment"),
              id: parseInt(profileId),
              form: form,
              documentRequestRefetch: documentRequestRefetch,
            });
            setVisibleModal(false);
          }}
        >
          <Form.Item
            name={"comment"}
            label={"Comment"}
            rules={[
              {
                required: true,
                message: "Write the comment!",
              },
            ]}
          >
            <Input.TextArea placeholder={"Please write the comment"} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
