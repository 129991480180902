import React, { useState } from "react";
import { Table } from "./Table";
import { Filters } from "./Filters";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, PageHeader, Radio } from "antd";
import { useGetPayout } from "../../data-layer/payout/hooks/useGetPayout";
import { useCreatePayout } from "../../data-layer/payout/hooks/useCreatePayout";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { PayoutItem } from "./PayoutItem";
import { IPayoutResponse } from "../../data-layer/payout/types/payoutTypes";
import { useUpdatePayout } from "../../data-layer/payout/hooks/useUpdatePayout";
import { Helmet } from "react-helmet";
import { AppliedFilterInput } from "../../components/AppliedFiltersInput/AppliedFilterInput";
import {
  Authorization,
  ROLES,
} from "../../providers/authorization/Authorization";
import { NotFound } from "../../pages/notFound";
import { useDownload } from "data-layer/payout/hooks/useDownloadStatement";
import { useRetryExportStatement } from "data-layer/payout/hooks/useRetryExportStatement";

export interface IPayoutRowInfo extends IPayoutResponse {
  merchantName: string;
}

interface IPayoutProps {
  beneficiaryTypes: "MERCHANT" | "PARTNER";
}

export const PayoutSC: React.FC<IPayoutProps> = ({ beneficiaryTypes }) => {
  let [searchParams] = useSearchParams();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [payoutItemInfo, setPayoutItemInfo] = useState<IPayoutRowInfo>(
    {} as IPayoutRowInfo
  );

  const { changeSearchParams } = useChangeSearchParams();
  const navigate = useNavigate();

  const limitFilter = searchParams.get("limit") || "";

  const handleLoadMorePayout = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };

  const onDrawerClose = () => {
    setVisibleDrawer(false);
  };

  const onDrawerOpen = (payoutInfo: IPayoutRowInfo) => {
    setPayoutItemInfo(payoutInfo);
    setVisibleDrawer(true);
  };

  const { payoutData, isLoading, requestStatus, refetch, dataWithoutLastItem } =
    useGetPayout(searchParams.toString(), limitFilter);

  const { onApprovePayout } = useUpdatePayout(refetch);

  const { onSubmitCreatePayoutMerchant, onSubmitCreatePayoutPartner } =
    useCreatePayout(refetch);

  const { startDownloading } = useDownload();
  const { onRetryExportStatement } = useRetryExportStatement(refetch);

  const handleRetryStatement = (id: number) => {
    onRetryExportStatement(id, () => {});
  };

  const handleDownloadStatement = (id: number) => {
    startDownloading(id, `settlement-${id}.pdf`);
  };

  const isAllowPagination = parseInt(limitFilter) === payoutData.length;

  const readyToSettlement =
    beneficiaryTypes === "MERCHANT"
      ? Boolean(payoutItemInfo?.merchant?.readyToSettlement)
      : Boolean(payoutItemInfo?.partner?.readyToSettlement);

  return (
    <Authorization
      allowedRoles={[
        ROLES.PARTNER,
        ROLES.EMPLOYEE,
        ROLES.EMPLOYEE,
        ROLES.ADMIN,
        ROLES.COMPLIANCE_OFFICER,
      ]}
      forbiddenFallback={<NotFound />}
    >
      <Helmet>
        <title>Payouts</title>
      </Helmet>
      <div style={{ display: "flex", alignItems: "center" }}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 5 }}
          title="Payouts"
        />
        <Radio.Group value={beneficiaryTypes}>
          <Radio.Button
            value="MERCHANT"
            onClick={() =>
              beneficiaryTypes !== "MERCHANT" &&
              navigate("/dashboard/payouts/merchant")
            }
          >
            Merchants
          </Radio.Button>
          <Radio.Button
            value="PARTNER"
            onClick={() =>
              beneficiaryTypes !== "PARTNER" &&
              navigate("/dashboard/payouts/partner")
            }
          >
            Partners
          </Radio.Button>
        </Radio.Group>
      </div>
      <Filters beneficiaryTypes={beneficiaryTypes} />
      <Button
        type="primary"
        style={{ marginTop: 25 }}
        onClick={
          beneficiaryTypes === "MERCHANT"
            ? onSubmitCreatePayoutMerchant
            : onSubmitCreatePayoutPartner
        }
      >
        Immediate reconcile
      </Button>
      <Button
        type="primary"
        style={{ marginLeft: 25 }}
        onClick={() => refetch()}
      >
        Refresh table
      </Button>
      <AppliedFilterInput />
      <Table
        pagination={isAllowPagination}
        tableData={dataWithoutLastItem}
        isLoading={isLoading}
        requestStatus={requestStatus}
        handleLoadMorePayout={handleLoadMorePayout}
        onRowClick={onDrawerOpen}
        onRetryStatement={handleRetryStatement}
        onDownloadStatement={handleDownloadStatement}
        beneficiaryTypes={beneficiaryTypes}
      />
      <PayoutItem
        visible={visibleDrawer}
        onClose={onDrawerClose}
        data={payoutItemInfo}
        handleApprove={onApprovePayout}
        readyToSettlement={readyToSettlement}
        beneficiaryTypes={beneficiaryTypes}
      />
    </Authorization>
  );
};
