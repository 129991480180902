import { useSetStatusEmployeeMutation } from "../api/employeeApi";
import { notification } from "antd";
import { ISetStatusEmployeeRequest } from "../types/IEmployee";

export const useSetStatusEmployee = () => {
  const [setStatusEmployee] = useSetStatusEmployeeMutation();

  const onBlockEmployee = (block: boolean, employeeId: number) => {
    const employeePayload: ISetStatusEmployeeRequest = {
      employeeId,
      employeeStatus: block ? "SUSPENDED" : "ACTIVATED",
    };

    const notifyStatus = block ? "blocked" : "unblocked";

    setStatusEmployee(employeePayload).then((info) => {
      if ("data" in info) {
        notification.open({
          message: `Employee has been successfully ${notifyStatus}`,
          type: "success",
        });
      } else if ("error" in info) {
        notification.open({
          message: "Something went wrong",
          type: "error",
        });
      }
    });
  };

  return {
    onBlockEmployee,
  };
};
