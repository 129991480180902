import { Helmet } from "react-helmet";
import React from "react";
import { Styled } from "./style";
import { LoginForm } from "./components/LoginForm";

export const Login: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Styled.Wrapper>
        <Styled.LoginTitle>Sign in</Styled.LoginTitle>
        <LoginForm />
      </Styled.Wrapper>
    </>
  );
};
