import { useGetEmployeePermissionsQuery } from "./api";
import { IEmployeePermissions } from "../types";

export const useGetPermissions = () => {
  const { data, error, status, isLoading, isSuccess } =
    useGetEmployeePermissionsQuery("");

  const permissionsData = data || [];

  const permissionCheckboxOptions =
    validatePermissionsForCheckbox(permissionsData);

  return {
    data: permissionsData,
    permissionCheckboxOptions,
    error,
    isLoading,
    isSuccess,
    requestStatus: status,
    EMPLOYEE_PERMISSIONS_MAP,
  };
};

//todo переместить в константы

const EMPLOYEE_PERMISSIONS_MAP = {
  REFUND: "Refund",
  PARTIAL_REFUND: "Partial refund",
  VOID: "Void",
};

const validatePermissionsForCheckbox = (data: IEmployeePermissions[]) => {
  return data.map((item) => {
    return {
      label: EMPLOYEE_PERMISSIONS_MAP[item.name],
      value: item.id,
    };
  });
};
