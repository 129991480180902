export const REJECT_REASONS_MAP = [
  {
    value: "DUPLICATE_ACCOUNT",
    name: "Duplicate Account",
  },
  {
    value: "PROHIBITED_MCC",
    name: "Prohibited MCC",
  },
  {
    value: "PROHIBITED_JURISDICTION",
    name: "Prohibited Jurisdiction",
  },
  {
    value: "IDENTIFICATION_DOCS_DONT_MEET_REQUIRED_STANDARDS",
    name: "Identification Docs do not meet required standards",
  },
  {
    value: "FINANCIAL_CRIME_CONCERNS",
    name: "Financial Crime Concerns",
  },
];
