import React, { useMemo } from "react";
import { MenuProps, Button, Dropdown, TablePaginationConfig } from "antd";
import { IPayoutResponse } from "../../data-layer/payout/types/payoutTypes";
import { Styled as TableStyled } from "shared/style";
import { IPayoutRowInfo } from "./PayoutSC";
import { SearchTableFilter } from "../../components/SearchTableFilter";
import { SearchOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/es/table/interface";
import { useHandleSortColumn } from "../../common/hooks/useHandleSortColumn";
import { useSearchParams } from "react-router-dom";
import { MerchantFindFilter } from "../../components/MerchantFindFilter/MerchantFindFilter";

interface ITableProps {
  tableData: IPayoutResponse[];
  isLoading: boolean;
  requestStatus: string;
  handleLoadMorePayout: () => void;
  onRowClick: (payoutInfo: IPayoutRowInfo) => void;
  onRetryStatement: (id: number) => void;
  onDownloadStatement: (id: number) => void;
  pagination: boolean;
  beneficiaryTypes: "MERCHANT" | "PARTNER";
}

export const Table: React.FC<ITableProps> = ({
  tableData,
  isLoading,
  requestStatus,
  handleLoadMorePayout,
  onRowClick,
  onRetryStatement,
  onDownloadStatement,
  pagination,
  beneficiaryTypes,
}) => {
  const partnersPayoutsColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by id"}
            filterName={"id"}
            type={"number"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Partner name",
      dataIndex: "partner",
      key: "partner",
      render: (data: any) => data.name,
    },
    {
      title: "Net amount",
      dataIndex: "netAmount",
      key: "netAmount",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by net amount"}
            filterName={"netAmount"}
            type={"number"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      fixed: "right",
      render: (data: IPayoutResponse) => {
        const items: MenuProps["items"] = [
          {
            label: (
              <span
                onClick={(event) => {
                  onRetryStatement(data.id);
                  event.stopPropagation();
                }}
              >
                Retry Export Statement
              </span>
            ),
            key: "1",
          },
          {
            label: (
              <span
                onClick={(event) => {
                  onDownloadStatement(data.id);
                  event.stopPropagation();
                }}
              >
                Download Statement
              </span>
            ),
            key: "2",
          },
        ];

        const menuProps = {
          items,
        };

        return (
          <Dropdown menu={menuProps} trigger={["click"]}>
            <Button onClick={(event) => event.stopPropagation()}>...</Button>
          </Dropdown>
        );
      },
    },
  ];

  const merchantPayoutsColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: true,
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by id"}
            filterName={"id"}
            type={"number"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Merchant",
      dataIndex: "merchantName",
      key: "merchantName",
      sorter: true,
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <MerchantFindFilter onSelect={confirm} />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by amount"}
            filterName={"amount"}
            type={"number"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Net amount",
      dataIndex: "netAmount",
      key: "netAmount",
      sorter: true,
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by net amount"}
            filterName={"netAmount"}
            type={"number"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Fee amount",
      dataIndex: "feeAmount",
      key: "feeAmount",
      sorter: true,
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by fee amount"}
            filterName={"feeAmount"}
            type={"number"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Refund amount",
      dataIndex: "refundAmount",
      key: "refundAmount",
      sorter: true,
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by refund amount"}
            filterName={"refundAmount"}
            type={"number"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Repay amount",
      dataIndex: "repayAmount",
      key: "repayAmount",
      sorter: true,
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by repay amount"}
            filterName={"repayAmount"}
            type={"number"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
    },
    {
      title: "Created",
      dataIndex: "created",
      sorter: true,
      key: "created",
    },
    {
      title: "Modified",
      sorter: true,
      dataIndex: "modified",
      key: "modified",
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      fixed: "right",
      render: (data: IPayoutResponse) => {
        const items: MenuProps["items"] = [
          {
            label: (
              <span
                onClick={(event) => {
                  onRetryStatement(data.id);
                  event.stopPropagation();
                }}
              >
                Retry Export Statement
              </span>
            ),
            key: "1",
          },
          {
            label: (
              <span
                onClick={(event) => {
                  onDownloadStatement(data.id);
                  event.stopPropagation();
                }}
              >
                Download Statement
              </span>
            ),
            key: "2",
          },
        ];

        const menuProps = {
          items,
        };

        return (
          <Dropdown menu={menuProps} trigger={["click"]}>
            <Button onClick={(event) => event.stopPropagation()}>...</Button>
          </Dropdown>
        );
      },
    },
  ];

  const currentColumns =
    beneficiaryTypes === "MERCHANT"
      ? merchantPayoutsColumns
      : partnersPayoutsColumns;

  const { setDefaultColumnsSort, handleSortColumn } = useHandleSortColumn();
  let [searchParams] = useSearchParams();

  const sortFilter = searchParams.get("orderBy[0].field");
  const sortDirectionFilter = searchParams.get("orderBy[0].direction");

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, any>,
    sorter: SorterResult<any>
  ) => {
    const { field, order } = sorter;
    // @ts-ignore
    handleSortColumn(field, order);
  };

  const columnsWithSort = useMemo(
    () =>
      setDefaultColumnsSort(
        sortFilter || "",
        sortDirectionFilter || "",
        currentColumns
      ),
    [beneficiaryTypes]
  );

  return (
    <>
      {/*@ts-ignore*/}
      <TableStyled.Table
        loading={requestStatus === "pending" || isLoading}
        columns={columnsWithSort}
        dataSource={tableData}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onRowClick(record as IPayoutRowInfo);
            },
          };
        }}
      />
      {!isLoading && tableData.length > 0 && pagination && (
        <Button
          onClick={handleLoadMorePayout}
          loading={requestStatus === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more payouts
        </Button>
      )}
    </>
  );
};
