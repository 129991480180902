import React from "react";
import { Filters } from "./components/Filters";
import { PageHeader } from "antd";
import { Helmet } from "react-helmet";
import { Table } from "./components/Table";
import { AppliedFilters } from "../../features/appliedFilters";

export const Reports: React.FC = () => {

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <PageHeader title="Reports" style={{ paddingLeft: 0 }} />
      <Filters />
      <AppliedFilters />
      <Table  />
    </>
  );
};
