import styled from "styled-components";

const FormWrapper = styled.div`
  margin-top: 41px;
  width: 850px;

  .ant-input[disabled] {
    border: none;
    background: inherit;
    cursor: auto;
    color: #1d1d1b;
    padding-left: 0;
  }

  .ant-input-number{
    width: 100%;
    background-color: #ffffff;
    color: #000000;
    border-radius: 10px 0 0 10px
  }

  .ant-form-item-required {
  }

  .ant-picker.ant-picker-disabled {
    background: inherit;
    border: none;
    color: #1d1d1b;
    padding: 0;
  }
  .ant-picker-input > input[disabled] {
    color: #1d1d1b;
  }

  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    display: none;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input).ant-select-selector {
    background: inherit;
    color: #1d1d1b;
    border: none;
    cursor: auto;
    padding: 0;
  }
  .ant-select-disabled {
    .ant-select-arrow {
      display: none;
    }
  }

  .ant-form-item-label {
    padding-bottom: 0;
    label {
      color: #80868b;
    }
  }

  .ant-card {
    margin-bottom: 22px;
  }
`;

const HeaderWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 25px;
`;

const TextWrapper = styled.span`
  color: rgb(128, 134, 139);
`;

export const Styled = {
  FormWrapper,
  HeaderWrapper,
  ButtonWrapper,
  TextWrapper
};
