import { useStatementIndexesQuery } from "../api/statementIndexesApi";

export const useGetStatementIndexes = (
  searchParams: string,
  limitFilter: string
) => {
  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess, refetch } =
    useStatementIndexesQuery(`${searchParams}`, {
      skip: skipRequest,
    });

  const statementIndexes = data?.length ? data : [];

  let dataWithoutLastItem = [...statementIndexes];
  limitFilter && dataWithoutLastItem.splice(parseInt(limitFilter) - 1, 1);

  return {
    statementIndexes,
    dataWithoutLastItem,
    data: dataWithoutLastItem,
    error,
    isLoading,
    isSuccess,
    refetch,
    status,
  };
};
