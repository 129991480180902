import React, { useMemo } from "react";
import { Button, Collapse } from "antd";
import { Styled } from "./style";
import { IDLQResponse } from "../../data-layer/system/types/ISystem";
import moment from "moment";

interface ITableProps {
  tableData: IDLQResponse[];
  isLoading: boolean;
  requestStatus: string;
  handleLoadMoreDLQ: () => void;
  onDeleteDLQ: (id: number) => void;
  pagination: boolean;
}

export const Table: React.FC<ITableProps> = ({
  tableData,
  isLoading,
  requestStatus,
  handleLoadMoreDLQ,
  pagination,
  onDeleteDLQ,
}) => {
  const columns = useMemo(() => getColumns(onDeleteDLQ), [onDeleteDLQ]);

  return (
    <>
      <Styled.Table
        loading={requestStatus === "pending" || isLoading}
        columns={columns}
        dataSource={tableData}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
        scroll={{ x: "calc(700px + 50%)" }}
      />
      {!isLoading && tableData.length > 0 && pagination && (
        <Button
          onClick={handleLoadMoreDLQ}
          loading={requestStatus === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more DLQ
        </Button>
      )}
    </>
  );
};

const getColumns = (onDeleteDLQ: (id: number) => void) => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 100,
    fixed: "left",
  },
  {
    title: "Consumer Id",
    dataIndex: "consumerId",
    key: "consumerId",
    width: 150,
    fixed: "left",
  },
  {
    title: "Created",
    dataIndex: "created",
    key: "created",
    width: 150,
    fixed: "left",
    render: (value: string) => moment(value).format("MMMM Do YYYY, h:mm:ss a"),
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
    width: 500,
    render: (info: string) =>
      info && (
        <Collapse>
          <Collapse.Panel header="Details" key="1">
            {info}
          </Collapse.Panel>
        </Collapse>
      ),
  },
  {
    title: "Message id",
    dataIndex: "messageId",
    key: "messageId",
    width: 400,
  },
  {
    title: "Payload",
    dataIndex: "payload",
    key: "payload",
    verticalAlign: "top",
    width: 500,
    render: (info: string) =>
      info && (
        <Collapse>
          <Collapse.Panel header="Payload" key="1">
            {info}
          </Collapse.Panel>
        </Collapse>
      ),
  },
  {
    title: "Queue id",
    dataIndex: "queueId",
    key: "queueId",
    width: 150,
  },
  {
    title: "Service id",
    dataIndex: "serviceId",
    key: "serviceId",
    width: 200,
  },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
    width: 200,
  },
  {
    title: "Delete",
    key: "operation",
    fixed: "right",
    width: 100,
    render: (info: IDLQResponse) => (
      <Button danger onClick={() => onDeleteDLQ(info.id)}>
        delete
      </Button>
    ),
  },
];
