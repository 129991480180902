import React, { useEffect } from "react";
import { Button, Col, Row } from "antd";
import { useSearchParams } from "react-router-dom";
import { FilterOutlined } from "@ant-design/icons";
import {
  MerchantFindFilter,
  SearchTableFilter,
} from "../../../features/filters";
import { useChangeSearchParams } from "../../../shared/hooks/useChangeSearchParams";

export const Filters: React.FC = () => {
  let [searchParams] = useSearchParams();

  const { setInitialParams, resetToInitialParams } = useChangeSearchParams();

  const initialFilters = {
    merchantIds: "",
    nameLike: "",
    limit: "11",
    offset: "0",
  };

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [searchParams]);

  return (
    <>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row>
            <Col xxl={8} xl={10} lg={14}>
              <Row>
                <Col span={12}>
                  <SearchTableFilter
                    placeholder={"Search by name"}
                    filterName={"nameLike"}
                  />
                </Col>
                <Col span={12}>
                  <div style={{ marginLeft: 25 }}>
                    <SearchTableFilter
                      placeholder={"Search by email"}
                      filterName={"emailLike"}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col xxl={8} xl={10} lg={14}>
              <MerchantFindFilter />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Button
                icon={<FilterOutlined />}
                style={{ marginRight: 15 }}
                onClick={() => resetToInitialParams(initialFilters)}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
    // <>
    //   <Row gutter={[0, 15]}>
    //     <Col span={24}>
    //       <Row>
    //         <Col xxl={8} xl={10} lg={14}>
    //           <PartnersFindFilter />
    //         </Col>
    //       </Row>
    //       <Row>
    //         <Col xxl={8} xl={10} lg={14}>
    //           <Row>
    //             <Col span={12}>
    //               <SearchTableFilter
    //                 placeholder={"Search by name"}
    //                 filterName={"nameLike"}
    //               />
    //             </Col>
    //             <Col span={12}>
    //               <SearchTableFilter
    //                 placeholder={"Search by email"}
    //                 filterName={"emailLike"}
    //               />
    //             </Col>
    //           </Row>
    //         </Col>
    //       </Row>
    //     </Col>
    //     <Col span={24}>
    //       <Row>
    //         <Col span={8}>
    //           <Button
    //             icon={<FilterOutlined />}
    //             style={{ marginRight: 15 }}
    //             onClick={() => resetToInitialParams(initialFilters)}
    //           >
    //             Reset
    //           </Button>
    //         </Col>
    //       </Row>
    //     </Col>
    //   </Row>
    // </>
  );
};
