import styled from "styled-components";
import { Typography } from "antd";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  max-width: 570px;
  margin: 0 auto;
  color: #09243a;
`;

const TitleCenter = styled.div`
  text-align: center;
`;

const FormLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #80868b;
`;

const ForgotPasswordLink = styled(Link)`
  color: #80868b;
`;

const LoginTitle = styled(Typography.Title)`
  text-align: center;
  margin-top: 228px;
  margin-bottom: 60px;
  font-weight: 400;
`;

const OTPTitle = styled.div`
  margin-top: 27px;
  margin-bottom: 4px;
  padding-top: 27px;

  font-size: 17px;
  line-height: 135%;

  border-top: 1px solid #78788033;
  color: #000000;

  @media (max-width: 1199px) {
    margin-bottom: 4px;
    padding-top: 22px;
    font-size: 14px;
  }

  @media (max-width: 575px) {
    margin-top: 16px;
  }
`;

const OTPDescription = styled.div`
  margin-bottom: 20px;

  font-size: 17px;
  line-height: 23px;


  @media (max-width: 1199px) {
    font-size: 12px;
    line-height: 16px;
  }

  span {
    white-space: nowrap;
  }
`;

const OTPInputWrapper = styled.div`
  max-width: 350px;

  @media (max-width: 1199px) {
    max-width: 322px;
  }
`;

const ResendOTP = styled.button`
  position: relative;
  margin-top: 9px;
  padding: 0;

  font-size: 14px;
  line-height: 100%;
  text-align: right;

  border: none;

  background: transparent;

  cursor: pointer;

  @media (max-width: 1199px) {
    margin-top: 3px;

    font-size: 12px;
    line-height: 16px;
  }

  span {
    position: absolute;
    top: -5px;
    right: calc(100% + 5px);
    font-size: 18px;
  }
`;

export const Styled = {
  ResendOTP,
  OTPDescription,
  OTPTitle,
  OTPInputWrapper,
  LoginTitle,
  ForgotPasswordLink,
  TitleCenter,
  FormLabel,
  Wrapper,
};
