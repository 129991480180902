import { useGetCrmQuery } from "./api";

export const useGetCRM = (params: string) => {
  const { data, isLoading, status } = useGetCrmQuery(params);

  return {
    data: data || [],
    status,
    isLoading,
  };
};

const mockData = [
  {
    userId: 12,
    userEmail: "user12@email.com",
    companyName: "CompanyName43",
    partnerName: "PartnerName12",
    country: {
      id: 76,
      name: "United Kingdom",
      iso: "GB",
      callingCode: "44",
    },
    crmStatus: "APPLICATION_SUBMITTED",
  },
  {
    userId: 12,
    userEmail: "user12@email.com",
    companyName: "CompanyName43",
    partnerName: "PartnerName12",
    country: {
      id: 76,
      name: "United Kingdom",
      iso: "GB",
      callingCode: "44",
    },
    crmStatus: "APPLICATION_SUBMITTED",
  },
  {
    userId: 12,
    userEmail: "user12@email.com",
    companyName: "CompanyName43",
    partnerName: "PartnerName12",
    country: {
      id: 76,
      name: "United Kingdom",
      iso: "GB",
      callingCode: "44",
    },
    crmStatus: "APPLICATION_SUBMITTED",
  },
  {
    userId: 29,
    userEmail: "user29@email.com",
    companyName: null,
    partnerName: "PartnerName29",
    country: {
      id: 76,
      name: "United Kingdom",
      iso: "GB",
      callingCode: "44",
    },
    crmStatus: "FIRST_TRANSACTION_DONE",
  },
];
