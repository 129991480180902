import React from "react";
import { Drawer } from "antd";
import { Styled } from "scenarios/TransactionSc/style";
import { IEmployeeResponse } from "../types";

interface IEmployeeItem {
  visible: boolean;
  onClose: () => void;
  data: IEmployeeResponse;
}

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
}

export const EmployeeInfo: React.FC<IEmployeeItem> = ({
  visible,
  onClose,
  data,
}) => {
  const {
    address,
    email,
    id,
    name,
    password,
    status,
    surname,
    tid,
    cctExternalId,
    cctTerminalExternalId,
    city,
    postalCode,
    vacStoreProfileId,
    vacStoreProfileName,
  } = data;

  const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
    <Styled.DescriptionContainer>
      <span style={{ marginRight: 10 }}>{title}:</span>
      <Styled.DescriptionValue>{content}</Styled.DescriptionValue>
    </Styled.DescriptionContainer>
  );

  return (
    <Drawer
      title="Employee"
      placement={"right"}
      onClose={onClose}
      visible={visible}
    >
      <DescriptionItem title="Address" content={address} />
      <DescriptionItem title="Email" content={email} />
      <DescriptionItem title="Name" content={name} />
      <DescriptionItem title="Surname" content={surname} />
      <DescriptionItem title="Id" content={id} />
      <DescriptionItem title="Password" content={password} />
      <DescriptionItem title="Tid" content={tid} />
      <DescriptionItem title="Status" content={status} />
      <DescriptionItem title="CCT External Id" content={cctExternalId} />
      <DescriptionItem
        title="CCT Terminal External  Id"
        content={cctTerminalExternalId}
      />
      <DescriptionItem title="City" content={city} />
      <DescriptionItem title="Postal code" content={postalCode} />
      <DescriptionItem
        title="Vac store profile Id"
        content={vacStoreProfileId}
      />
      <DescriptionItem
        title="Vac store profile name"
        content={vacStoreProfileName}
      />
    </Drawer>
  );
};
