import React, { useRef } from "react";
import { Col, Form as AntForm, Form, Input, Row } from "antd";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { useSearchParams } from "react-router-dom";

interface IRangeFilter {
  placeholderFirst?: string;
  placeholderSecond?: string;
  filterNameFirst: string;
  filterNameSecond: string;
}

export const RangeFilter: React.FC<IRangeFilter> = ({
  placeholderFirst,
  placeholderSecond,
  filterNameFirst,
  filterNameSecond,
}) => {
  const inputRef = useRef(null);
  const { changeSearchParams } = useChangeSearchParams();
  let [searchParams] = useSearchParams();

  const [form] = AntForm.useForm();

  const searchFilterFirst = searchParams.get(filterNameFirst);
  const searchFilterSecond = searchParams.get(filterNameSecond);

  const onPressEnterFirstInput = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onStartInputChange = () => {
    form.validateFields(["startRange", "endRange"]);
  };

  const onPressEnterSecondInput = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    form.validateFields(["startRange", "endRange"]).then((res) => {
      changeSearchParams({ [filterNameSecond]: event.target.value });
    });
  };

  const onBlurStartInput = (event) => {
    form.validateFields(["startRange", "endRange"]).then((res) => {
      changeSearchParams({ [filterNameFirst]: event.target.value });
    });
  };

  const onBlurEndInput = (event) => {
    form.validateFields(["startRange", "endRange"]).then((res) => {
      changeSearchParams({ [filterNameSecond]: event.target.value });
    });
  };

  return (
    <Form name={"rangeFilter"} form={form}>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="startRange"
            style={{ margin: 0 }}
            initialValue={searchFilterFirst}
          >
            <Input
              placeholder={placeholderFirst || "from"}
              onPressEnter={onPressEnterFirstInput}
              defaultValue={searchFilterFirst}
              onChange={onStartInputChange}
              onBlur={onBlurStartInput}
              type={"number"}
              min="0"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            style={{ margin: 0 }}
            name={"endRange"}
            initialValue={searchFilterSecond}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && getFieldValue("startRange")) {
                    if (
                      parseInt(value) < parseInt(getFieldValue("startRange"))
                    ) {
                      return Promise.reject(
                        "End range must be greater than start"
                      );
                    } else if (value === getFieldValue("startRange")) {
                      return Promise.reject("End cannot be same as start");
                    } else {
                      return Promise.resolve();
                    }
                  } else return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder={placeholderSecond || "to"}
              defaultValue={searchFilterSecond}
              onPressEnter={onPressEnterSecondInput}
              onBlur={onBlurEndInput}
              ref={inputRef}
              type={"number"}
              min="0"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
