import { crmStatuses } from "../../pages/crm/types";

export const onboardingStatusMap = [
  {
    value: "APPROVED",
    name: "Approved",
  },
  {
    value: "CHANGES_REQUESTED",
    name: "Changes requested",
  },
  {
    value: "NEW",
    name: "New",
  },
  {
    value: "REJECTED",
    name: "Rejected",
  },
  {
    value: "REVIEWING",
    name: "Reviewing",
  },
];

export const EMPLOYEE_STATUS_MAP = [
  {
    value: "NEW",
    name: "New",
  },
  {
    value: "EXPORTED",
    name: "Exported",
  },
  {
    value: "ZERO_TRANSACTION",
    name: "Zero transaction",
  },
  {
    value: "EXTERNAL_SYSTEM_NOT_READY",
    name: "External system not ready",
  },
  {
    value: "ACTIVATED",
    name: "Activated",
  },
  {
    value: "SUSPENDED",
    name: "Suspended",
  },
];

export const CRM_STATUS_MAP = [
  {
    value: crmStatuses.NONE,
    name: "None status",
  },
  {
    value: crmStatuses.APPLICATION_APPROVED,
    name: "Application approved",
  },
  {
    value: crmStatuses.APPLICATION_REJECTED,
    name: "Application rejected",
  },
  {
    value: crmStatuses.APPLICATION_SUBMITTED,
    name: "Application submitted",
  },
  {
    value: crmStatuses.SIGNED_UP,
    name: "Sign-up completed",
  },
  {
    value: crmStatuses.INVITED,
    name: "Invited merchants",
  },
  {
    value: crmStatuses.FIRST_EMPLOYEE_SETUP_DONE,
    name: "Immediate setup completed",
  },
  {
    value: crmStatuses.FIRST_TRANSACTION_DONE,
    name: "First transaction",
  },
  {
    value: crmStatuses.FIRST_PAYOUT_DONE,
    name: "First payout",
  },
];
