import { Spin, Upload, UploadFile as UploadFileType } from "antd";
import { UploadButton } from "../../ui/UploadButton";
import React, { useState } from "react";
import { useUpload } from "../../data-layer/sekumpulUpload/hooks/useUpload";
import { Styled } from "./styled";
import { useDownloadFileByName } from "../../data-layer/sekumpulUpload/hooks/useDownloadFileByName";

interface IUploadFileProps {
  name?: string;
  onAfterUpload?: (uploadedFileName: string) => void;
  logoImageFilename?: string | null;
}

export const UploadFile: React.FC<IUploadFileProps> = ({
  name,
  onAfterUpload,
  logoImageFilename,
}) => {
  const { onDownload } = useDownloadFileByName();
  const [uploadedItem, setUploadedItem] = useState<string>();
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const { upload } = useUpload();

  const onUploadChange = async (file: UploadFileType) => {
    setIsUploadLoading(true);
    const uploadedFileName = await upload(file);
    onAfterUpload && onAfterUpload(uploadedFileName?.error?.data);
    setUploadedItem(uploadedFileName?.error?.data);
    setIsUploadLoading(false);
  };

  return (
    <Styled.UploadWrapper>
      <Upload
        beforeUpload={() => false}
        maxCount={1}
        fileList={[]}
        // onPreview={(file) => onUpload(file)}
        onChange={({ file, fileList }) => onUploadChange(file)}
      >
        <UploadButton />
      </Upload>
      {isUploadLoading ? (
        <Styled.UploadSpinWrapper />
      ) : (
        <a onClick={() => onDownload(uploadedItem || logoImageFilename)}>
          {uploadedItem || logoImageFilename}
        </a>
      )}
    </Styled.UploadWrapper>
  );
};
