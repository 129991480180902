import React from "react";
import { Form, Modal, Select } from "antd";
import { REJECT_REASONS_MAP } from "../utils";
import { Styled } from "../styled";

interface IApproveModal {
  isVisibleModal: boolean;
  onReject: (reason: string) => void;
  onCancel: () => void;
  rejectLoading: boolean;
}

export const RejectModal: React.FC<IApproveModal> = ({
  isVisibleModal,
  onReject,
  rejectLoading,
  onCancel,
}) => {
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const { Option } = Select;

  return (
    <Modal
      open={isVisibleModal}
      footer={null}
      onCancel={onCancel}
      title="Reject"
    >
      <Form onFinish={(fields) => onReject(fields?.rejectReason)}>
        <Form.Item
          label={"Reason for rejecting:"}
          labelCol={{ span: 24 }}
          name="rejectReason"
          rules={[
            {
              required: true,
              message: "Please select reject reason",
            },
          ]}
        >
          <Select showSearch filterOption={filterOption} placeholder={"Reason"}>
            {REJECT_REASONS_MAP.map((item, i) => (
              <Option key={item.value}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Styled.ModalButtonWrapper>
          <Styled.CancelButton onClick={onCancel}>Cancel</Styled.CancelButton>
          <Styled.RejectReasonButton
            danger
            // onClick={onReject}
            htmlType={"submit"}
            loading={rejectLoading}
            disabled={rejectLoading}
          >
            Reject
          </Styled.RejectReasonButton>
        </Styled.ModalButtonWrapper>
      </Form>
    </Modal>
  );
};
