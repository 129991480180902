import React from "react";
import { useGetCRM } from "../api/useGetCRM";
import { crmStatuses } from "../types";
import { CRM_STATUS_MAP } from "../../../shared/constants/statuses";
import { Card } from "./Card";
import { Styled } from "../style";
import { Flex } from "ui/Flex";
import { Container } from "../../../ui/Container";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "ui/Spinner/Spinner";
import { Empty } from "antd";
export const CardsList: React.FC = () => {
  let [searchParams] = useSearchParams();

  const { data, status, isLoading } = useGetCRM(searchParams.toString());

  if (status === "pending" || isLoading) return <Spinner />;
  const renderListByStatus = (status: crmStatuses) => {
    const validMap = data.filter((crm) => crm.crmStatus === status);

    return (
      <Container marginTop={15}>
        <Flex
          gap={10}
          width={"350px"}
          alignItems={"center"}
          css={`
            margin-bottom: 25px;
          `}
        >
          <Styled.ListTitle>
            {CRM_STATUS_MAP.find((item) => item.value === status)?.name}
          </Styled.ListTitle>
          {validMap.length > 0 && (
            <Styled.Counter>{validMap.length}</Styled.Counter>
          )}
        </Flex>
        <Flex direction={"column"} gap={10}>
          {validMap.length === 0 ? (
            <Styled.CardContainer>
              <Empty />
            </Styled.CardContainer>
          ) : (
            validMap.map((element) => (
              <Card
                key={element?.userId}
                id={element?.userId}
                email={element?.userEmail}
                country={element?.country?.name}
                partnerName={element?.partnerName}
              />
            ))
          )}
        </Flex>
      </Container>
    );
  };

  return (
    <Flex gap={10}>
      {renderListByStatus(crmStatuses.INVITED)}
      {renderListByStatus(crmStatuses.SIGNED_UP)}
      {renderListByStatus(crmStatuses.APPLICATION_SUBMITTED)}
      {renderListByStatus(crmStatuses.APPLICATION_APPROVED)}
      {renderListByStatus(crmStatuses.APPLICATION_REJECTED)}
      {renderListByStatus(crmStatuses.FIRST_EMPLOYEE_SETUP_DONE)}
      {renderListByStatus(crmStatuses.FIRST_TRANSACTION_DONE)}
      {renderListByStatus(crmStatuses.FIRST_PAYOUT_DONE)}
    </Flex>
  );
};
