import { useGetRolesQuery } from "../api/rolesApi";

export const useRoles = () => {
  const { data, error, status, isLoading, isSuccess } = useGetRolesQuery("");

  return {
    data: data || [],
    error,
    isLoading,
    isSuccess,
    requestStatus: status,
  };
};
