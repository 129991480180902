import React from "react";
import { PopoverProps } from "antd/lib/popover";
import { Popover as AntPopover } from "antd";

interface IPopoverProps extends PopoverProps {
  disabled?: boolean;
  children: JSX.Element;
}

export const Popover: React.FC<IPopoverProps> = ({
  disabled,
  children,
  ...rest
}) => {
  const MyPopover = () =>
    disabled ? <>{children}</> : <AntPopover {...rest} children={children} />;

  return <MyPopover />;
};
