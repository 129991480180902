import { useGetOverviewPartnersQuery } from "../api/adminControllerApi";

export const useGetOverviewPartners = (params: string) => {
  const paramsReplacedPartnersToPartner = params.replace(
    "partnerIds",
    "partnerId"
  );

  const { data, error, isLoading, isSuccess, status } =
    useGetOverviewPartnersQuery(paramsReplacedPartnersToPartner);

  return {
    overviewPartnersData: data,
    error,
    isLoading,
    isSuccess,
    status,
  };
};
