import { useUpdatePayoutsMutation } from "../api/payoutApi";
import { notification } from "antd";

export const useUpdatePayout = (refetch: () => void) => {
  const [updatePayout] = useUpdatePayoutsMutation();

  const onApprovePayout = (id: number, onClose: () => void) => {
    const params = {
      ids: [id],
      status: "APPROVED",
    };
    updatePayout(params).then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Payout approved",
          type: "success",
        });
        onClose();
        refetch();
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    onApprovePayout,
  };
};
