import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";

// Define a service using a base URL and expected endpoints
export const attachmentApi = createApi({
  reducerPath: "attachmentApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    attachmentsDownload: builder.query<any, string>({
      query: (fileHashName) => `/attachments/download/${fileHashName}`,
    }),
    attachmentByIds: builder.mutation<IAttachmentsResponse, string>({
      query: (id) => `/attachments/admin/${id}`,
    }),
    attachmentsListByIds: builder.query<IAttachmentsResponse[], string>({
      query: (ids) => `/attachments/admin/list${ids}`,
    }),
  }),
});
export const {
  useAttachmentsDownloadQuery,
  useAttachmentsListByIdsQuery,
  useAttachmentByIdsMutation,
} = attachmentApi;
