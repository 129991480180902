import React from "react";
import { Button } from "antd";
import { Styled as TableStyled } from "shared/style";
import {
  IPartnerResponse,
  IPartnerWithMerchants,
} from "../../data-layer/partner/types/partnerTypes";

interface ITableProps {
  tableData: IPartnerResponse[];
  isLoading: boolean;
  requestStatus: string;
  handleLoadMorePartners: () => void;
  pagination: boolean;
  onRowClick: (partnerInfo: IPartnerWithMerchants) => void;
}

export const Table: React.FC<ITableProps> = ({
  tableData,
  isLoading,
  requestStatus,
  handleLoadMorePartners,
  pagination,
  onRowClick,
}) => {
  return (
    <>
      <TableStyled.Table
        loading={requestStatus === "pending" || isLoading}
        columns={columns}
        dataSource={tableData}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
        onRow={(record) => {
          return {
            onClick: (event) => onRowClick(record),
          };
        }}
      />
      {!isLoading && tableData.length > 0 && pagination && (
        <Button
          onClick={handleLoadMorePartners}
          loading={requestStatus === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more partners
        </Button>
      )}
    </>
  );
};

export const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Partner",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Number of associated merchants",
    key: "merchantsCount",
    render: (item: IPartnerWithMerchants) => {
      return item.merchants.length;
    },
  },
];
