import styled from "styled-components";
import { Spin } from "antd";

const InviteModalItemListWrapper = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
`;

const UploadWrapper = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
`;

const UploadSpinWrapper = styled(Spin)`
  margin-left: 25%;
`;

const PartnerDetailsFormWrapper = styled.div`
  .ant-form-item {
    margin: 0;
  }

  .ant-input[disabled] {
    border: none;
    background: inherit;
    cursor: auto;
    color: #1d1d1b;
    padding-left: 0;
  }

  .ant-form-item-required {
  }

  .ant-picker.ant-picker-disabled {
    background: inherit;
    border: none;
    color: #1d1d1b;
    padding: 0;
  }
  .ant-picker-input > input[disabled] {
    color: #1d1d1b;
  }

  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    display: none;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: inherit;
    color: #1d1d1b;
    border: none;
    cursor: auto;
    padding: 0;
  }
  .ant-select-disabled {
    .ant-select-arrow {
      display: none;
    }
  }

  .ant-form-item-label {
    padding-bottom: 0;
    label {
      color: #80868b;
    }
  }

  .ant-card {
    margin-bottom: 22px;
  }
`;

const ActionButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 24px;
  width: 310px;
  height: 40px;
`;

const EditableButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;
`;

export const Styled = {
  InviteModalItemListWrapper,
  PartnerDetailsFormWrapper,
  ActionButtonWrapper,
  EditableButtonContainer,
  UploadWrapper,
  UploadSpinWrapper,
};
