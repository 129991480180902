import {Col, Divider, Form as AntForm, Input, InputNumber, Row, Select,} from "antd";
import {IMerchantDetailsFormPart, Kinds, OriginTypes, Statuses} from "./types";

export const MerchantDetailsTab: React.FC<IMerchantDetailsFormPart> = ({
  countries,
  timezones,
  currencies,
  mccList,
  partners,
  initialData,
  validator,
}) => {


  return (
    <Row gutter={24}>
      <Col span={16}>
        <AntForm.Item
          rules={[validator]}
          name={"merchantName"}
          id={"merchantName"}
          label={"Merchant Name"}
          initialValue={initialData?.merchantName}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"merchantNumber"}
          id={"merchantNumber"}
          label={"Merchant Number"}
          initialValue={initialData?.merchantNumber}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"dba"}
          id={"dba"}
          label={"DBA (Trading As)"}
          initialValue={initialData?.dba}
        >
          <Input />
        </AntForm.Item>

        <AntForm.Item
          rules={[validator]}
          name={"kind"}
          id={"kind"}
          label={"Kind"}
          initialValue={initialData?.kind}
        >
          <Select
            placeholder="Kind"
            options={Kinds.map((item) => ({
              value: item,
              label: item.replace("_", " "),
            }))}
          ></Select>
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"status"}
          id={"status"}
          label={"Status"}
          initialValue={initialData?.status}
        >
          <Select
            placeholder="Status"
            options={Statuses.map((item) => ({
              value: item,
              label: item.replace("_", " "),
            }))}
          ></Select>
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"mcc"}
          id={"mcc"}
          label={"MCC"}
          initialValue={initialData?.mcc}
        >
          <Select
            placeholder="MCC"
            options={mccList?.map((item) => ({
              value: item,
              label: item,
            }))}
          ></Select>
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"feePercent"}
          id={"feePercent"}
          label={"Fee percent"}
          initialValue={initialData?.feePercent || 0}
        >
          <InputNumber
              step={0.01}
              max={100}
              min={0}
              formatter={(value) => `${value}%`}
              parser={(value) => value!.replace('%', '')}
          />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["partner", "id"]}
          id={"partnerId"}
          label={"Partner"}
          initialValue={initialData?.partner?.id}
        >
          <Select
            showSearch
            placeholder="Partner"
            options={partners?.map((item) => ({
              value: item.id,
              label: `${item.name} (${item.feePercent}%)`,
            }))}
          ></Select>
        </AntForm.Item>
        <Divider plain>Locale</Divider>
        <AntForm.Item
          rules={[validator]}
          name={"country"}
          id={"country"}
          label={"Country"}
          initialValue={initialData?.country}
        >
          <Select
            showSearch
            optionFilterProp="label"
            placeholder="Country"
            options={countries?.map((item) => ({
              value: item.iso,
              label: item.name,
            }))}
          ></Select>
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"timeZone"}
          id={"timeZone"}
          label={"Timezone"}
          initialValue={initialData?.timeZone}
        >
          <Select
            showSearch
            placeholder="Timezone"
            options={timezones?.map((item) => ({
              value: item,
              label: item,
            }))}
          ></Select>
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"currency"}
          id={"currency"}
          label={"Currency"}
          initialValue={initialData?.currency}
        >
          <Select
            showSearch
            placeholder="Currency"
            options={currencies?.map((item) => ({
              value: item,
              label: item,
            }))}
          ></Select>
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={"originType"}
          id={"originType"}
          label={"Origin Type"}
          initialValue={initialData?.originType || "EXTERNAL"}
        >
          <Select
            placeholder="Origin Type"
            disabled={true}
            options={OriginTypes.map((item) => ({
              value: item,
              label: item.replace("_", " "),
            }))}
          ></Select>
        </AntForm.Item>
      </Col>
    </Row>
  );
};
