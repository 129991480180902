import React, { useState } from "react";
import { Table } from "./Table";
import { Helmet } from "react-helmet";
import { Button, PageHeader } from "antd";
import { Filters } from "./Filters";
import { useGetLocales } from "../../data-layer/i18settings/hooks/useGetLocales";
import { useSearchParams } from "react-router-dom";
import { CreateLocaleModal } from "./CreateLocaleModal";
import { useCreateLocale } from "../../data-layer/i18settings/hooks/useCreateLocale";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { AppliedFilterInput } from "../../components/AppliedFiltersInput";

export const I18nSettings: React.FC = () => {
  const [visibleCreateLocaleModal, setVisibleCreateLocaleModal] =
    useState(false);

  let [searchParams] = useSearchParams();

  const { changeSearchParams } = useChangeSearchParams();

  const limitFilter = searchParams.get("limit") || "";

  const { localesData, isLoading, requestStatus } = useGetLocales(
    searchParams.toString(),
    limitFilter
  );
  const { onCreateLocale } = useCreateLocale();

  const handleLoadMoreLocale = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };

  const isAllowPagination = parseInt(limitFilter) === localesData.length;

  return (
    <>
      <Helmet>
        <title>I18nSettings</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="I18nSettings" />
      <Filters />
      <AppliedFilterInput />
      <Button
        type="primary"
        style={{ marginBottom: 25, marginTop: 15 }}
        onClick={() => setVisibleCreateLocaleModal(true)}
      >
        Create locale
      </Button>
      <Table
        pagination={isAllowPagination}
        requestStatus={requestStatus}
        data={localesData}
        isLoading={requestStatus === "pending" || isLoading}
        handleLoadMoreLocale={handleLoadMoreLocale}
      />
      <CreateLocaleModal
        visible={visibleCreateLocaleModal}
        setVisible={setVisibleCreateLocaleModal}
        onSubmit={onCreateLocale}
      />
    </>
  );
};
