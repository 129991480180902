import React, { useEffect, useState } from "react";
import { Col, DatePicker, Input, Row } from "antd";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import moment from "moment";

export const Filters: React.FC = () => {
  const { Search } = Input;

  let [searchParams] = useSearchParams();

  const { changeSearchParams, setInitialParams } = useChangeSearchParams();

  const initialFilters = {
    name: "",
    limit: "11",
    offset: "0",
    fileName: "",
    sourceExternalPaymentId: "",
    from: "",
    to: "",
  };

  const fileNameSearchFilter = searchParams.get("fileName");
  const sourceExternalPaymentIdFilter = searchParams.get(
    "sourceExternalPaymentId"
  );

  const dateFromFilter = searchParams.get("from");
  const dateToFilter = searchParams.get("to");

  const rangeFilter = [
    dateFromFilter && moment(dateFromFilter),
    dateToFilter && moment(dateToFilter),
  ];

  const [fileName, setFileNameSearch] = useState(
    fileNameSearchFilter || undefined
  );
  const [sourceExternalPaymentId, setSourceExternalPaymentIdSearch] = useState(
    sourceExternalPaymentIdFilter || undefined
  );

  const handleFileNameSearchFilter = (search: string) => {
    changeSearchParams({ fileName: search });
  };

  const handleSourceExternalPaymentIdSearchFilter = (search: string) => {
    changeSearchParams({ sourceExternalPaymentId: search });
  };

  const handleDateChange = (momentRange: any) => {
    if (momentRange === null) {
      changeSearchParams({
        from: "",
        to: "",
      });
    }
    changeSearchParams({
      from: momentRange[0].toDate().toISOString(),
      to: momentRange[1].toDate().toISOString(),
    });
  };

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [searchParams]);

  const { RangePicker } = DatePicker;

  return (
    <>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Row>
                <Col span={12}>
                  <Search
                    placeholder={"Search by source external paymentId"}
                    onSearch={handleSourceExternalPaymentIdSearchFilter}
                    enterButton={false}
                    defaultValue={fileNameSearchFilter || ""}
                    value={sourceExternalPaymentId}
                    onChange={(e) =>
                      setSourceExternalPaymentIdSearch(e.target.value)
                    }
                  />
                </Col>
                <Col span={12}>
                  <RangePicker
                    format={"DD MMM"}
                    onChange={handleDateChange}
                    value={rangeFilter}
                    style={{ marginLeft: 25 }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Search
                placeholder={"Search by file name"}
                onSearch={handleFileNameSearchFilter}
                enterButton={false}
                defaultValue={fileNameSearchFilter || ""}
                value={fileName}
                onChange={(e) => setFileNameSearch(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
