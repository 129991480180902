import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { history } from "../../routes/AppRouter";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

let url = "dev.backend";
if (
  ENVIRONMENT === "development" &&
  sessionStorage.getItem("isUseProductionData")
) {
  url = "test-aws";
}
if (ENVIRONMENT === "production") {
  url = "test-aws";
} else if (ENVIRONMENT === "staging") {
  url = "staging.backend";
}

export const duatigaApiPath = `https://${url}.dapio.com/duatiga`;
export const crateApiPath = `https://${url}.dapio.com/crate`;
export const authApiPath = `https://${url}.dapio.com/sunset`;
export const sekumpulApiPath = `https://${url}.dapio.com/sekumpul`;
export const emailTestApiPath = `https://${url}.dapio.com/grab`;

const createQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("authToken");

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  });

export const baseQuery = createQuery(duatigaApiPath);
export const crateQuery = createQuery(crateApiPath);
export const authQuery = createQuery(authApiPath);
export const sekumpulQuery = createQuery(sekumpulApiPath);
export const emailTestQuery = createQuery(emailTestApiPath);

export const baseQueryInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.removeItem("authToken");
    history.push("/signin");
  }
  return result;
};
