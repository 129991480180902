import { useGetPartnersQuery } from "../api/partnerApi";
import { IPartnerResponse } from "../types/partnerTypes";
import { IMerchantResponse } from "../../merchant/types/merchantTypes";

export const usePartners = (
  searchParams: string,
  merchants: IMerchantResponse[]
) => {
  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess, refetch } =
    useGetPartnersQuery(searchParams, {
      skip: skipRequest,
    });

  const partnersData = addMerchToPartner(data || [], merchants);

  return { data: partnersData, error, status, isLoading, isSuccess, refetch };
};

const addMerchToPartner = (
  data: IPartnerResponse[],
  merchants: IMerchantResponse[]
) => {
  return data.map((item) => {
    return {
      ...item,
      key: item?.id,
      merchants: merchants.filter(
          (merch) => merch.partner?.id === item.id
      ),
    };
  });
};
