import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";
import {
  IEmployeePermissions,
  IEmployeeResponse,
  ISetStatusEmployeeRequest,
} from "../types";

// Define a service using a base URL and expected endpoints
export const employeeApi = createApi({
  reducerPath: "employeeApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getEmployeeAdmin: builder.query<IEmployeeResponse[], string>({
      query: (params) => `/employees/find?${params}`,
    }),
    getEmployeePermissions: builder.query<IEmployeePermissions[], string>({
      query: () => `/employee-permissions`,
    }),
    findEmployee: builder.mutation<IEmployeeResponse[], string>({
      query: (params) => `/employees/find?${params}`,
    }),
    createEmployee: builder.mutation<IEmployeeResponse, any>({
      query: (credentials) => ({
        url: `/employees/admin`,
        method: "POST",
        body: credentials,
      }),
    }),
    setStatusEmployee: builder.mutation<
      IEmployeeResponse,
      ISetStatusEmployeeRequest
    >({
      query: (credentials) => ({
        url: `/employees/set-status`,
        method: "PUT",
        body: credentials,
      }),
    }),
  }),
});
export const {
  useGetEmployeeAdminQuery,
  useFindEmployeeMutation,
  useCreateEmployeeMutation,
  useSetStatusEmployeeMutation,
  useGetEmployeePermissionsQuery,
} = employeeApi;
