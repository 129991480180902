import React from "react";
import {
  Button,
  Form,
  Modal,
  Input,
  Space,
  Checkbox,
  Select,
  Row,
  Col,
  Typography,
} from "antd";
import { useGetMerchants } from "../../../shared/api/merchants/useGetMerchants";
import { useGetPermissions } from "../api/useGetPermissions";
import { useCreateEmployee } from "../api/useCreateEmployee";

interface ICreateEmployeeModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const CreateEmployee: React.FC<ICreateEmployeeModal> = ({
  visible,
  setVisible,
}) => {
  const merchantsData = useGetMerchants("name=&limit=1000&offset=0");

  const { createEmployee } = useCreateEmployee();

  const { data: permissionsData, permissionCheckboxOptions } =
    useGetPermissions();

  const [form] = Form.useForm();

  return (
    <Modal
      open={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      title="Create employee"
    >
      <Form
        onFinish={(data) => {
          createEmployee(data, permissionsData, form.resetFields);
          setVisible(false);
        }}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input name" }]}
        >
          <Input name="name" placeholder={"name"} />
        </Form.Item>
        <Form.Item
          label="Surname"
          name="surname"
          rules={[{ required: true, message: "Please input surname" }]}
        >
          <Input name="surname" placeholder={"surname"} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input email" }]}
        >
          <Input name="email" placeholder={"email"} />
        </Form.Item>
        <Form.Item
          label="Address of terminal usage"
          name="address"
          rules={[{ required: true, message: "Please input address" }]}
        >
          <Input name="address" placeholder={"Address of terminal usage"} />
        </Form.Item>
        <Row gutter={25}>
          <Col span={12}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "Please input city" }]}
            >
              <Input name="city" placeholder={"city"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Post code"
              name="postalCode"
              rules={[{ required: true, message: "Please input post code" }]}
            >
              <Input name="postalCode" placeholder={"Post code"} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Pin"
          name={"password"}
          rules={[{ required: true, message: "Please input password" }]}
        >
          <Input.Password name={"password"} />
        </Form.Item>
        <Typography.Title level={5}>Permissions</Typography.Title>
        <Row>
          <Form.Item
            name="permissions"
            rules={[{ required: true, message: "Please select permission" }]}
          >
            <Checkbox.Group options={permissionCheckboxOptions} />
          </Form.Item>
        </Row>

        <Form.Item
          name="merchantId"
          label={"Select merchant"}
          rules={[{ required: true, message: "Please select merchant" }]}
        >
          <Select>
            {merchantsData?.data.map((item) => (
              <Select.Option key={item.id}>{item.merchantName}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Space>
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button type="primary" htmlType={"submit"}>
              Create employee
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};
