import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import { TransactionSc } from "../scenarios/TransactionSc";
import { PayoutSC } from "../scenarios/PayoutSC";
import { Partners } from "../scenarios/PartnerSc";
import { Merchants } from "../scenarios/MerchantsSc";
import { MerchantEdit } from "../scenarios/MerchantEdit/MerchantEdit";
import { MerchantEdit as MerchantUpdate } from "../scenarios/merchant/edit";
import { DLQSc } from "../scenarios/DLQSc";
import { UsersSc } from "../scenarios/UsersSc";
import { TerminalsSC } from "../scenarios/TerminalsSC";
import { OnboardingDetails } from "../pages/onboardingDetails";
import { PasswordRecoverySc } from "../scenarios/PasswordRecoverySc";
import { StatementIndexes } from "../scenarios/StatementIndexesSc";
import jwtDecode from "jwt-decode";
import { useLogout } from "../data-layer/auth/hooks/useLogout";
import { I18nSettings } from "../scenarios/I18nSettings";
import { OverviewSc } from "../scenarios/OverviewSc";
import { Login } from "../pages/auth";
import { Onboardings } from "../pages/onboardings";
import { Employees } from "../pages/employees";
import { NotFound } from "../pages/notFound";
import { CRM } from "../pages/crm";
import { MainLayout } from "../components/MainLayout/MainLayout";
import {Reports} from "../pages/reports";
import { MerchantCreate } from "scenarios/merchant/create";
import { FiservProcessesSc } from "scenarios/FiservProcessesSc/FiservProcessesSc";

export const history = createBrowserHistory();
export const entryPath = "/dashboard/onboardings";

const PrivateRoute: React.FC<any> = (props) => {
  const auth = localStorage.getItem("authToken");
  const { sessionLogout } = useLogout();

  useEffect(() => {
    if (auth) {
      const { exp } = jwtDecode(auth);
      const timeDifference = exp * 1000 - Date.now();
      setTimeout(() => sessionLogout(), timeDifference);
    }
  }, []);

  const { pathname, search } = useLocation();
  const previousUrl = pathname + search;
  if (!auth) return <Navigate to="/signin" state={{ from: previousUrl }} />;
  else if (pathname === "/") return <Navigate to={entryPath} />;
  else return <Outlet />;
};

export const AppRouter = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="dashboard" element={<MainLayout />}>
            <Route path="onboardings" element={<Onboardings />} />
            <Route path="transactions" element={<TransactionSc />} />
            <Route path="payouts">
              <Route
                path="merchant"
                element={<PayoutSC beneficiaryTypes={"MERCHANT"} />}
              />
              <Route
                path="partner"
                element={<PayoutSC beneficiaryTypes={"PARTNER"} />}
              />
            </Route>
            <Route path="employees" element={<Employees />} />
            <Route path="partners" element={<Partners />} />
            <Route path="merchants" element={<Merchants />} />
            <Route path="merchant-create" element={<MerchantCreate />} />
            <Route path="merchants/:id" element={<MerchantUpdate />} />
            <Route path="users" element={<UsersSc />} />
            <Route path="overview" element={<OverviewSc />} />
            <Route path="reports" element={<Reports />} />
            <Route path="crm" element={<CRM />} />
            <Route path="onboarding-info/:id" element={<OnboardingDetails />} />
            <Route path="merchant-edit/:id" element={<MerchantEdit />} />
            <Route path="tools">
              <Route path="dlq" element={<DLQSc />} />
              <Route path="terminals" element={<TerminalsSC />} />
              <Route path="fiserv" element={<FiservProcessesSc />} />
              <Route path="statement-indexes" element={<StatementIndexes />} />
              <Route path="i18settings" element={<I18nSettings />} />
            </Route>
          </Route>
        </Route>
        <Route path={"signin"} element={<Login />} />
        <Route path={"password-recovery"} element={<PasswordRecoverySc />} />
      </Routes>
    </HistoryRouter>
  );
};
