import { notification } from "antd";
import { IEmployeePermissions } from "../types";
import { useCreateEmployeeMutation } from "./api";

export const useCreateEmployee = () => {
  const [createEmployeeQuery] = useCreateEmployeeMutation();

  const createEmployee = (
    data: any,
    permissionsData: IEmployeePermissions[],
    clearForm: (fields?: (string | number)[] | undefined) => void
  ) => {
    const validData = validateCreateEmployeeData(data, permissionsData);
    createEmployeeQuery(validData).then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Request sent successfully",
          type: "success",
        });
        clearForm();
      } else if ("error" in result) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    createEmployee,
  };
};

const validateCreateEmployeeData = (
  data: any,
  permissionsData: IEmployeePermissions[]
) => {
  const validData = {
    data: {
      ...data,
      permissions: getValidPermissions(data.permissions, permissionsData),
    },
    merchantId: data.merchantId,
  };

  delete validData.data.merchantId;

  return validData;
};

const getValidPermissions = (
  data: number[],
  permissionsData: IEmployeePermissions[]
) => {
  return permissionsData
    .filter((item) => {
      return data.includes(item.id);
    })
    .map((permission) => permission.id);
};
