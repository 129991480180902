import React from "react";

export enum ROLES {
  ADMIN = "ADMIN",
  COMPLIANCE_OFFICER = "COMPLIANCE_OFFICER",
  EMPLOYEE = "EMPLOYEE",
  MERCHANT = "MERCHANT",
  PARTNER = "PARTNER",
  COMPLIANCE_OFFICER_ASSISTANT = "COMPLIANCE_OFFICER_ASSISTANT",
}

export type RoleTypes = keyof typeof ROLES;

interface IAuthorization {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
  allowedRoles: RoleTypes[];
}

export const useAuthorization = () => {
  const user = JSON.parse(localStorage.getItem("user") as string);

  if (!user) {
    throw Error("User does not exist!");
  }

  const checkAccess = React.useCallback(
    ({ allowedRoles }: { allowedRoles: RoleTypes[] }) => {
      let canAccess = false;
      if (allowedRoles && allowedRoles.length > 0) {
        // return allowedRoles?.includes(user.roles);
        allowedRoles.forEach((role) => {
          if (user.roles?.includes(role)) {
            canAccess = true;
          }
        });
      }
      return canAccess;
    },
    [user.roles]
  );

  return { checkAccess, role: user.roles };
};

export const Authorization: React.FC<IAuthorization> = ({
  children,
  allowedRoles,
  forbiddenFallback,
}) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  // if (typeof policyCheck !== "undefined") {
  //   canAccess = policyCheck;
  // }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
