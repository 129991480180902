import * as yup from "yup";

const UNITED_KINGDOM_ID = "UK";

export const generalSchema = yup.object().shape({
  ["bankAccountNumber"]: yup.string().required("Required"),
  ["cctExternalId"]: yup.string(),
  ["cdxExternalId"]: yup.string().required("Required"),
  ["country"]: yup.string().required("Required"),
  ["currency"]: yup.string().required("Required"),
  ["email"]: yup.string().required("Required"),
  ["iban"]: yup.string().required("Required"),
  ["id"]: yup.string().required("Required"),
  ["kind"]: yup.string().required("Required"),
  ["mcc"]: yup.string().required("Required"),
  ["merchantName"]: yup.string().required("Required"),
  ["merchantNumber"]: yup.string().required("Required"),
  ["phoneNumber"]: yup.string().required("Required"),
  ["sortCode"]: yup.string().required("Required"),
  ["tidPrefix"]: yup.string().required("Required"),
  ["timeZone"]: yup.string().required("Required"),
});
