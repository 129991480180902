import styled from "styled-components";
import { Button, Collapse } from "antd";

const TransactionCollapse = styled(Collapse)`
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

const ApprovePayoutButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 24px;
  width: 300px;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Styled = {
  TransactionCollapse,
  ApprovePayoutButton,
  PageHeaderWrapper,
};
