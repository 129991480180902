import React, { useState } from "react";
import { Card, List } from "antd";
import { useDownload } from "../../../data-layer/attachment/hooks/useDownload";
import { RequestedDocumentsList } from "./RequestedDocumentsList";
import { DocumentRequestModal } from "./DocumentRequestModal";
import { useParams } from "react-router-dom";
import { IDocumentsResponse } from "../../../data-layer/documents/types/IDocuments";
import { ReactComponent as AttachedSvg } from "../../../assets/attached.svg";
import { Styled } from "./styles";

interface IDocuments {
  documents: IDocumentsData[];
  documentRequestRefetch: any;
  documentForProfileData: IDocumentsResponse[];
}

export interface IDocumentsData {
  PERSON_FIRST_NAME: string;
  PERSON_LAST_NAME: string;
  PROOF_ADDRESS_ATTACHMENT?: IAttachmentsResponse;
  PROOF_IDENTITY_FIRST_ATTACHMENT?: IAttachmentsResponse;
}

export const Documents: React.FC<IDocuments> = ({
  documents,
  documentRequestRefetch,
  documentForProfileData,
}) => {
  const { downloadFile, startDownloading } = useDownload();
  const { id } = useParams();

  const [
    visibleRequestAdditionalDocumentsModal,
    setVisibleRequestAdditionalDocumentsModal,
  ] = useState<boolean>(false);

  return (
    <>
      {documents.map((item) => {
        const {
          PERSON_FIRST_NAME,
          PERSON_LAST_NAME,
          PROOF_ADDRESS_ATTACHMENT,
          PROOF_IDENTITY_FIRST_ATTACHMENT,
        } = item;
        return (
          <Card title={`${PERSON_FIRST_NAME} ${PERSON_LAST_NAME}`}>
            <List>
              <List.Item>
                <span>Proof of address:</span>
                {PROOF_ADDRESS_ATTACHMENT ? (
                  <Styled.AttachedWrapper>
                    <AttachedSvg />
                    <a
                      onClick={() => downloadFile(PROOF_ADDRESS_ATTACHMENT.id)}
                    >
                      {PROOF_ADDRESS_ATTACHMENT.originalFileName}
                    </a>
                  </Styled.AttachedWrapper>
                ) : (
                  <Styled.AttachedWrapper>
                    Need to request
                  </Styled.AttachedWrapper>
                )}
              </List.Item>
              <List.Item>
                <span>Proof of identity:</span>
                {PROOF_IDENTITY_FIRST_ATTACHMENT ? (
                  <Styled.AttachedWrapper>
                    <AttachedSvg />
                    <a
                      onClick={() =>
                        downloadFile(PROOF_IDENTITY_FIRST_ATTACHMENT.id)
                      }
                    >
                      {PROOF_IDENTITY_FIRST_ATTACHMENT.originalFileName}
                    </a>
                  </Styled.AttachedWrapper>
                ) : (
                  <Styled.AttachedWrapper>
                    Need to request
                  </Styled.AttachedWrapper>
                )}
              </List.Item>
            </List>
          </Card>
        );
      })}
      <RequestedDocumentsList
        documents={documentForProfileData || []}
        setVisibleModal={setVisibleRequestAdditionalDocumentsModal}
      />
      <DocumentRequestModal
        profileId={id as string}
        visibleModal={visibleRequestAdditionalDocumentsModal}
        setVisibleModal={setVisibleRequestAdditionalDocumentsModal}
        documentRequestRefetch={documentRequestRefetch}
      />
    </>
  );
};
