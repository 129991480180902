import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Filters } from "./components/Filters";
import { AppliedFilters } from "../../features/appliedFilters";
import { PageHeader } from "antd";
import { CardsList } from "./components/CardsList";
import {
  Authorization,
  ROLES,
} from "../../providers/authorization/Authorization";
import { NotFound } from "../notFound";

export const CRM: React.FC = () => {
  return (
    <Authorization
      allowedRoles={[
        ROLES.PARTNER,
        ROLES.EMPLOYEE,
        ROLES.EMPLOYEE,
        ROLES.ADMIN,
        ROLES.COMPLIANCE_OFFICER,
      ]}
      forbiddenFallback={<NotFound />}
    >
      <Helmet>
        <title>CRM</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="CRM" />
      <Filters />
      <AppliedFilters />

      <CardsList />
    </Authorization>
  );
};
