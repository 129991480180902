import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";
import { IRoles } from "../types/IRoles";

// Define a service using a base URL and expected endpoints
export const rolesApi = createApi({
  reducerPath: "rolesApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getRoles: builder.query<IRoles[], string>({
      query: () => `/roles`,
    }),
  }),
});
export const { useGetRolesQuery } = rolesApi;
