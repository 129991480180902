import React, { useEffect, useState } from "react";
import { CompanyDescription } from "./CompanyDescription";
import { Form as AntForm, Form, Spin, Tabs } from "antd";
import { Styled } from "./styled";
import { useApplicationById } from "../../data-layer/application/hooks/useApplicationById";
import { useParams } from "react-router-dom";
import { BusinessOwners } from "./BusinessOwners";
import { NavHeader } from "./NavHeader/NavHeader";
import { ActionButtons } from "./ActionButtons/ActionButtons";
import { Helmet } from "react-helmet";
import { useDictionary } from "../../data-layer/dictionary/hooks";
import { useApplicationReview } from "../../data-layer/application/hooks/useApplicationReview";
import { BusinessDescription } from "./BusinessDescription";
import { PersonalDetails } from "./PersonalDetails/PersonalDetails";
import { useDictionaryMcc } from "../../data-layer/dictionary/hooks/useDictionaryMcc";
import { ApproveModal } from "./ApproveModal";
import { useValidateTabs } from "./useValidateTabs";
import { Documents } from "./Documents";
import { usePersonAttachments } from "../../data-layer/attachment/hooks/usePersonAttachments";
import { useGetDocumentForProfile } from "../../data-layer/documents/hooks/useGetDocumentForProfile";
import { RejectModal } from "./components/RejectModal";
import { PricingModels } from "./PricingModels";
import { IApplicationResponseData } from "data-layer/application/types/applicationTypes";

export const OnboardingDetails: React.FC = () => {
  const [form] = AntForm.useForm();
  const { id } = useParams();
  const { currentTabs, setCurrentTab, onSwitchToInvalidateTab } =
    useValidateTabs({ form });

  const { data, profileType, profileData, applicationRefetch, profileRefetch } =
    useApplicationById(id as string);
  const {
    dictionaryData,
    paymentHistory,
    expectedTurnover,
    averageTxValueMap,
  } = useDictionary();
  const { dictionaryMccData } = useDictionaryMcc();
  const personDocuments = usePersonAttachments(data);
  const { documentForProfileData } = useGetDocumentForProfile(id as string);

  const [isDisableForm, setIsDisableForm] = useState<boolean>(true);

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  const [isVisibleRejectModal, setIsVisibleRejectModal] =
    useState<boolean>(false);

  const [personalData, setPersonalData] = useState<IApplicationResponseData | null>(data?.data || null);

  const {
    applicationRejectRequest,
    applicationApproveRequest,
    applicationPersist,
    approveLoading,
    persistLoading,
    rejectLoading,
  } = useApplicationReview(
    setIsDisableForm,
    profileRefetch,
    applicationRefetch
  );

  const onEditClick = () => {
    setIsDisableForm(false);
  };

  const onCancelEditClick = () => {
    setIsDisableForm(true);
  };

  const onSaveClick = () => {
    setIsDisableForm(true);
    applicationPersist(form.getFieldsValue(), data.id, data.data);
  };

  const onRejectOnboarding = (reason: string) => {
    //todo reason в enum
    applicationRejectRequest({
      applicationId: data.id,
      approve: false,
      rejectReason: [reason],
    });
    setIsVisibleRejectModal(false);
  };

  const onOpenApproveModal = () => {
    onSwitchToInvalidateTab();
    setIsDisableForm(false);
    setIsVisibleModal(true);
  };

  const onRejectButtonClick = () => {
    setIsVisibleRejectModal(true);
  };

  const onRejectCancelButtonClick = () => {
    setIsVisibleRejectModal(false);
  };

  const onSubmitApprove = () => {
    form
      .validateFields()
      .then(() => {
        setIsVisibleModal(false);
        applicationApproveRequest(form.getFieldsValue(), data!.id, data!.data);
      })
      .catch((error) => {
        let isModalError = false;
        error.errorFields.forEach((value: any) => {
          if (
            value.name.includes("MCC") ||
            value.name.includes("TIME_ZONE") ||
            value.name.includes("CURRENCY")
          ) {
            isModalError = true;
          }
        });

        if (!isModalError) {
          setIsVisibleModal(false);
          setPersonalData(form.getFieldsValue());
        }
      });
  };

  useEffect(() => {
    if (data && data.data && !personalData) {
      setPersonalData(data.data);
    }
  }, [data, personalData]);

  return (
    <>
      <Helmet>
        <title>Onboarding</title>
      </Helmet>
      <Styled.HeaderWrapper>
        <NavHeader />
        {data && (
          <ActionButtons
            onEdit={onEditClick}
            onCancel={onCancelEditClick}
            isDisableForm={isDisableForm}
            onSave={onSaveClick}
            onRejectButtonClick={onRejectButtonClick}
            onApprove={onOpenApproveModal}
            persistLoading={persistLoading}
            profileStatus={profileData?.status}
            rejectLoading={rejectLoading}
            verifiedEmail={Boolean(profileData?.emailVerified)}
          />
        )}
      </Styled.HeaderWrapper>

      {personalData && dictionaryData ? (
        <>
          <Styled.FormWrapper>
            <Form
              initialValues={personalData}
              form={form}
              disabled={Boolean(
                isDisableForm &&
                  (currentTabs === "1" ||
                    currentTabs === "2" ||
                    currentTabs === "4")
              )}
              onChange={({ target }: { target: any }) => {
                const fieldName = target.id;
                let fieldValue = target.value;
                if (fieldName === "PM_IN_STORE_FEE_TX_PERCENTAGE" || fieldName === "PM_ONLINE_FEE_TX_PERCENTAGE") {
                  fieldValue = fieldValue.replace("%", "");
                }
                personalData[fieldName] = fieldValue;
                setPersonalData(personalData);
              }}
              scrollToFirstError
            >
              <Tabs
                activeKey={currentTabs}
                type="card"
                onChange={(key) => setCurrentTab(key)}
              >
                {profileType === "COMPANY" && (
                  <>
                    <Tabs.TabPane tab={"Company Description"} key="1">
                      <CompanyDescription
                        isDisableForm={isDisableForm}
                        dictionaryData={dictionaryData}
                        dictionaryMccData={dictionaryMccData}
                        paymentHistory={paymentHistory}
                        expectedTurnover={expectedTurnover}
                        averageTxValueMap={averageTxValueMap}
                        pmOnline={personalData?.PM_ONLINE}
                        pmOnlineGlobal={personalData?.PM_ONLINE_GLOBAL}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={"Business owners"}
                      key="2"
                      disabled={!personalData?.PERSONS}
                      forceRender={personalData?.PERSONS}
                    >
                      <BusinessOwners
                        PERSONS={personalData?.PERSONS}
                        isDisableForm={isDisableForm}
                      />
                    </Tabs.TabPane>
                  </>
                )}
                {profileType === "SOLE_TRADER" && (
                  <>
                    <Tabs.TabPane tab={"Business Description"} key="1">
                      <BusinessDescription
                        dictionaryData={dictionaryData}
                        paymentHistory={paymentHistory}
                        expectedTurnover={expectedTurnover}
                        dictionaryMccData={dictionaryMccData}
                        averageTxValueMap={averageTxValueMap}
                        isDisableForm={isDisableForm}
                        pmOnline={personalData?.PM_ONLINE}
                        pmOnlineGlobal={personalData?.PM_ONLINE_GLOBAL}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"Personal details"} key="2" forceRender>
                      <PersonalDetails
                        personName={
                          personalData?.PERSON_FIRST_NAME
                            ? `${personalData?.PERSON_FIRST_NAME} ${personalData?.PERSON_LAST_NAME}`
                            : "Unknown person"
                        }
                        isDisableForm={isDisableForm}
                      />
                    </Tabs.TabPane>
                  </>
                )}
                <Tabs.TabPane
                  tab={"Documents"}
                  key="3"
                  disabled={personDocuments.length === 0}
                >
                  <Documents
                    documents={personDocuments}
                    documentForProfileData={documentForProfileData.data}
                    documentRequestRefetch={documentForProfileData.refetch}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Pricing Models"} key="4">
                  <PricingModels form={form} data={personalData} onChangeData={setPersonalData} />
                </Tabs.TabPane>
              </Tabs>
              {isVisibleModal && (
                <ApproveModal
                  dictionaryData={dictionaryData}
                  dictionaryMccData={dictionaryMccData}
                  isVisibleModal={isVisibleModal}
                  setIsVisibleModal={setIsVisibleModal}
                  onSubmit={onSubmitApprove}
                  approveLoading={approveLoading}
                  form={form}
                  data={personalData}
                  onChangeData={setPersonalData}
                />
              )}
            </Form>
            {isVisibleRejectModal && (
              <RejectModal
                isVisibleModal={isVisibleRejectModal}
                onReject={onRejectOnboarding}
                rejectLoading={rejectLoading}
                onCancel={onRejectCancelButtonClick}
              />
            )}
          </Styled.FormWrapper>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            height: "90%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}
      {/*<Progress />;*/}
    </>
  );
};
