import { ILoginRequest } from "../types";
import { useLoginMutation } from "./api";
import { notification } from "antd";
import { notificationsConstants } from "shared/constants/notifications";
import { history } from "routes/AppRouter";
import { useLocation } from "react-router-dom";

export const useLogin = () => {
  const [login, { isError, isSuccess, isLoading, data, error }] =
    useLoginMutation();

  const { state } = useLocation();

  if (isLoading) {
  } else if (isError) {
    // todo important добавить эррор
    // showError("sign in error", error?.data?.error);
  } else if (isSuccess && data) {
    localStorage.setItem("authToken", data.token);
    localStorage.setItem("user", JSON.stringify(data));

    if (state?.from && state.from.includes("dashboard")) {
      history.push(state.from);
    } else history.push("/");
  }

  const onLogin = async (
    { password, email }: Omit<ILoginRequest, "recaptchaResponse">,
    recaptchaResponse: string
  ) => {
    const result = await login({ password, email, recaptchaResponse });
    notification.close(notificationsConstants.SESSION_EXPIRED_LOGOUT);
    if("error" in result) {
      notification.open({
        message: "Something went wrong",
        description: result.error?.data?.error,
        type: "error",
      });
    }
    return result
  };

  return {
    onLogin,
    isLoading,
    isSuccess,
  };
};
