import React, { useEffect, useState } from "react";
import { useTransaction } from "../../data-layer/transaction/hooks";
import { Collapse, Table } from "antd";
import { ITransactionResponse } from "../../data-layer/transaction/types/ITransaction";
import getSymbolFromCurrency from "currency-symbol-map";
import { IMerchantResponse } from "../../data-layer/merchant/types/merchantTypes";
import { Link } from "react-router-dom";
import { Styled } from "./styles";

interface ITransactionsList {
  payoutId: number;
}

export const TransactionsList: React.FC<ITransactionsList> = ({ payoutId }) => {
  const limit = "40";

  const [activeKeys, setActiveKeys] = useState<number[]>([]);

  const transactionsParams = new URLSearchParams(
    `payoutIds=${payoutId}&types=&limit=${limit}&offset=0`
  );

  const { transactionData, requestStatus, isLoading } = useTransaction(
    transactionsParams,
    limit
  );

  useEffect(() => {
    if (!activeKeys.includes(payoutId)) {
      setActiveKeys((oldActiveKeys) => [...oldActiveKeys, payoutId]);
    }
  }, [payoutId]);

  const onCollapseChange = (value: number[]) => {
    setActiveKeys([...value]);
  };

  return (
    <Styled.TransactionCollapse
      activeKey={activeKeys}
      onChange={onCollapseChange}
    >
      <Collapse.Panel header="Transactions" key={payoutId} forceRender>
        <Table
          loading={requestStatus === "pending" || isLoading}
          columns={columns}
          dataSource={transactionData}
          pagination={false}
          bordered
        />
      </Collapse.Panel>
    </Styled.TransactionCollapse>
  );
};

const columns = [
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    render: (info: string, record: ITransactionResponse) =>
      `${info} ${getSymbolFromCurrency(record.currency)}`,
  },
  {
    title: "DateTime",
    dataIndex: "dateTime",
    key: "dateTime",
  },
  {
    title: "Merchant",
    dataIndex: "merchant",
    key: "merchant",

    render: (info: IMerchantResponse) => (
      <Link to={`/dashboard/merchant-edit/${info.id}`}>
        {info.merchantName}
      </Link>
    ),
  },
  {
    title: "Employee name",
    dataIndex: "employeeName",
    key: "employeeName",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
];
