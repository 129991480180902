import { useGetDictionaryQuery } from "./api";
import { IDictionaryResponse } from "./types";

export const useDictionary = () => {
  //todo убрать аргумент
  const { data, error, isLoading, isSuccess } = useGetDictionaryQuery("");

  return {
    dictionaryData: data as IDictionaryResponse,
    error,
    isLoading,
    isSuccess,
  };
};
