import { IMerchantDetail } from "../../../scenarios/merchant/types";
import { useCreateMerchantMutation } from "../api/merchantApi";

export const useCreateMerchant = () => {
  const [createMerchantRequest] = useCreateMerchantMutation();

  const createMerchant = async (data: IMerchantDetail) => {
    return await createMerchantRequest(data);
  };

  return {
    createMerchant,
  };
};
