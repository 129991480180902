import React, { useEffect, useState } from "react";
import {Row, Col, Button, Select} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";


import { useChangeSearchParams } from "../../../shared/hooks/useChangeSearchParams";
import {RangePickerFilter} from "../../../features/filters/RangePickerFilter";
import {PartnersFindFilter} from "../../../components/PartnerFindFilter/PartnersFindFilter";
import {useDownloadReports} from "../api/useDownloadReports";


export const Filters: React.FC = () => {

  const [exportFormat, setExportFormat] = useState('XLS')

  const { downloadReport } = useDownloadReports()

  let [searchParams] = useSearchParams();

  const { changeSearchParams, setInitialParams, resetToInitialParams } =
      useChangeSearchParams();

  const initialFilters = {
    limit: "11",
    offset: "0",
    reportSummaryGroupBy: "MERCHANT"
  };

  const onExportClick = () => {
    downloadReport(`${searchParams.toString()}&format=${exportFormat}`, exportFormat)
  }

  const dateFromFilter = searchParams.get("from");
  const dateToFilter = searchParams.get("to");
  const groupByFilter = searchParams.get("reportSummaryGroupBy");


  const rangeFilter = [
    dateFromFilter && dayjs(dateFromFilter),
    dateToFilter && dayjs(dateToFilter),
  ];

  useEffect(() => {
    setInitialParams(initialFilters);
  }, []);


  const handleDateChange = (momentRange: any) => {
    if (momentRange === null) {
      changeSearchParams({
        from: "",
        to: "",
      });
    }
    changeSearchParams({
      from: momentRange[0].toDate().toISOString(),
      to: momentRange[1].toDate().toISOString(),
    });
  };

  const handleGroupByFilter = (value: string) => {
    changeSearchParams({ reportSummaryGroupBy: value });
  };

  const { Option } = Select;



  return (
      <>
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Row>
              <Col xxl={8} xl={10} lg={18}>
                <Row gutter={15}>
                  <Col span={12}>
                    {/*@ts-ignore*/}
                    <RangePickerFilter  format={"DD MMM"}
                                  onChange={handleDateChange}
                                  value={rangeFilter} />
                  </Col>
                  <Col span={12}>
                    <PartnersFindFilter filterName={"partnerId"}/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col xxl={8} xl={10} lg={18}>
                <Row gutter={15}>
                  <Col span={12}>
                    <Select
                        placeholder="Group by"
                        style={{ width: "100%" }}
                        onChange={(value) => handleGroupByFilter(value)}
                        defaultValue={groupByFilter}
                        value={groupByFilter}
                    >
                      <Option value={"EMPLOYEE"}>Employee</Option>
                      <Option value={"MERCHANT"}>Merchant</Option>
                      <Option value={"PARTNER"}>Partner</Option>
                    </Select>
                  </Col>
                  <Col span={12}>
                    <Select
                        placeholder="Export to"
                        style={{ width: "100%" }}
                        onChange={(value) => setExportFormat(value)}
                        defaultValue={exportFormat}
                        value={exportFormat}
                    >
                      {/*<Option value={"PDF"}>.PDF</Option>*/}
                      <Option value={"XLS"}>.XLS</Option>
                      <Option value={"CSV"}>.CSV</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={2}>
                <Button
                    icon={<FilterOutlined />}
                    style={{ marginRight: 15 }}
                    onClick={() => resetToInitialParams(initialFilters)}
                >
                  Reset
                </Button>
              </Col>
              <Col span={2}>
                <Button
                    type={"primary"}
                    style={{ marginRight: 15 }}
                    onClick={onExportClick}
                >
                  Export
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
  );
};
