import {
  Form,
  Row,
  Col,
  Select,
  Divider,
} from "antd";
import { Currency, IPricingModels } from "./types";
import { isNotValidPricingModelCurrency } from "./utils";
import { InStorePricingModel } from "./inStoreModel";
import { OnlinePricingModel } from "./onlineModel";
import { OnlineGlobalPricingModel } from "./onlineGlobalModel";

export const PricingModels: React.FC<IPricingModels> = ({ form, data, onChangeData }) => {
  const { Option } = Select;

  return (
    <>
      <Row gutter={[110, 0]}>
        <Col span={12}>
          <Divider orientation="center" plain>
            General
          </Divider>
          <Form.Item
            label="Select currency"
            labelCol={{ span: 24 }}
            name={"PM_CURRENCY"}
            rules={[
              {
                required: true,
                message: "Please select currency",
              },
              ({ getFieldValue }) => ({
                validator() {
                  const selectedCurrency = getFieldValue("PM_CURRENCY");
                  if (isNotValidPricingModelCurrency(selectedCurrency)) {
                    return Promise.reject(new Error("Invalid currency"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select>
              <Option key={Currency.GBP} value={Currency.GBP}>
                UK Pounds (GBP)
              </Option>
              <Option key={Currency.EUR} value={Currency.EUR}>
                Euro (EUR)
              </Option>
              <Option key={Currency.USD} value={Currency.USD}>
                US Dollars (USD)
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <OnlineGlobalPricingModel form={form} data={data} onChangeData={onChangeData} />
        </Col>
      </Row>
      <Row gutter={[110, 0]}>
        <Col span={12}>
          <InStorePricingModel form={form} data={data} onChangeData={onChangeData} />
        </Col>
        <Col span={12}>
          <OnlinePricingModel form={form} data={data} onChangeData={onChangeData} />
        </Col>
      </Row>
    </>
  );
};
