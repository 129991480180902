import { useState, useEffect } from "react";
import { Fee, FeeType, IPricingModels } from "./types";
import {
  BLENDED_FIX_FEE,
  BLENDED_PERCENTAGE,
  DETAILED_FIX_FEE,
  DETAILED_PERCENTAGE,
} from "./constants";
import { Divider, Form, Input, InputNumber, Select } from "antd";
import { parseStringToNumber } from "../../../utils/number";

export const InStorePricingModel: React.FC<IPricingModels> = ({
  form,
  data,
  onChangeData
}) => {
  const [inStoreFeeType, setInStoreFeeType] = useState<FeeType>(
    data.PM_IN_STORE_FEE_TYPE || Fee.BLENDED_FEE
  );

  const handleChangeInStoreFeeType = (value: FeeType) => {
    if (
      value === Fee.BLENDED_FEE &&
      data.PM_IN_STORE_FEE_TYPE === Fee.BLENDED_FEE
    ) {
      form.setFieldValue(
        "PM_IN_STORE_FEE_TX_PERCENTAGE",
        data.PM_IN_STORE_FEE_TX_PERCENTAGE
      );
      form.setFieldValue("PM_IN_STORE_FEE_TX_FIX", BLENDED_FIX_FEE.toFixed(2));
    } else if (
      value === Fee.BLENDED_FEE &&
      data.PM_IN_STORE_FEE_TYPE !== Fee.BLENDED_FEE
    ) {
      form.setFieldValue("PM_IN_STORE_FEE_TX_PERCENTAGE", BLENDED_PERCENTAGE);
      form.setFieldValue("PM_IN_STORE_FEE_TX_FIX", BLENDED_FIX_FEE.toFixed(2));
    } else if (
      value === Fee.DETAILED_FEE &&
      data.PM_IN_STORE_FEE_TYPE !== Fee.DETAILED_FEE
    ) {
      form.setFieldValue("PM_IN_STORE_FEE_TX_PERCENTAGE", DETAILED_PERCENTAGE);
      form.setFieldValue("PM_IN_STORE_FEE_TX_FIX", DETAILED_FIX_FEE.toFixed(2));
    } else if (
      value === Fee.DETAILED_FEE &&
      data.PM_IN_STORE_FEE_TYPE === Fee.DETAILED_FEE
    ) {
      form.setFieldValue(
        "PM_IN_STORE_FEE_TX_PERCENTAGE",
        data.PM_IN_STORE_FEE_TX_PERCENTAGE
      );
      form.setFieldValue("PM_IN_STORE_FEE_TX_FIX", data.PM_IN_STORE_FEE_TX_FIX);
    }

    form.setFieldValue("PM_IN_STORE_FEE_TYPE", value);
    onChangeData(form.getFieldsValue());
  };

  useEffect(() => {
    setInStoreFeeType(data.PM_IN_STORE_FEE_TYPE);
  }, [data]);

  const { Option } = Select;
  return (
    <>
      <Divider orientation="center" plain>
        InStore UK+EU / Visa & MasterCard
      </Divider>
      <Form.Item
        label="Type"
        labelCol={{ span: 24 }}
        name={"PM_IN_STORE_FEE_TYPE"}
        rules={[
          {
            required: true,
            message: "Please select In Store Fee Type",
          },
        ]}
      >
        <Select
          onChange={handleChangeInStoreFeeType}
          placeholder="Please select..."
        >
          <Option key={Fee.BLENDED_FEE} value={Fee.BLENDED_FEE}>
            Blended rate
          </Option>
          <Option key={Fee.DETAILED_FEE} value={Fee.DETAILED_FEE}>
            IC++
          </Option>
          <Option key={Fee.DISABLED} value={Fee.DISABLED}>
            Disabled
          </Option>
        </Select>
      </Form.Item>
      {inStoreFeeType !== Fee.DISABLED && (
        <>
          <Form.Item
            label="Percent fee"
            labelCol={{ span: 24 }}
            name={"PM_IN_STORE_FEE_TX_PERCENTAGE"}
            rules={[
              {
                required: true,
                message: "Please input percent fee",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
                backgroundColor: "#ffffff",
                color: "#000000",
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
              step={0.01}
              max={5}
              min={1.2}
              formatter={(value) => `${parseStringToNumber(value).toFixed(2)}%`}
              // @ts-ignore
              parser={(value) => value!.replace("%", "")}
            />
          </Form.Item>
          {inStoreFeeType === Fee.DETAILED_FEE && (
            <Form.Item
              label="Auth fee"
              labelCol={{ span: 24 }}
              name={"PM_IN_STORE_FEE_TX_FIX"}
            >
              <Input disabled />
            </Form.Item>
          )}
        </>
      )}
    </>
  );
};
