import React from "react";
import { Styled } from "../style";
import { Col, Row } from "antd";
import {Popover} from "../../../components/Popover";

interface ICard {
  partnerName: string;
  id: number | string;
  email: string;
  country: string;
}

export const Card: React.FC<ICard> = ({ partnerName, id, email, country }) => {
  return (
    <Styled.CardContainer>
      <Row>
        <Col span={12}>
          <Styled.FlexWrapper>
            <Styled.Label>Partner name:</Styled.Label>
            <Styled.Text>{partnerName || "No data"}</Styled.Text>
          </Styled.FlexWrapper>
        </Col>
        <Col span={12}>
          <Styled.FlexWrapper>
            <Styled.Label>Client ID:</Styled.Label>
            <Styled.Text>{id  || "No data"}</Styled.Text>
          </Styled.FlexWrapper>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Styled.FlexWrapper>
            <Styled.Label>Email:</Styled.Label>
            <CutLabel text={email}/>
          </Styled.FlexWrapper>
        </Col>
        <Col span={12}>
          <Styled.FlexWrapper>
            <Styled.Label>Country</Styled.Label>
            <Styled.Text>{country ? country : "No data"}</Styled.Text>
          </Styled.FlexWrapper>
        </Col>
      </Row>
    </Styled.CardContainer>
  );
};

const CutLabel: React.FC = ({text} : { text: string}) => {
  if(text.length > 18) {
    return (<Popover content={text} trigger="hover">
      <Styled.Text>{text.slice(0, 16) + "..."}</Styled.Text>
    </Popover>)
  }
  return ( <Styled.Text>{text}</Styled.Text>)
}
