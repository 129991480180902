import { useDownloadSettlementQuery } from "../api/payoutApi";
import { downloadApi } from "../api/downloadApi";
import { notification } from "antd";

export const useDownload = () => {
    const token = localStorage.getItem("authToken");

    const startDownloading = (id: number, filename: string) => {
        downloadWaitingNotify();
        downloadApi(id, token)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                downloadCompleteNotify();
            })
            .catch(console.error);
    };

    return {
        startDownloading,
    };
};

export const useDownloadSettlement = (id: number) => {
    const { data, isLoading } = useDownloadSettlementQuery(id);

    return {
        data,
        isLoading,
    };
};

const waitingNotifyKey = "waiting";

const downloadWaitingNotify = () => {
    notification.open({
        message: "Download is about to start",
        description: "Please wait a bit",
        type: "info",
        duration: 0,
        key: waitingNotifyKey,
    });
};

const downloadCompleteNotify = () => {
    notification.close(waitingNotifyKey);
    notification.open({
        message: "Success",
        description: "Download complete",
        type: "success",
    });
};
