import { IMerchantUpdateDetail } from "../../../scenarios/merchant/types";
import { useUpdateMerchantMutation } from "../api/merchantApi";

export const useUpdateMerchant = () => {
  const [updateMerchantRequest] = useUpdateMerchantMutation();

  const updateMerchant = async (data: IMerchantUpdateDetail) => {
    return await updateMerchantRequest(data);
  };

  return {
    updateMerchant,
  };
};
