import React, { useEffect, useState } from "react";
import { AutoComplete, Col, Input, Row } from "antd";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";

export const Filters: React.FC = () => {
  const { Search } = Input;

  let [searchParams] = useSearchParams();

  const { changeSearchParams, setInitialParams } = useChangeSearchParams();

  const searchFilter = searchParams.get("name");

  const initialFilters = {
    name: "",
    limit: "11",
    offset: "0",
  };

  const [search, setSearch] = useState(searchFilter || undefined);

  const handleSearchFilter = (search: string) => {
    changeSearchParams({ name: search });
  };

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [searchParams]);

  return (
    <>
      <Row>
        <Col span={8}>
          <Search
            placeholder={"Search by name"}
            onSearch={handleSearchFilter}
            enterButton={false}
            defaultValue={searchFilter || ""}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>
    </>
  );
};
