import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { Styled } from "../style";
import { useGetReports } from "../api/useGetReports";
import { useChangeSearchParams } from "shared/hooks/useChangeSearchParams";
import {
  SearchTableFilter,
  SelectTableFilter,
} from "../../../features/filters";

import { onboardingStatusMap } from "../../../shared/constants/statuses";
import {addTrailingZeros} from "../../../utils/addTrailingZeros";


export const Table: React.FC = () => {
  let [searchParams] = useSearchParams();

  const limitFilter = searchParams.get("limit") || "";

  const { data, error, isLoading, status, dataWithoutLastItem } =
    useGetReports(searchParams.toString(), limitFilter);

  const { changeSearchParams } = useChangeSearchParams();


  const isPagination = parseInt(limitFilter) === data.length;


  const handleLoadMoreReports = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };


  const columns = [
    {
      title: "Merchant name",
      dataIndex: "merchantName",
      key: "merchantName",
      // sorter: true,
      // width: "10%",
    },
    {
      title: "MID",
      dataIndex: "mid",
      key: "mid",
      // sorter: true,
      // width: "10%",
    },
    {
      title: "Number of devices",
      dataIndex: "numberOfDevices",
      key: "numberOfDevices",
      // sorter: true,
      // width: "10%",
    },
    {
      title: "Number of operations",
      dataIndex: "numberOfOperations",
      key: "numberOfOperations",
      // sorter: true,
      // width: "10%",
    },
    {
      title: "Turnover",
      dataIndex: "turnover",
      key: "turnover",
      render: (turnover: string) => turnover != 0 ? addTrailingZeros(turnover) : turnover
      // sorter: true,
      // width: "10%",
    },

  ];


  return (
    <>
      {/*@ts-ignore*/}
      <Styled.Table
        loading={status === "pending" || isLoading}
        columns={columns}
        dataSource={data}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
        onRow={(record, rowIndex) => {
          return {
            record,
          };
        }}
      />
      {!isLoading && data.length > 0 && isPagination && (
        <Button
          onClick={handleLoadMoreReports}
          loading={status === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more reports
        </Button>
      )}
    </>
  );
};

