import React, { useState } from "react";
import { useTransaction } from "../../data-layer/transaction/hooks";
import { Table } from "./Table";
import { Filters } from "./Filters";
import { useSearchParams } from "react-router-dom";
import { PageHeader } from "antd";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { TransactionItem } from "./TransactionItem";
import { ITransactionResponse } from "../../data-layer/transaction/types/ITransaction";
import { Helmet } from "react-helmet";
import { AppliedFilterInput } from "../../components/AppliedFiltersInput";
import { TotalTurnover } from "./TotalTurnover";
import { useGetTransactionsAggregate } from "../../data-layer/transaction/hooks/useGetTransactionAggregate";
import {
  Authorization,
  ROLES,
} from "../../providers/authorization/Authorization";
import { NotFound } from "../../pages/notFound";

export const TransactionSc: React.FC = () => {
  let [searchParams] = useSearchParams();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [transactionItemInfo, setTransactionItemInfo] = useState<
    ITransactionResponse | {}
  >({});
  const limitFilter = searchParams.get("limit") || "";

  const { transactionData, isLoading, requestStatus, dataWithoutLastItem } =
    useTransaction(searchParams, limitFilter);

  const { transactionsAggregateData } = useGetTransactionsAggregate(
    searchParams,
    limitFilter
  );

  const { changeSearchParams } = useChangeSearchParams();

  const handleLoadMoreTransactions = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };

  const onDrawerClose = () => {
    setVisibleDrawer(false);
  };

  const onDrawerOpen = (transactionInfo: ITransactionResponse) => {
    setTransactionItemInfo(transactionInfo);
    setVisibleDrawer(true);
  };

  const isAllowPagination = parseInt(limitFilter) === transactionData.length;

  return (
    <Authorization
      allowedRoles={[
        ROLES.PARTNER,
        ROLES.EMPLOYEE,
        ROLES.EMPLOYEE,
        ROLES.ADMIN,
        ROLES.COMPLIANCE_OFFICER,
      ]}
      forbiddenFallback={<NotFound />}
    >
      <Helmet>
        <title>Transactions</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="Transactions" />
      <div style={{ position: "relative" }}>
        <Filters />
        <TotalTurnover
          transactionsAggregateData={transactionsAggregateData[0]}
        />
      </div>

      <AppliedFilterInput />
      <Table
        tableData={dataWithoutLastItem}
        isLoading={isLoading}
        requestStatus={requestStatus}
        handleLoadMoreTransactions={handleLoadMoreTransactions}
        onRowClick={onDrawerOpen}
        pagination={isAllowPagination}
      />
      <TransactionItem
        visible={visibleDrawer}
        onClose={onDrawerClose}
        data={transactionItemInfo}
      />
    </Authorization>
  );
};
