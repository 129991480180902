import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../shared/hooks/useChangeSearchParams";

interface IMultiselectTableFilter {
  placeholder: string;
  filterName: string;
  optionItems: any[];
  valueName: string;
  name: string;
  onSelect?: () => void;
}

export const MultiselectTableFilter: React.FC<IMultiselectTableFilter> = ({
  placeholder,
  filterName,
  optionItems,
  valueName,
  name,
  onSelect,
}) => {
  const { changeSearchParams } = useChangeSearchParams();
  let [searchParams] = useSearchParams();

  const handleSelectFilter = (selectValue: string) => {
    changeSearchParams({ [filterName]: selectValue });
    onSelect && onSelect();
  };

  const searchFilter = searchParams.getAll(filterName);

  // useEffect(() => {
  //   setValue(searchFilter as string);
  // }, [searchFilter]);

  const { Option } = Select;

  return (
    <Select
      onKeyDown={(e) => e.stopPropagation()}
      placeholder={placeholder}
      style={{ width: "100%" }}
      onChange={handleSelectFilter}
      defaultValue={searchFilter}
      value={searchFilter}
      mode={"multiple"}
    >
      {optionItems.map((item) => {
        return <Option value={item[valueName]}>{item[name]}</Option>;
      })}
    </Select>
  );
};
