import { useConnectMerchantsToPartnerMutation } from "../api/partnerApi";
import { notification } from "antd";

export const useConnectMerchantsToPartner = () => {
  const [connectMerchantsPost] = useConnectMerchantsToPartnerMutation();

  const connectMerchants = (merchantList: any, partnerId: string) => {
    const validMerchants = validateMerchantList(merchantList);
    const validRequestData = {
      merchantIds: validMerchants,
      partnerId: parseInt(partnerId),
    };
    connectMerchantsToPartner(validRequestData);
  };

  const connectMerchantsToPartner = (params: any) => {
    connectMerchantsPost(params).then((info) => {
      if ("data" in info) {
        notification.open({
          message: "Connected successfully",
          type: "success",
        });
      } else if ("error" in info) {
        notification.open({
          message: "Something went wrong",
          description: info.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    connectMerchants,
    connectMerchantsToPartner,
  };
};

const validateMerchantList = (merchantList: any) => {
  const result = [];
  for (const merch in merchantList) {
    if (merchantList[merch] !== undefined) {
      result.push(parseInt(merch));
    }
  }
  return result;
};
