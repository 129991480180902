import React from "react";
import {
  IOverviewPartnersResponse,
  IOverviewMerchantsResponse,
} from "../../data-layer/adminController/types/IAdminController";
import { Styled } from "./styles";
import { Spin } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import { addTrailingZeros } from "../../utils/addTrailingZeros";

interface IInfoMerchantsProps {
  overviewMerchantsData: IOverviewMerchantsResponse;
  isLoadingMerchants: boolean;
}

interface IInfoPartnersProps {
  overviewPartnersData: IOverviewPartnersResponse;
  isLoadingPartners: boolean;
}

interface IInfoItemsProps extends IInfoMerchantsProps, IInfoPartnersProps {}

interface IInfoItem {
  text: string;
  count: string | number;
}

export const InfoItems: React.FC<IInfoItemsProps> = ({
  overviewMerchantsData,
  overviewPartnersData,
  isLoadingMerchants,
  isLoadingPartners,
}) => {
  return (
    <>
      <InfoPartners
        overviewPartnersData={overviewPartnersData}
        isLoadingPartners={isLoadingPartners}
      />
      <InfoMerchants
        overviewMerchantsData={overviewMerchantsData}
        isLoadingMerchants={isLoadingMerchants}
      />
    </>
  );
};

const InfoMerchants: React.FC<IInfoMerchantsProps> = ({
  isLoadingMerchants,
  overviewMerchantsData,
}) => {
  if (isLoadingMerchants) return <Spin />;

  const {
    invitedMerchants,
    applicationsApproved,
    applicationsSubmitted,
    firstTransactions,
    appFirstLogins,
    signUpsCompleted,
    firstPayoutDone,
  } = overviewMerchantsData;

  return (
    <>
      <Styled.InfoTitle>Merchants statistics</Styled.InfoTitle>
      <Styled.InfoItemsWrapper>
        <InfoItem text={"invited merchants"} count={invitedMerchants} />
        <InfoItem text={"sign-ups completed"} count={signUpsCompleted} />
        <InfoItem
          text={"applications submitted"}
          count={applicationsSubmitted}
        />
        <InfoItem text={"applications approved"} count={applicationsApproved} />
      </Styled.InfoItemsWrapper>
      <Styled.InfoItemsWrapper>
        <InfoItem text={"immediate setup completed"} count={appFirstLogins} />
        <InfoItem
          text={"first transaction committed"}
          count={firstTransactions}
        />
        <InfoItem text={"first payout transaction"} count={firstPayoutDone} />
      </Styled.InfoItemsWrapper>
    </>
  );
};

const InfoPartners: React.FC<IInfoPartnersProps> = ({
  isLoadingPartners,
  overviewPartnersData,
}) => {
  if (isLoadingPartners) return <Spin />;

  const { activeMerchants, merchants, totalTurnover } = overviewPartnersData;

  return (
    <>
      <Styled.PartnerInfoWrapper>
        <Styled.InfoTitle>Partners statistics</Styled.InfoTitle>
        <Styled.InfoItemsWrapper>
          <InfoItem text={"total merchants"} count={merchants} />
          <InfoItem text={"active merchants"} count={activeMerchants} />
          <InfoItem
            text={"total turnover"}
            count={
              totalTurnover !== 0
                ? `${getSymbolFromCurrency("GBP")}${addTrailingZeros(
                    totalTurnover
                  )}`
                : totalTurnover
            }
          />
        </Styled.InfoItemsWrapper>
      </Styled.PartnerInfoWrapper>
    </>
  );
};

const InfoItem: React.FC<IInfoItem> = ({ text, count }) => {
  return (
    <Styled.InfoBlockContainer>
      <Styled.InfoCount>{count}</Styled.InfoCount>
      <Styled.InfoText>{text}</Styled.InfoText>
    </Styled.InfoBlockContainer>
  );
};
