import styled from "styled-components";
import { Button } from "antd";

const LogoutButton = styled(Button)`
  margin-bottom: 50px;
`;

export const Styled = {
  LogoutButton,
};
