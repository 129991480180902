import { useGetEmployeeAdminQuery } from "../api/employeeApi";

export const useEmployee = (searchParams: string, limitFilter: string) => {
  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess } =
    useGetEmployeeAdminQuery(`${searchParams}`, {
      skip: skipRequest,
    });

  const employeeData = data?.length ? data : [];

  let dataWithoutLastItem = [...employeeData];
  limitFilter && dataWithoutLastItem.splice(parseInt(limitFilter) - 1, 1);

  return {
    employeeData,
    dataWithoutLastItem,
    data,
    error,
    isLoading,
    isSuccess,
    requestStatus: status,
  };
};
