import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { ColumnsType } from "antd/es/table";

export const useHandleSortColumn = () => {
  const { changeSearchParams } = useChangeSearchParams();

  const setDefaultColumnsSort = (
    sortField: string,
    sortDirection: string,
    columns: ColumnsType
  ) => {
    return columns.map((item) => {
      if (item.key === sortField) {
        return {
          ...item,
          defaultSortOrder: sortDirection === "ASC" ? "ascend" : "descend",
        };
      }
      return item;
    });
  };

  const handleSortColumn = (sortColumn: string, sortDirection: string) => {
    const direction = sortDirection === "ascend" ? "ASC" : "DESC";
    changeSearchParams({
      ["orderBy[0].field"]: sortColumn,
      ["orderBy[0].direction"]: direction,
    });
    if (sortDirection === undefined) {
      changeSearchParams({
        ["orderBy[0].field"]: "",
        ["orderBy[0].direction"]: "",
      });
    }
  };

  return {
    handleSortColumn,
    setDefaultColumnsSort,
  };
};
