import React from "react";
import { Drawer } from "antd";
import { ITransactionResponse } from "../../data-layer/transaction/types/ITransaction";
import { Styled } from "./style";
import getSymbolFromCurrency from "currency-symbol-map";
import { GoogleMapsStatic } from "../../components/GoogleMapsStatic/GoogleMapsStatic";

interface ITransactionItem {
  visible: boolean;
  onClose: () => void;
  data: ITransactionResponse;
}

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
}

export const TransactionItem: React.FC<ITransactionItem> = ({
  visible,
  onClose,
  data,
}) => {
  const { amount, id, currency, dateTime, employeeName, type } = data;

  const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
    <Styled.DescriptionContainer>
      <span style={{ marginRight: 10 }}>{title}:</span>
      <Styled.DescriptionValue>{content}</Styled.DescriptionValue>
    </Styled.DescriptionContainer>
  );

  return (
    <Drawer
      title="Transaction"
      placement={"right"}
      onClose={onClose}
      visible={visible}
      width={440}
    >
      <DescriptionItem
        title="Amount"
        content={`${amount} ${getSymbolFromCurrency(currency)}`}
      />
      <DescriptionItem title="Id" content={id} />
      <DescriptionItem title="DateTime" content={dateTime} />
      <DescriptionItem title="Employee" content={employeeName} />
      <DescriptionItem title="Type" content={type} />
      <GoogleMapsStatic
        height={300}
        width={400}
        lat={"19.641152"}
        long={"-98.992844"}
      />
    </Drawer>
  );
};
