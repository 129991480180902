import styled from "styled-components";

const DescriptionContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

const DescriptionValue = styled.span`
  color: rgba(0, 0, 0, 0.65);
`;

const TotalTurnoverTitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #80868b;
`;

const TotalTurnoverContainer = styled.div`
  display: flex;
  gap: 50px;
  padding: 5px 16px;
  background: #fff1f0;
`;

const TotalTurnoverMainTitleWrapper = styled(TotalTurnoverTitle)`
  margin-bottom: 12px;
`;

const TotalTurnoverInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalTurnoverInfoValue = styled.p`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;
`;

const TotalTurnoverWrapper = styled.div`
  position: absolute;
  width: 65%;
  top: -35px;
  left: 35%;
  @media (max-width: 1600px) {
    left: 43%;
    width: 58%;
  }
  @media (max-width: 1200px) {
    left: 60%;
    width: 45%;
  }
  @media (max-width: 1000px) {
    position: initial;
    width: 100%;
  }
`;

export const Styled = {
  DescriptionContainer,
  DescriptionValue,
  TotalTurnoverTitle,
  TotalTurnoverContainer,
  TotalTurnoverMainTitleWrapper,
  TotalTurnoverInfoBlock,
  TotalTurnoverInfoValue,
  TotalTurnoverWrapper,
};
