import { combineReducers } from "@reduxjs/toolkit";
import { profileApi } from "../profile/api/profileApi";
import { authApi } from "../auth/api/authApi";
import authReducer from "../auth/slice";
import { dictionaryApi } from "../dictionary/api/dictionaryApi";
import { applicationApi } from "../application/api/applicationApi";
import { documentRequestApi } from "../documents/api/documentRequestApi";
import { attachmentApi } from "../attachment/api/attachmentApi";
import { transactionApi } from "../transaction/api/transactionApi";
import { merchantApi } from "../merchant/api/merchantApi";
import { payoutApi } from "../payout/api/payoutApi";
import { employeeApi } from "../employee/api/employeeApi";
import { partnerApi } from "../partner/api/partnerApi";
import { systemApi } from "../system/api/systemApi";
import { userApi } from "../user/api/userApi";
import { rolesApi } from "../roles/api/rolesApi";
import { terminalApi } from "../terminals/api/terminalApi";
import { statementIndexesApi } from "../statmentIndexes/api/statementIndexesApi";
import { i18settingsApi } from "../i18settings/api/i18settingsApi";
import { adminControllerApi } from "../adminController/api/adminControllerApi";
import { crmApi } from "../../pages/crm/api/api";
import { sekumpulApi } from "../sekumpulUpload/api/sekumpulUpload";
import {reportApi} from "../../pages/reports/api/api";

export const rootReducer = combineReducers({
  auth: authReducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [dictionaryApi.reducerPath]: dictionaryApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [attachmentApi.reducerPath]: attachmentApi.reducer,
  [documentRequestApi.reducerPath]: documentRequestApi.reducer,
  [merchantApi.reducerPath]: merchantApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [payoutApi.reducerPath]: payoutApi.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [partnerApi.reducerPath]: partnerApi.reducer,
  [systemApi.reducerPath]: systemApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [terminalApi.reducerPath]: terminalApi.reducer,
  [statementIndexesApi.reducerPath]: statementIndexesApi.reducer,
  [i18settingsApi.reducerPath]: i18settingsApi.reducer,
  [adminControllerApi.reducerPath]: adminControllerApi.reducer,
  [crmApi.reducerPath]: crmApi.reducer,
  [sekumpulApi.reducerPath]: sekumpulApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
});
