import { useRetryExportStatementMutation } from "../api/payoutApi";
import { notification } from "antd";

export const useRetryExportStatement = (refetch: () => void) => {
  const [retryExportStatement] = useRetryExportStatementMutation();

  const onRetryExportStatement = (id: number, onClose: () => void) => {
    retryExportStatement(id).then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Payout retry export statement",
          type: "success",
        });
        onClose();
        refetch();
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    onRetryExportStatement,
  };
};
