import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";
import { IChangeRoleParams, IUserResponse } from "../types/IUser";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getUsers: builder.query<IUserResponse[], string>({
      query: (params) => `/users?${params}`,
    }),
    updateUser: builder.mutation<IUserResponse, IUserResponse>({
      query: (params) => ({
        url: `/users/admin`,
        method: "PUT",
        body: params,
      }),
    }),
    addUserRole: builder.mutation<IUserResponse[], IChangeRoleParams>({
      query: ({ roleId, userId }) => ({
        url: `/users/${userId}/role/${roleId}`,
        method: "PUT",
      }),
    }),
    deleteUserRole: builder.mutation<IUserResponse[], IChangeRoleParams>({
      query: ({ roleId, userId }) => ({
        url: `/users/${userId}/role/${roleId}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useGetUsersQuery,
  useAddUserRoleMutation,
  useDeleteUserRoleMutation,
  useUpdateUserMutation,
} = userApi;
