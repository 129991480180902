import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, PageHeader } from "antd";
import { Filters } from "./Filters";
import { Table } from "./TableV2";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { useMerchants } from "../../data-layer/merchant/hooks/useMerchants";
import { ConnectPartnerModal } from "../MerchantEdit/ConnectPartnerModal";
import { usePartners } from "../../data-layer/partner/hooks/usePartners";
import { useConnectMerchantsToPartner } from "../../data-layer/partner/hooks/useConnectMerchantsToPartner";
import { useUpdateMerchantsData } from "../../data-layer/merchant/hooks/useUpdateMerchantsData";
import {
  Authorization,
  ROLES,
} from "../../providers/authorization/Authorization";
import { NotFound } from "../../pages/notFound";
import { history } from "../../routes/AppRouter";

const Merchants: React.FC = () => {
  const [visibleConnectPartnerModal, setVisibleConnectPartnerModal] =
    useState(false);
  const [currentMerchantId, setCurrentMerchantId] = useState(0);
  const { searchParams, changeSearchParams } = useChangeSearchParams();

  const { connectMerchantsToPartner } = useConnectMerchantsToPartner();
  const { onBlockMerchant } = useUpdateMerchantsData();

  const limitFilter = searchParams.get("limit") || "";

  const { data, isLoading, status } = useMerchants(searchParams.toString());

  const isAllowPagination = parseInt(limitFilter) === data.length;

  const partnersData = usePartners("name=&limit=1000&offset=0", []);

  const handleLoadMoreMerchants = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };

  const onSubmitConnectPartner = (data: any, merchantId: number) => {
    connectMerchantsToPartner({
      merchantIds: [merchantId],
      partnerId: data.partner,
    });
  };

  return (
    <Authorization
      allowedRoles={[
        ROLES.PARTNER,
        ROLES.EMPLOYEE,
        ROLES.EMPLOYEE,
        ROLES.ADMIN,
        ROLES.COMPLIANCE_OFFICER,
      ]}
      forbiddenFallback={<NotFound />}
    >
      <Helmet>
        <title>Merchants</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="Merchants" />

      <Filters />
      <Button
        type="primary"
        onClick={() => history.push("/dashboard/merchant-create")}
        style={{ marginTop: 15 }}
      >
        Create
      </Button>
      <ConnectPartnerModal
        visible={visibleConnectPartnerModal}
        setVisible={setVisibleConnectPartnerModal}
        onSubmit={(data) => onSubmitConnectPartner(data, currentMerchantId)}
        partnerList={partnersData.data}
      />
      <Table
        isLoading={isLoading}
        tableData={data}
        pagination={isAllowPagination}
        handleLoadMoreMerchants={handleLoadMoreMerchants}
        requestStatus={status}
        onOpenConnectModal={() => setVisibleConnectPartnerModal(true)}
        setCurrentMerchantId={setCurrentMerchantId}
        onBlockMerchant={onBlockMerchant}
      />
    </Authorization>
  );
};

export default Merchants;
