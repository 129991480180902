import React from "react";
import { Form, Input, Select } from "antd";
import { FormCard } from "../../../../components/FormCard/FormCard";
import { ICountries } from "../../../../data-layer/dictionary/types/IDictionary";

interface IRegistrationAddress {
  countries: ICountries[];
}

export const RegistrationAddress: React.FC<IRegistrationAddress> = ({
  countries,
}) => {
  const { Option } = Select;

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <FormCard title={"Office address"} size={"small"}>
      <Form.Item
        name={["registrationAddressData", "addressLine1"]}
        id={"registrationAddressData.addressLine1"}
        label={"Address line 1"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["registrationAddressData", "addressLine2"]}
        id={"registrationAddressData.addressLine2"}
        label={"Address line 2"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["registrationAddressData", "city"]}
        id={"registrationAddressData.city"}
        label={"City"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["registrationAddressData", "country"]}
        id={"registrationAddressData.country"}
        label={"Country"}
      >
        <Select showSearch filterOption={filterOption} allowClear>
          {countries.map((item) => (
            <Option key={item.callingCode}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["registrationAddressData", "postCode"]}
        id={"registrationAddressData.postCode"}
        label={"Post code"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["registrationAddressData", "postTown"]}
        id={"registrationAddressData.postTown"}
        label={"Post town"}
      >
        <Input />
      </Form.Item>
    </FormCard>
  );
};
