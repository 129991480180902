export const FIRST_DATE_MOMENT_FORMAT = "YYYY-MM-DDT00:00:01.000[Z]";
export const LAST_DATE_MOMENT_FORMAT = "YYYY-MM-DDT23:59:59.999[Z]";

export const typesOfMerchant = ["COMPANY", "SOLE_TRADER"];

export const statusMap = [
  "NOT_STARTED",
  "NOT_FINISHED",
  "DOCUMENT_REQUESTED",
  "UNDER_REVIEW",
  "APPROVED",
];

export const EMAIL_SERVICES = [
  {
    value: "INTERNAL",
    name: "INTERNAL",
  },
  {
    value: "CUSTOMER_IO",
    name: "CUSTOMER_IO",
  },
];


export const LOCALE_MAP = [
  {
    value: "en",
    name: "en",
  },
];

export const EMPLOYEE_STATUS_MAP = [
  {
    value: "NEW",
    name: "New",
  },
  {
    value: "EXPORTED",
    name: "Exported",
  },
  {
    value: "ZERO_TRANSACTION",
    name: "Zero transaction",
  },
  {
    value: "EXTERNAL_SYSTEM_NOT_READY",
    name: "External system not ready",
  },
  {
    value: "ACTIVATED",
    name: "Activated",
  },
  {
    value: "SUSPENDED",
    name: "Suspended",
  },
];

export const TRANSACTIONS_TYPES_MAP = [
  {
    value: "CANCEL",
    name: "Cancel",
  },
  {
    value: "CHARGEBACK",
    name: "Chargeback",
  },
  {
    value: "MANUAL",
    name: "Manual",
  },
  {
    value: "PAYMENT",
    name: "Payment",
  },
  {
    value: "REFUND",
    name: "Refund",
  },
];

export const PAYOUTS_STATUSES_MAP = [
  {
    value: "APPLIED",
    name: "Applied",
  },
  {
    value: "APPROVED",
    name: "Approved",
  },
  {
    value: "CANCELLED",
    name: "Cancelled",
  },
  {
    value: "REJECTED",
    name: "Rejected",
  },
  {
    value: "EXPORT_FAILED",
    name: "Export failed",
  },
  {
    value: "IN_PROGRESS",
    name: "In progress",
  },
  {
    value: "NEW",
    name: "New",
  },
  {
    value: "PAID",
    name: "Paid",
  },
  {
    value: "RECONCILED",
    name: "Reconciled",
  },
  {
    value: "RECONCILIATION_FAILED",
    name: "Reconciliation failed",
  },
  {
    value: "VALIDATION_FAILED",
    name: "Validation failed",
  },
];

export const onboardingStatusMap = [
  {
    value: "APPROVED",
    name: "Approved",
  },
  {
    value: "CHANGES_REQUESTED",
    name: "Changes requested",
  },
  {
    value: "NEW",
    name: "New",
  },
  {
    value: "REJECTED",
    name: "Rejected",
  },
  {
    value: "REVIEWING",
    name: "Reviewing",
  },
];

export const MERCHANT_STATUSES_MAP = ["ACTIVATED", "SUSPENDED"];

export const GOOGLE_MAPS_API_KEY = "AIzaSyCMhtMnQ9MV_tya_cZjPGorlckms1YKhf8";
