import React from "react";
import { Filters } from "./components/Filters";
import { PageHeader } from "antd";
import { Helmet } from "react-helmet";
import { Table } from "./components/Table";
import { useDictionary } from "../../shared/api/dictionary/useDictionary";
import { AppliedFilters } from "../../features/appliedFilters";
import { Spinner } from "../../ui/Spinner/Spinner";

export const Onboardings: React.FC = () => {
  const { dictionaryData } = useDictionary();

  return (
    <>
      <Helmet>
        <title>Onboardings</title>
      </Helmet>
      <PageHeader title="Onboardings" style={{ paddingLeft: 0 }} />
      <Filters />
      <AppliedFilters />
      {dictionaryData ? <Table dictionary={dictionaryData} /> : <Spinner />}
    </>
  );
};
