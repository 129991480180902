import React, { useEffect, useState } from "react";
import { Select, DatePicker, Input, Row, Col, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

import { useChangeSearchParams } from "../../../shared/hooks/useChangeSearchParams";
import utc from "dayjs/plugin/utc";

// todo перенести это в какой-нибдуь конфиг
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

export const Filters: React.FC = () => {
  let [searchParams] = useSearchParams();

  const { changeSearchParams, setInitialParams, resetToInitialParams } =
    useChangeSearchParams();

  const initialFilters = {
    limit: "11",
    offset: "0",
    states: "",
    from: "",
    to: "",
    companyName: "",
    "orderBy[0].field": "created",
    "orderBy[0].direction": "DESC",
  };

  const searchFilter = searchParams.get("companyName");
  const statusFilter = searchParams.get("states");
  const dateFromFilter = searchParams.get("from");
  const dateToFilter = searchParams.get("to");

  const [search, setSearch] = useState(searchFilter || undefined);

  const rangeFilter = [
    dateFromFilter && dayjs(dateFromFilter),
    dateToFilter && dayjs(dateToFilter),
  ];

  useEffect(() => {
    setInitialParams(initialFilters);
  }, []);

  useEffect(() => {
    setSearch(searchFilter as string);
  }, [searchFilter]);

  const handleStatusFilter = (value: string) => {
    changeSearchParams({ states: value });
  };

  const handleSearchFilter = (search: string) => {
    changeSearchParams({ companyName: search });
  };

  const handleDateChange = (momentRange: any) => {
    if (momentRange === null) {
      changeSearchParams({
        from: "",
        to: "",
      });
    }
    changeSearchParams({
      from: momentRange[0].toDate().toISOString(),
      to: momentRange[1].toDate().toISOString(),
    });
  };

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { Search } = Input;

  //todo заменить тут все фильтры на переиспользуемые

  return (
    <>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row>
            <Col xxl={8} xl={10} lg={14}>
              <Row>
                <Col span={12}>
                  <Select
                    placeholder="Filter by status"
                    style={{ width: "100%" }}
                    onChange={handleStatusFilter}
                    defaultValue={statusFilter}
                    value={statusFilter || null}
                  >
                    <Option value={"APPROVED"}>Approved</Option>
                    <Option value={"CHANGES_REQUESTED"}>
                      Changes requested
                    </Option>
                    <Option value={"NEW"}>New</Option>
                    <Option value={"REJECTED"}>Rejected</Option>
                    <Option value={"REVIEWING"}>Reviewing</Option>
                  </Select>
                </Col>
                <Col span={12}>
                  {/*@ts-ignore*/}
                  <RangePicker
                    format={"DD MMM"}
                    onChange={handleDateChange}
                    // todo fix
                    //@ts-ignore
                    value={rangeFilter}
                    style={{ marginLeft: 25 }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col xxl={8} xl={10} lg={14}>
              <Search
                placeholder={"Search by company name"}
                onSearch={handleSearchFilter}
                enterButton={false}
                defaultValue={searchFilter || ""}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Button
                icon={<FilterOutlined />}
                style={{ marginRight: 15 }}
                onClick={() => resetToInitialParams(initialFilters)}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
