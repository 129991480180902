import React, { useState } from "react";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { Select } from "antd";
import { useSearchParams } from "react-router-dom";

interface ISelectTableFilter {
  placeholder: string;
  filterName: string;
  optionItems: any[];
  valueName: string;
  name: string;
  onSelect?: () => void;
  mode?: "multiple" | "tags";
}

export const SelectTableFilter: React.FC<ISelectTableFilter> = ({
  placeholder,
  filterName,
  optionItems,
  valueName,
  name,
  onSelect,
  mode,
}) => {
  const { changeSearchParams } = useChangeSearchParams();
  let [searchParams] = useSearchParams();

  const handleSelectFilter = (selectValue: string) => {
    setValue(selectValue);
    changeSearchParams({ [filterName]: selectValue });
    onSelect && onSelect();
  };

  const searchFilter =
    mode === "multiple"
      ? searchParams.getAll(filterName)
      : searchParams.get(filterName);

  const [value, setValue] = useState(searchFilter || undefined);

  // useEffect(() => {
  //   setValue(searchFilter as string);
  // }, [searchFilter]);

  const { Option } = Select;

  return (
    <Select
      onKeyDown={(e) => e.stopPropagation()}
      placeholder={placeholder}
      style={{ width: "100%" }}
      onChange={handleSelectFilter}
      defaultValue={value}
      value={value || null}
      mode={mode}
    >
      {optionItems.map((item) => {
        return <Option value={item[valueName]}>{item[name]}</Option>;
      })}
    </Select>
  );
};
