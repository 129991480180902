import React from "react";
import { DevTools } from "features/devTools/DevTools";

export const Dev: React.FC = ({ children }) => {
  return (
    <>
      {children}
      {process.env.REACT_APP_ENVIRONMENT === "development" ? (
        <DevTools />
      ) : null}
    </>
  );
};
