//todo перенести это в другое место, вероятно в хук???
//todo один общий файл для утилсов или для каждого свой??
import { parseQueryParamsToObject } from "../../../services/searchParams/changeSearchParams";

export const parseQueryParamsToArr = (queryParams: URLSearchParams) => {
  const queryobj = parseQueryParamsToObject(queryParams);
  const result = [];
  for (let key in queryobj) {
    result.push({
      queryName: key,
      // @ts-ignore
      value: queryobj[key],
    });
  }

  return result;
};

export const getNonEmptyFilters = (filters: [any]) => {
  return filters.filter((item) => {
    let result = true;
    //проверка на пустые значения в списке фильтров
    Object.values(item).forEach((value) => {
      if (!Boolean(value)) {
        result = false;
      }
    });
    return result;
  });
};

export const getFiltersObjectFromArray = (filtersArr: string[]) => {
  const filtersObj: any = {};
  filtersArr.forEach((item) => {
    filtersObj[item] = "";
  });
  return filtersObj;
};
