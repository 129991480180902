import { useCreatePartnerMutation } from "../api/partnerApi";
import { notification } from "antd";
import { IPartnerCreateRequest } from "../types/partnerTypes";
import { NamePath } from "antd/lib/form/interface";

export const useCreatePartner = () => {
  const [createPartner] = useCreatePartnerMutation();

  const onSubmitCreateForm = (
    fields: IPartnerCreateRequest,
    clearForm: (fields?: NamePath[] | undefined) => void
  ) => {
    createPartner(fields).then((result) => {
      if ("data" in result) {
        notification.open({
          message: `Partner "${fields.name}" has been created`,
          type: "success",
        });
        clearForm();
      } else if ("error" in result) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    onSubmitCreateForm,
  };
};
