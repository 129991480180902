import { Spin } from "antd";
import { SpinProps } from "antd/lib/spin";
import { Flex } from "ui/Flex";

interface ISpinner extends SpinProps {}

export const Spinner: React.FC<ISpinner> = (props) => {
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      css={`
        width: 100%;
        height: 70%;
      `}
    >
      <Spin {...props} />
    </Flex>
  );
};
