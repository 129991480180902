import React from "react";
import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import { FormCard } from "../../../../components/FormCard/FormCard";
import {
  statusMap,
  typesOfMerchant,
} from "../../../../common/constants/constants";
import moment from "moment";

export const Profile: React.FC = () => {
  const { Option } = Select;

  return (
    <FormCard title={"Profile"} size={"large"}>
      <Form.Item
        rules={[
          {
            required: true,
            message: "required",
          },
        ]}
        name={["profile", "kind"]}
        id={"profile.kind"}
        label={"Kind"}
      >
        <Select showSearch>
          {typesOfMerchant.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "required",
          },
        ]}
        name={["profile", "name"]}
        id={"profile.name"}
        label={"Name"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "required",
          },
        ]}
        name={["profile", "id"]}
        id={"profile.id"}
        label={"Id"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "required",
          },
        ]}
        name={["profile", "status"]}
        id={"profile.status"}
        label={"Status"}
      >
        <Select showSearch>
          {statusMap.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "required",
          },
        ]}
        name={["profile", "created"]}
        id={"profile.created"}
        label={"Created"}
        getValueProps={(value: any) => ({
          value: moment(value),
        })}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
      {/*<Form.Item*/}
      {/*  rules={[*/}
      {/*    {*/}
      {/*      required: true,*/}
      {/*      message: "required",*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*  name={["profile", "selected"]}*/}
      {/*  id={"profile.selected"}*/}
      {/*>*/}
      {/*  <Checkbox>Selected</Checkbox>*/}
      {/*</Form.Item>*/}
      <Form.Item
        name={["profile", "managerName"]}
        id={"profile.managerName"}
        label={"Manager name"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "required",
          },
        ]}
        name={["profile", "partner", "id"]}
        id={"profile.partner.id"}
        label={"Partner id"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "required",
          },
        ]}
        name={["profile", "partner", "name"]}
        id={"profile.partner.name"}
        label={"Partner name"}
      >
        <Input />
      </Form.Item>
    </FormCard>
  );
};
