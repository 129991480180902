import { useGetMerchantsQuery } from "./api";

export const useGetMerchants = (searchParams: string) => {
  const { data, error, status, isLoading, isSuccess } =
    useGetMerchantsQuery(searchParams);

  const merchantsData = data || [];

  return { data: merchantsData, error, status, isLoading, isSuccess };
};
