import React from "react";
import { Button, Col, Form as AntForm, Input, Row } from "antd";
// import { Styled } from "../styles";
import { General } from "./Cards/General";
import { IDictionaryResponse } from "../../../data-layer/dictionary/types/IDictionary";
import { IMerchantResponse } from "../../../data-layer/merchant/types/merchantTypes";
import { Profile } from "./Cards/Profile";
import { RegistrationAddress } from "./Cards/RegistrationAddress";
import { OfficeAddress } from "./Cards/OfficeAddress";

interface IFormProps {
  data: IMerchantResponse;
  dictionaryData: IDictionaryResponse;
  onUpdateMerchants: (fields: IMerchantResponse) => void;
  // timeZones: string[];
  // currencies: string[];
  // countries: ICountries[];
  // personDocuments: IAttachmentsResponse[];
  // generalDocuments: IAttachmentsResponse[];
  // onDownloadFile: (id: number) => void;
  // profileType?: "COMPANY" | "SOLE_TRADER";
  // business: IBusiness[];
}

export const Form: React.FC<IFormProps> = ({
  data,
  dictionaryData,
  onUpdateMerchants,
  // onApproveStatus,
  // onRejectStatus,
  // timeZones,
  // currencies,
  // countries,
  // business,
  // personDocuments,
  // onDownloadFile,
  // profileType,
  // generalDocuments,
}) => {
  const [form] = AntForm.useForm();

  return (
    <AntForm
      form={form}
      layout="vertical"
      scrollToFirstError={true}
      onFinish={(values) => onUpdateMerchants(values)}
      initialValues={data}
    >
      <Row gutter={25}>
        <Col span={8}>
          <General
            countries={dictionaryData?.countries || []}
            currencies={dictionaryData?.currencies || []}
            timeZones={dictionaryData?.timeZones || []}
          />
        </Col>
        <Col span={8}>
          <Profile />
        </Col>
        <Col span={8}>
          <RegistrationAddress countries={dictionaryData?.countries || []} />
          <OfficeAddress countries={dictionaryData?.countries || []} />
        </Col>
        <Button htmlType={"submit"}>save</Button>
        {/*    <BusinessCard*/}
        {/*      business={business}*/}
        {/*      typeOfBusinessCurrentValue={typeOfBusinessCurrentValue}*/}
        {/*    />*/}
        {/*    <MediaCard />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        {/*<Row gutter={25}>*/}
        {/*  <Col span={12}>*/}
        {/*    <MandatoryCard*/}
        {/*      currencies={currencies}*/}
        {/*      timeZones={timeZones}*/}
        {/*      data={data}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*  <Col span={12}>*/}
        {/*    {profileType === "SOLE_TRADER" && (*/}
        {/*      <PersonCard*/}
        {/*        documents={personDocuments}*/}
        {/*        onDownloadFile={onDownloadFile}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    {profileType === "COMPANY" && data["OWNERS"] && (*/}
        {/*      <OwnerCard*/}
        {/*        data={data["OWNERS"]}*/}
        {/*        onDownloadFile={onDownloadFile}*/}
        {/*        countries={countries}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </Col>*/}
      </Row>
      {/*<Styled.FooterContainer>*/}
      {/*  <Styled.ButtonContainer>*/}
      {/*    <Styled.Button danger size={"large"} onClick={() => onRejectStatus()}>*/}
      {/*      Reject*/}
      {/*    </Styled.Button>*/}
      {/*    <Styled.Button size={"large"} onClick={handleSaveForm}>*/}
      {/*      Save*/}
      {/*    </Styled.Button>*/}
      {/*    <Styled.Button size={"large"} htmlType="submit">*/}
      {/*      Approve*/}
      {/*    </Styled.Button>*/}
      {/*  </Styled.ButtonContainer>*/}
      {/*</Styled.FooterContainer>*/}
    </AntForm>
  );
};
