import { useGetTransactionAdminQuery } from "../api/transactionApi";
import { ITransactionResponse } from "../types/ITransaction";
import dayjs from "dayjs";

export const useTransaction = (
  searchParams: URLSearchParams,
  limitFilter: string
) => {
  validateSearchParamsTypes(searchParams);

  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess } =
    useGetTransactionAdminQuery(validateSearchParamsTypes(searchParams), {
      skip: skipRequest,
    });

  const validateDateTime = (data: ITransactionResponse[]) => {
    return data.map((item) => {
      return {
        ...item,
        dateTime: dayjs(item.dateTime).format("DD MMM, YYYY"),
      };
    });
  };

  const transactionData = data?.length ? validateDateTime(data) : [];
  //for pagination check
  let dataWithoutLastItem = [...transactionData];
  limitFilter && dataWithoutLastItem.splice(parseInt(limitFilter) - 1, 1);

  return {
    transactionData,
    dataWithoutLastItem,
    data,
    error,
    isLoading,
    isSuccess,
    requestStatus: status,
  };
};

const validateSearchParamsTypes = (searchParams: URLSearchParams) => {
  let params = searchParams.toString();
  if (!searchParams.get("types")) {
    transactionTypes.forEach((type) => {
      params += `&types=${type}`;
    });
  }
  return params;
};

const transactionTypes = [
  "CANCEL",
  "CHARGEBACK",
  "MANUAL",
  "PAYMENT",
  "REFUND",
];
