import styled from "styled-components";

const ComplianceOfficerCommentWrapper = styled.div`
  border-right: 1px solid #f0f0f0;
  padding-right: 10px;
`;

const DownloadedDocumentsAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const DownloadedDocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AttachedWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const Styled = {
  ComplianceOfficerCommentWrapper,
  DownloadedDocumentsWrapper,
  DownloadedDocumentsAreaWrapper,
  AttachedWrapper,
};
