import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import ReCAPTCHA from "react-google-recaptcha";
import {Button, Col, Row, Spin} from "antd";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { ILoginRequest } from "../types";
import { Styled } from "../style";
import { useLogin } from "../api/useLogin";
import {OTPInput} from "./OTPInput";
import '../style/styles.css'
import {useResendOTP} from "../../../data-layer/auth/hooks/useResendOTP";

export const SignupSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

const initialValues: Omit<ILoginRequest, "recaptchaResponse"> = {
  email: "",
  password: "",
};

export const LoginForm: React.FC = () => {
  const { onLogin, isLoading } = useLogin();
  const { onResendOTP} = useResendOTP()

  const [isOtpRequired, setIsOtpRequired] = useState(false)


  const recaptchaRef = useRef(null);
  const [isRecaptchaPending, setIsRecaptchaPending] = useState<boolean>(false);

  const onSubmitWithReCAPTCHA = async (values: ILoginRequest) => {
    const token = await recaptchaRef?.current?.executeAsync();
    setIsRecaptchaPending(true);
    onLogin(values, token).then((result) => {
      if("error" in result) {
        if(result.error?.data?.error === "OTP_CODE_REQUIRED") {
          setIsOtpRequired(true)
        }
      }
    })
    setIsRecaptchaPending(false);
    recaptchaRef?.current?.reset();
  };



  const handleResendOTPCode = (email?: string) => {
    if (!email) {
      return;
    }

    onResendOTP(email);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: Omit<ILoginRequest, "recaptchaResponse">) => {
        onSubmitWithReCAPTCHA(values);
      }}
      validationSchema={SignupSchema}
    >
      {({ handleSubmit, errors, setFieldValue, values }) => {
        return (
          <>
            <Form layout={"vertical"}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                // sitekey={"6Lc1olklAAAAAP3yw_pKBfnfQrUeIKtpd0qCTkw-"}
                ref={recaptchaRef}
                size="invisible"
              />
              <Form.Item
                label={<Styled.FormLabel>Login</Styled.FormLabel>}
                name="email"
              >
                <Input name="email" placeholder={"Email or phone number"} />
              </Form.Item>
              <Form.Item
                label={<Styled.FormLabel>Password</Styled.FormLabel>}
                name={"password"}
              >
                <Input.Password name={"password"} placeholder={"Password"} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 7,
                  }}
                >
                  <Styled.FormLabel>
                    <Styled.ForgotPasswordLink to={"/password-recovery"}>
                      Forgot password?
                    </Styled.ForgotPasswordLink>
                  </Styled.FormLabel>
                </div>
              </Form.Item>
              {isOtpRequired && (

                  <Col span={24}>
                    <Styled.OTPTitle>Enter OTP code</Styled.OTPTitle>
                    <Styled.OTPDescription>
                      An 6 digit code has been sent to your email
                      {/*<span>{values.email}</span>*/}
                    </Styled.OTPDescription>
                    <Styled.OTPInputWrapper>
                      <Form.Item
                          name={"otpCode"}
                          // error={errors.otpCode}
                          // showError={false}
                      >
                        <Row justify="end">
                          <Col span={24}>
                            <OTPInput
                                value={values.otpCode || ""}
                                onChange={(value) => {
                                  setFieldValue("otpCode", value);
                                }}
                                numInputs={6}
                                separator={null}
                                containerStyle="OTPContainer"
                                inputStyle="OTPCol"
                            />
                          </Col>
                          <Col span={24}>
                            <Row justify="end">
                              <Col>
                                <Styled.ResendOTP
                                    type="button"
                                    onClick={() =>
                                        handleResendOTPCode(values.email)
                                    }
                                    // disabled={employee.isResendingOTPCode}
                                >
                                  Resend code
                                  {/*{employee.isResendingOTPCode && <Spin />}*/}
                                </Styled.ResendOTP>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Styled.OTPInputWrapper>
                  </Col>
              )}

              <Button
                style={{ marginTop: 25 }}
                loading={isRecaptchaPending || isLoading}
                //todo important добавить лоадинг
                // loading={isLoading || isRecaptchaPending}
                block
                size={"large"}
                type={"primary"}
                htmlType={"submit"}
              >
                Sign in
              </Button>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
