import { useDownloadFileByNameMutation } from "../api/sekumpulUpload";
import { notification } from "antd";

export const useDownloadFileByName = () => {
  const [download] = useDownloadFileByNameMutation();

  const onDownload = (file: any) => {
    downloadWaitingNotify();
    download(file)
      .then((response) => {
        const blob = new Blob([response.error.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = file;
        link.click();
        downloadCompleteNotify();
      })
      .catch(console.error);
  };

  return {
    onDownload,
  };
};

const waitingNotifyKey = "waiting";

const downloadWaitingNotify = () => {
  notification.open({
    message: "Download is about to start",
    description: "Please wait a bit",
    type: "info",
    duration: 0,
    key: waitingNotifyKey,
  });
};

const downloadCompleteNotify = () => {
  notification.close(waitingNotifyKey);
  notification.open({
    message: "Success",
    description: "Download complete",
    type: "success",
  });
};
