import { useState } from "react";
import { FormInstance } from "antd";

interface IUseValidate {
  form: FormInstance;
}

export const useValidateTabs = ({ form }: IUseValidate) => {
  const [currentTabs, setCurrentTab] = useState<string>("1");

  const onSwitchToInvalidateTab = () => {
    if (
      !isAllFieldsValid(form.getFieldsValue(businessDetailsRequiredFieldsList))
    ) {
      setCurrentTab("1");
    } else if (
      !isAllFieldsValid(form.getFieldsValue(personalDetailsRequiredFieldsList))
    ) {
      setCurrentTab("2");
    } else if (
      !isAllFieldsValid(form.getFieldsValue(pricingModelsRequiredFieldsList))
    ) {
      setCurrentTab("4");
    }
    form.validateFields();
  };

  return {
    currentTabs,
    setCurrentTab,
    onSwitchToInvalidateTab,
  };
};

type Fields = { [name: string]: any };

export const isAllFieldsValid = (fields: Fields) => {
  let res = true;
  for (const field in fields) {
    if (fields[field] === undefined) {
      res = false;
    }
  }
  return res;
};

const personalDetailsRequiredFieldsList = [
  "PERSON_FIRST_NAME",
  "PERSON_DOB",
  "PERSON_POSTAL_CODE",
  "PERSON_LAST_NAME",
  "PERSON_ADDRESS_LINE_1",
  "PERSON_CITY",
];

const businessDetailsRequiredFieldsList = [
  "COMPANY_NAME",
  "COMPANY_STATUS",
  "CITY",
  "OFFICE_CITY",
  "PHONE_NUMBER",
  "EMAIL",
  "TYPE_OF_BUSINESS",
  "PAYMENT_HISTORY",
  "AVERAGE_TX_VALUE",
  "VAT_NUMBER",
  "ADDRESS_LINE_1",
  "POSTAL_CODE",
  "OFFICE_ADDRESS_LINE_1",
  "OFFICE_POSTAL_CODE",
  "EXPECTED_TURNOVER",
  "ACCOUNT_HOLDER_NAME",
  "ACCOUNT_NUMBER",
  "SORT_CODE",
  "MCC",
  "CURRENCY",
  "TIME_ZONE",
];

const pricingModelsRequiredFieldsList = [
  "PM_IN_STORE",
  "PM_IN_STORE_FEE_TX_FIX",
  "PM_IN_STORE_FEE_TX_PERCENTAGE",
  "PM_ONLINE",
  "PM_ONLINE_FEE_TX_FIX",
  "PM_ONLINE_FEE_TX_PERCENTAGE",
  "PM_ONLINE_GLOBAL"
];
