import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";
import {
  ITransactionResponse,
  ITransactionsAggregate,
} from "../types/ITransaction";

// Define a service using a base URL and expected endpoints
export const transactionApi = createApi({
  reducerPath: "transactionApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getTransactionAdmin: builder.query<ITransactionResponse[], string>({
      query: (params) => `/transaction/admin?${params}`,
    }),
    getTransactionAggregateAdmin: builder.query<
      ITransactionsAggregate[],
      string
    >({
      query: (params) => `/transaction/agg/admin?${params}`,
    }),
  }),
});
export const {
  useGetTransactionAdminQuery,
  useGetTransactionAggregateAdminQuery,
} = transactionApi;
