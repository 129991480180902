import { notification } from "antd";
import { useRunPullerByMutation } from "../api/systemApi";
import { IRunPullerByRequest } from "../types/ISystem";

export const useRunFiservPullerBy = () => {
    const [runPullerBy, { data, isLoading }] =
        useRunPullerByMutation();

    const onRunFiservPullerBy = (runPullerByDTO: IRunPullerByRequest) => {
        runPullerBy(runPullerByDTO).then((result) => {
            if ("data" in result) {
                notification.open({
                    message: "Run fiserv puller by success",
                    type: "success",
                });
            } else if (result.error) {
                notification.open({
                    message: "Something went wrong",
                    description: result.error?.data?.error,
                    type: "error",
                });
            }
        });
    };

    return {
        onRunFiservPullerBy,
        isLoading,
        data,
    };
};
