import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";
import {
  IOverviewMerchantsResponse,
  IOverviewPartnersResponse,
} from "../types/IAdminController";

// Define a service using a base URL and expected endpoints
export const adminControllerApi = createApi({
  reducerPath: "adminControllerApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getOverviewMerchants: builder.query<IOverviewMerchantsResponse, string>({
      query: (params: string) => `/admin/overview/merchants?${params}`,
    }),
    getOverviewPartners: builder.query<IOverviewPartnersResponse, string>({
      query: (params: string) => `/admin/overview/partners?${params}`,
    }),
  }),
});
export const { useGetOverviewMerchantsQuery, useGetOverviewPartnersQuery } =
  adminControllerApi;
