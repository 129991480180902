export function isNumeric(str: unknown): boolean {
    if (typeof str !== "string") return false; // we only process strings!

    return (
        !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
}

export function parseStringToNumber(value: unknown): number {
    if (!Number.isNaN(Number(value))) {
        return Number(value);
    }
    return 0;
}