import { useFindMerchantsMutation } from "../api/merchantApi";
import { IMerchantResponse } from "../types/merchantTypes";
import { useEffect, useState } from "react";

export const useFindMerchants = () => {
  const [findResultCache, setFindResultCache] = useState<
    { value: string; key: number }[]
  >([]);

  const [getFindMerchants, { data }] = useFindMerchantsMutation({
    fixedCacheKey: "merchantFind",
  });

  useEffect(() => {
    if (data !== undefined) {
      setFindResultCache(validateSearchData(data));
    }
  }, [data]);

  const validateSearchData = (data: IMerchantResponse[]) => {
    return data.map((item) => {
      const { id, merchantName } = item;
      return {
        key: id,
        value: merchantName,
      };
    });
  };

  return {
    getFindMerchants,
    searchData: findResultCache,
  };
};
