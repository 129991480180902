import styled from "styled-components";
import { CSSProp } from "styled-components";

interface IContainer {
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingRight?: number;
  paddingLeft?: number;
  width?: number;
  css?: CSSProp;
}

export const Container = styled.div<IContainer>`
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-right: ${(props) => props.marginRight}px;
  margin-left: ${(props) => props.marginLeft}px;
  padding-top: ${(props) => props.paddingTop}px;
  padding-bottom: ${(props) => props.paddingBottom}px;
  padding-right: ${(props) => props.paddingRight}px;
  padding-left: ${(props) => props.paddingLeft}px;
  //width: ${(props) => props.width}px;
  ${(props) => props.css}
`;
