import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { Button, Col, Row } from "antd";
import { SearchTableFilter } from "../../components/SearchTableFilter";
import { FilterOutlined } from "@ant-design/icons";
import { MultiselectTableFilter } from "../../components/MultiselectTableFilter";
import { LOCALE_MAP } from "../../common/constants/constants";

export const Filters: React.FC = () => {
  let [searchParams] = useSearchParams();

  const { setInitialParams, resetToInitialParams } = useChangeSearchParams();

  const initialFilters = {
    limit: "11",
    offset: "0",
  };

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [searchParams]);

  return (
    <>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row>
            <Col xxl={8} xl={10} lg={14}>
              <Row>
                <Col span={12}>
                  <SearchTableFilter
                    placeholder={"Search by id"}
                    filterName={"id"}
                  />
                </Col>
                <Col span={12}>
                  <div style={{ marginLeft: 25 }}>
                    <SearchTableFilter
                      placeholder={"Search by value"}
                      filterName={"value"}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col xxl={8} xl={10} lg={14}>
              <MultiselectTableFilter
                placeholder={"Filter by locale"}
                filterName={"locale"}
                optionItems={LOCALE_MAP}
                name={"name"}
                valueName={"value"}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Button
                icon={<FilterOutlined />}
                style={{ marginRight: 15 }}
                onClick={() => resetToInitialParams(initialFilters)}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
