import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { rootReducer } from "./rootReducer";
import { authApi } from "../auth/api/authApi";
import { partnerApi } from "../partner/api/partnerApi";
import { i18settingsApi } from "../i18settings/api/i18settingsApi";
import { dictionaryApi } from "../dictionary/api/dictionaryApi";
import { profileApi } from "../profile/api/profileApi";
import { merchantApi } from "../merchant/api/merchantApi";
import { employeeApi } from "../employee/api/employeeApi";
import { adminControllerApi } from "../adminController/api/adminControllerApi";
import { transactionApi } from "../transaction/api/transactionApi";
import { applicationApi } from "../application/api/applicationApi";
import { attachmentApi } from "../attachment/api/attachmentApi";
import { documentRequestApi } from "../documents/api/documentRequestApi";
import { payoutApi } from "../payout/api/payoutApi";
import { rolesApi } from "../roles/api/rolesApi";
import { userApi } from "../user/api/userApi";
import { systemApi } from "../system/api/systemApi";
import { terminalApi } from "../terminals/api/terminalApi";
import { statementIndexesApi } from "../statmentIndexes/api/statementIndexesApi";
import { crmApi } from "../../pages/crm/api/api";
import { sekumpulApi } from "../sekumpulUpload/api/sekumpulUpload";
import {reportApi} from "../../pages/reports/api/api";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware,
      partnerApi.middleware,
      i18settingsApi.middleware,
      dictionaryApi.middleware,
      profileApi.middleware,
      merchantApi.middleware,
      employeeApi.middleware,
      adminControllerApi.middleware,
      transactionApi.middleware,
      applicationApi.middleware,
      attachmentApi.middleware,
      documentRequestApi.middleware,
      payoutApi.middleware,
      rolesApi.middleware,
      userApi.middleware,
      systemApi.middleware,
      terminalApi.middleware,
      statementIndexesApi.middleware,
      crmApi.middleware,
      sekumpulApi.middleware,
        reportApi.middleware
    ),
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => {
  return useDispatch<AppDispatch>();
};
