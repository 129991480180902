import { Form, Input, InputNumber, Select } from "antd";
import React from "react";
import { FormCard } from "../../../../components/FormCard/FormCard";
import { yupSyncMerchantEdit } from "../../../../common/merchantEditForm/schema";
import { ICountries } from "../../../../data-layer/dictionary/types/IDictionary";
import {
  MERCHANT_STATUSES_MAP,
  typesOfMerchant,
} from "../../../../common/constants/constants";

interface IGeneral {
  countries: ICountries[];
  currencies: string[];
  timeZones: string[];
}

export const General: React.FC<IGeneral> = ({
  countries,
  currencies,
  timeZones,
}) => {
  const { Option } = Select;

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <FormCard title={"General"} size={"large"}>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"merchantName"}
        id={"merchantName"}
        label={"Merchant name"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"kind"}
        id={"kind"}
        label={"Kind"}
      >
        <Select showSearch>
          {typesOfMerchant.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name={"status"}
        id={"status"}
        label={"Status"}
      >
        <Select showSearch>
          {MERCHANT_STATUSES_MAP.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name={"mid"}
        id={"mid"}
        label={"Mid"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"merchantNumber"}
        id={"merchantNumber"}
        label={"Merchant number"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"email"}
        id={"email"}
        label={"Email"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"country"}
        id={"country"}
        label={"Country"}
      >
        <Select showSearch filterOption={filterOption}>
          {countries.map((item) => (
            <Option key={item.callingCode}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"currency"}
        id={"currency"}
        label={"Currency"}
      >
        <Select showSearch>
          {currencies.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"phoneNumber"}
        id={"phoneNumber"}
        label={"Phone number"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"sortCode"}
        id={"sortCode"}
        label={"Sort code"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"tidPrefix"}
        id={"tidPrefix"}
        label={"Tid prefix"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"timeZone"}
        id={"timeZone"}
        label={"Time zone"}
      >
        <Select showSearch>
          {timeZones.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"id"}
        id={"id"}
        label={"id"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"iban"}
        id={"iban"}
        label={"Iban"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[yupSyncMerchantEdit]}
        name={"mcc"}
        id={"mcc"}
        label={"Mcc"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        // rules={[yupSyncMerchantEdit]}
        name={"cdxExternalId"}
        id={"cdxExternalId"}
        label={"CsdExternalId"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name={"bankAccountHolderName"}
        id={"bankAccountHolderName"}
        label={"Bank account holder name"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name={"bankAccountNumber"}
        id={"bankAccountNumber"}
        label={"Bank account number"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        // rules={[yupSyncMerchantEdit]}
        name={"cctExternalId"}
        id={"cctExternalId"}
        label={"CctExternalId"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        // rules={[yupSyncMerchantEdit]}
        name={"feePercent"}
        id={"feePercent"}
        label={"Fee percent"}
      >
        <InputNumber />
      </Form.Item>
    </FormCard>
  );
};
