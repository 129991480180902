import React, { useState } from "react";
import { Button, Dropdown, MenuProps, Switch } from "antd";
import { Styled as TableStyled } from "shared/style";
import { IMerchantResponse } from "../../data-layer/merchant/types/merchantTypes";
import { history } from "../../routes/AppRouter";
import { ColumnsType } from "antd/lib/table";
import { MerchantDetailDrawer } from "./MerchantDetailDrawer";
import { IMerchantDetail } from "../../scenarios/merchant/types";
import { useReCreateMSA } from "../../data-layer/merchant/hooks/useReCreateMSA";

interface ITableProps {
  tableData: IMerchantResponse[];
  isLoading: boolean;
  requestStatus: string;
  handleLoadMoreMerchants: () => void;
  pagination: boolean;
  onOpenConnectModal: () => void;
  setCurrentMerchantId: (id: number) => void;
  onBlockMerchant: (block: boolean, info: IMerchantResponse) => void;
}

export const Table: React.FC<ITableProps> = ({
  tableData,
  isLoading,
  requestStatus,
  handleLoadMoreMerchants,
  pagination,
  onOpenConnectModal,
  setCurrentMerchantId,
  onBlockMerchant,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [merchantDetail, setMerchantDetail] = useState<IMerchantDetail | null>(
    null
  );

  const onDrawerOpen = (merchant: IMerchantDetail) => {
    setMerchantDetail(merchant);
    setOpenDrawer(true);
  };

  const onDrawerClose = () => {
    setOpenDrawer(false);
  };

  const onRowClick = () => {
    if (merchantDetail && merchantDetail.id) {
      history.push(`/dashboard/merchants/${merchantDetail.id}`);
      history.go(0);
    }
  };

  const { onReCreateMSA } = useReCreateMSA();

  const onReCreateMSAFunction = (id: number) => {
    onReCreateMSA(id);
  };

  const columns = getMerchantTableColumns(
    onOpenConnectModal,
    setCurrentMerchantId,
    onBlockMerchant,
    onReCreateMSAFunction,
  );

  return (
      <>
      <TableStyled.Table
        loading={requestStatus === "pending" || isLoading}
        columns={columns}
        dataSource={tableData}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              onDrawerOpen(record);
            },
          };
        }}
      />
      {!isLoading && tableData.length > 0 && pagination && (
        <Button
          onClick={handleLoadMoreMerchants}
          loading={requestStatus === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more merchants
        </Button>
      )}
      <MerchantDetailDrawer open={openDrawer} data={merchantDetail} onClose={onDrawerClose} onEditMerchant={onRowClick} />
    </>
  );
};

interface MerchantsTableColumns {
  key: React.Key;
  id: number;
  merchantName: string;
  merchantNumber: string;
  email: string;
  currency: string;
}

const getMerchantTableColumns = (
  onOpenConnectModal: () => void,
  setCurrentMerchantId: (id: number) => void,
  onBlockMerchant: (block: boolean, merchantInfo: IMerchantResponse) => void,
  onReCreateMSAFunction: (id: number) => void,
): ColumnsType<MerchantsTableColumns> => [
  {
    title: "Merchant id",
    dataIndex: "id",
    key: "id",
    width: 100,
    fixed: "left",
  },
  {
    title: "Merchant name",
    dataIndex: "merchantName",
    key: "merchantName",
    width: 200,
    fixed: "left",
  },
  {
    title: "Merchant number",
    dataIndex: "merchantNumber",
    key: "merchantNumber",
    width: 150,
    fixed: "left",
  },

  {
    title: "Email",
    dataIndex: ["merchantContactData", "email"],
    key: "email",
    width: 200,
    fixed: "left",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    width: 100,
    fixed: "left",
  },
  {
    title: "Actions",
    key: "actions",
    width: 100,
    fixed: "right",
    render: (data: IMerchantResponse) => {
      const handleMenuClick: MenuProps["onClick"] = (e) => {
        setCurrentMerchantId(data.id);
        e.domEvent.stopPropagation();
      };

      const items: MenuProps["items"] = [
        {
          label: (<span onClick={(event) => {
                          setCurrentMerchantId(data.id);
                          onOpenConnectModal();
                          event.stopPropagation()
                        }}
                  >
                  Connect with partner
                  </span>),
          key: "1",
        },
        {
          label: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Block user</span>
              <Switch
                defaultChecked={data.status === "SUSPENDED"}
                onClick={(checked, event) => {
                  onBlockMerchant(checked, data);
                  event.stopPropagation();
                }}
              />
            </div>
          ),
          key: "2",
        },
        {
          label: (<span onClick={(event) => {
                            onReCreateMSAFunction(data.id);
                            event.stopPropagation()
                            }}>
                  ReCreateMSA
                  </span>),
          key: "3",
        },
      ];

      const menuProps = {
        items,
        onClick: handleMenuClick,
      };

      return (
        <Dropdown menu={menuProps} trigger={["click"]}>
          <Button onClick={(event) => event.stopPropagation()}>...</Button>
        </Dropdown>
      );
    },
  },
];

// const getMerchantDataSource = (
//   data: IMerchantResponse[]
// ): MerchantsTableColumns[] => {
//   return data.map((item) => ({
//       key: item.id,
//       id: item.id,
//       merchantName: item.merchantName,
//       merchantNumber: item.merchantNumber,
//       email: item.email,
//       currency: item.currency,
//   }));
// };
