import { Helmet } from "react-helmet";
import { PageHeader } from "antd";
import React from "react";
import { Filters } from "./Filters";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { useGetDLQ } from "../../data-layer/system/hooks";
import { Table } from "./Table";
import { useDeleteDLQ } from "../../data-layer/system/hooks/useDeleteDLQ";

export const DLQSc: React.FC = () => {
  let [searchParams] = useSearchParams();
  const { changeSearchParams } = useChangeSearchParams();

  const limitFilter = searchParams.get("limit") || "";

  const { data, isLoading, dataWithoutLastItem, requestStatus, refetch } =
    useGetDLQ(searchParams.toString(), limitFilter);

  const { onDeleteDLQ } = useDeleteDLQ(refetch);

  const isAllowPagination = parseInt(limitFilter) === data.length;

  const handleLoadMoreDLQ = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };
  return (
    <>
      <Helmet>
        <title>DLQ</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="DLQ" />
      <Filters />
      <Table
        pagination={isAllowPagination}
        tableData={dataWithoutLastItem}
        isLoading={isLoading}
        requestStatus={requestStatus}
        handleLoadMoreDLQ={handleLoadMoreDLQ}
        onDeleteDLQ={onDeleteDLQ}
      />
    </>
  );
};
