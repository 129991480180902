import React from "react";
import { Styled } from "../styled";
import { Card, Col, DatePicker, Form, Input, Row } from "antd";
import { IPersonsOnboarding } from "../../../data-layer/application/types/applicationTypes";
import moment from "moment";
import { PhoneInput } from "../../../common/phoneInput";

interface IBusinessOwners {
  PERSONS: IPersonsOnboarding[];
  isDisableForm: boolean;
}

export const BusinessOwners: React.FC<IBusinessOwners> = ({ PERSONS, isDisableForm }) => {
  return (
    <>
      <Styled.SectionTitle>
        Business owners personal details
      </Styled.SectionTitle>
      {PERSONS.map((person, index) => {
        const arrayName = ["PERSONS", `${index}`];
        return (
          <Card
            title={`${person.PERSON_FIRST_NAME} ${person.PERSON_LAST_NAME}`}
          >
            <Row gutter={[110, 0]}>
              <Col span={12}>
                <Form.Item
                  name={[...arrayName, "PERSON_FIRST_NAME"]}
                  label={"First name"}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={"Date of birth"}
                  labelCol={{ span: 24 }}
                  name={[...arrayName, "PERSON_DOB"]}
                  getValueProps={(value: any) => ({
                    value: moment(value),
                  })}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} selected={moment()} />
                </Form.Item>
                <Form.Item
                  name={[...arrayName, "PERSON_EMAIL"]}
                  label={"Email"}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                  name={[...arrayName, "PERSON_PHONE_NUMBER"]}
                  label={"Phone number"}
                  labelCol={{ span: 24 }}
                >
                  {isDisableForm ? (<Input placeholder={"None"} />) : (<PhoneInput country={"gb"} />)}
                </Form.Item>
                <Form.Item
                  name={[...arrayName, "PERSON_ADDRESS_LINE_2"]}
                  label={"Address line 2"}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[...arrayName, "PERSON_POSTAL_CODE"]}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                  label={"Postcode"}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[...arrayName, "PERSON_OWNERSHIP"]}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                  label={"Company ownership (%)"}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={[...arrayName, "PERSON_LAST_NAME"]}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                  label={"Surname"}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[...arrayName, "PERSON_ADDRESS_LINE_1"]}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                  label={"Address line 1"}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[...arrayName, "PERSON_CITY"]}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                  label={"City/Town"}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name={[...arrayName, "PERSON_NATIONALITY"]}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                  label={"Nationality"}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[...arrayName, "PERSON_AUTHORIZED"]}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                  label={"Authorised Signatory"}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        );
      })}
    </>
  );
};
