import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../services/api/config";
import {
  IConnectMerchantsToPartnerRequest,
  IConnectMerchantsToPartnerResponse,
  IPartnerCreateRequest,
  IPartnerUpdateRequest,
  IPartnerResponse,
} from "../types/partnerTypes";

export const partnerApi = createApi({
  reducerPath: "partnerApi",
  baseQuery: baseQuery,
  tagTypes: ["PartnerList"],
  endpoints: (builder) => ({
    createPartner: builder.mutation<IPartnerResponse, IPartnerCreateRequest>({
      query: (credential) => ({
        url: `/partners`,
        body: credential,
        method: "POST",
      }),
      invalidatesTags: ["PartnerList"],
    }),
    updatePartner: builder.mutation<IPartnerResponse, IPartnerUpdateRequest>({
      query: (credential) => ({
        url: `/partners/admin`,
        body: credential,
        method: "PUT",
      }),
      invalidatesTags: (result) => (result ? ["PartnerList"] : []),
    }),
    connectMerchantsToPartner: builder.mutation<
      IConnectMerchantsToPartnerResponse,
      IConnectMerchantsToPartnerRequest
    >({
      query: (credential) => ({
        url: `/partners/connect-merchants`,
        body: credential,
        method: "POST",
      }),
    }),
    getPartners: builder.query<IPartnerResponse[], string>({
      query: (params) => ({
        url: `/partners/find?${params}`,
      }),
      providesTags: ["PartnerList"],
    }),
    findPartners: builder.mutation<IPartnerResponse[], string>({
      query: (params) => ({
        url: `/partners/find?${params}&limit=20&offset=0`,
      }),
    }),
  }),
});
export const {
  useCreatePartnerMutation,
  useFindPartnersMutation,
  useGetPartnersQuery,
  useConnectMerchantsToPartnerMutation,
  useUpdatePartnerMutation,
} = partnerApi;
