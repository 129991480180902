import { createApi } from "@reduxjs/toolkit/query/react";
import { IProfile } from "../types";
import { baseQueryInterceptor } from "../../../services/api/config";

// Define a service using a base URL and expected endpoints
export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getProfiles: builder.query<IProfile[], string>({
      query: (params) => `/profile/admin?${params}`,
    }),
    // updateProfile: builder.mutation({
    //   query: (credentials) => ({
    //     url: `/profile/${credentials.id}`,
    //     method: "POST",
    //     body: credentials.body,
    //   }),
    // }),
    getProfilesById: builder.query<IProfile, string>({
      query: (id) => `/profile/${id}`,
    }),
  }),
});
export const { useGetProfilesQuery, useGetProfilesByIdQuery } = profileApi;
