import { useResendOTPMutation } from "../api/authApi";
import { notification } from "antd";

export const useResendOTP = () => {
    const [resendOTP] = useResendOTPMutation();

    const onResendOTP = (email: string) => {
        resendOTP(email).then((result) => {
            if ("data" in result) {
                notification.open({
                    message:
                        `The code was sent to the ${email}`,
                    type: "success",
                });
            } else if (result.error) {
                notification.open({
                    message: "Something went wrong",
                    description: result.error?.data?.error,
                    type: "error",
                });
            }
        });
    };

    return {
        onResendOTP,
    };
};
