import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 570px;
  margin: 0 auto;
  color: #09243a;
`;

const FormLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #80868b;
`;

export const Styled = {
  Wrapper,
  FormLabel,
};
