import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../services/api/config";
import { IPostDocumentsRequest, IDocumentsResponse } from "../types/IDocuments";

// Define a service using a base URL and expected endpoints
export const documentRequestApi = createApi({
  reducerPath: "documentRequestApi",
  baseQuery,
  endpoints: (builder) => ({
    documentRequest: builder.mutation<
      IDocumentsResponse,
      IPostDocumentsRequest
    >({
      query: (credentials) => ({
        url: `/document-request`,
        method: "POST",
        body: credentials,
      }),
    }),
    documentGetForProfileRequest: builder.query<IDocumentsResponse, string>({
      query: (params) => `/document-request/${params}`,
    }),
  }),
});
export const {
  useDocumentRequestMutation,
  useDocumentGetForProfileRequestQuery,
} = documentRequestApi;
