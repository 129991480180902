import React from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Modal,
  Select,
  Divider,
  FormInstance,
} from "antd";
import {
  IDictionaryMccResponse,
  IDictionaryResponse,
} from "../../../data-layer/dictionary/types/IDictionary";
import { Styled } from "./styles";
import { IApplicationResponseData } from "data-layer/application/types/applicationTypes";
import { Currency, } from "../PricingModels/types";
import { isNotValidPricingModelCurrency } from "../PricingModels/utils";
import { InStorePricingModel } from "../PricingModels/inStoreModel";
import { OnlinePricingModel } from "../PricingModels/onlineModel";
import { OnlineGlobalPricingModel } from "../PricingModels/onlineGlobalModel";

interface IApproveModal {
  dictionaryData: IDictionaryResponse;
  isVisibleModal: boolean;
  setIsVisibleModal: (state: boolean) => void;
  dictionaryMccData: IDictionaryMccResponse;
  onSubmit: () => void;
  approveLoading: boolean;
  form: FormInstance<any>;
  data: IApplicationResponseData;
  onChangeData: (data: IApplicationResponseData | null) => void
}

export const ApproveModal: React.FC<IApproveModal> = ({
  dictionaryData,
  dictionaryMccData,
  setIsVisibleModal,
  isVisibleModal,
  onSubmit,
  approveLoading,
  form,
  data,
  onChangeData
}) => {
  const { Option } = Select;
  const filterOption = (input: string, option: any) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Modal
      open={isVisibleModal}
      footer={null}
      onCancel={() => setIsVisibleModal(false)}
      title="Approval"
      width={1000}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Divider orientation="center" plain>
            General
          </Divider>
          <Form.Item
            label={"MCC Code"}
            labelCol={{ span: 24 }}
            name="MCC"
            rules={[
              {
                required: true,
                message: "Please input MCC Code",
              },
            ]}
          >
            <Select showSearch filterOption={filterOption} placeholder={"None"}>
              {[...new Set(dictionaryMccData?.mccList)].map((item, i) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"Currency"}
            labelCol={{ span: 24 }}
            name="CURRENCY"
            rules={[
              {
                required: true,
                message: "Please input currency",
              },
            ]}
          >
            <Select showSearch filterOption={filterOption} placeholder={"None"}>
              {dictionaryData.currencies.map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"Time zone"}
            labelCol={{ span: 24 }}
            name="TIME_ZONE"
            rules={[
              {
                required: true,
                message: "Please input time zone",
              },
            ]}
          >
            <Select showSearch filterOption={filterOption} placeholder={"None"}>
              {dictionaryData.timeZones.map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
          {(data?.PM_ONLINE || data?.PM_ONLINE_GLOBAL) && (
            <Form.Item
              label={"Fiserv ID"}
              labelCol={{ span: 24 }}
              name={"FISERV_ID"}
              rules={[
                {
                  required: true,
                  message: "Please input fiserv id",
                },
              ]}
            >
              <Input placeholder={"None"} max={100} />
            </Form.Item>
          )}
          <Form.Item
            label="Pricing Model Currency"
            labelCol={{ span: 24 }}
            name="PM_CURRENCY"
            rules={[
              {
                required: true,
                message: "Please select currency",
              },
              ({ getFieldValue }) => ({
                validator() {
                  const selectedCurrency = getFieldValue("PM_CURRENCY");
                  if (isNotValidPricingModelCurrency(selectedCurrency)) {
                    return Promise.reject(new Error("Invalid currency"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select>
              <Option key={Currency.GBP} value={Currency.GBP} defaultChecked>
                UK Pounds (GBP)
              </Option>
              <Option key={Currency.EUR} value={Currency.EUR}>
                Euro (EUR)
              </Option>
              <Option key={Currency.USD} value={Currency.USD}>
                US Dollars (USD)
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <InStorePricingModel form={form} data={data} onChangeData={onChangeData} />

          <OnlinePricingModel form={form} data={data} onChangeData={onChangeData} />
          
          <OnlineGlobalPricingModel form={form} data={data} onChangeData={onChangeData} />
        </Col>
      </Row>

      <Styled.ModalButtonWrapper>
        <Styled.CancelButton onClick={() => setIsVisibleModal(false)}>
          Cancel
        </Styled.CancelButton>
        <Styled.ApproveButton
          onClick={onSubmit}
          loading={approveLoading}
          disabled={approveLoading}
        >
          Approve and send email with commercial
        </Styled.ApproveButton>
      </Styled.ModalButtonWrapper>
    </Modal>
  );
};
