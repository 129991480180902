import { useSetNewPasswordMutation } from "../api/authApi";
import { IUserResponse } from "../../user/types/IUser";
import { notification } from "antd";

export const useSetNewPassword = () => {
  const [setNewPassword] = useSetNewPasswordMutation();

  const onSetNewPassword = (
    userInfo: IUserResponse,
    newPassword: string,
    clearForm: (fields?: (string | number)[] | undefined) => void
  ) => {
    const params = {
      userId: userInfo.id,
      password: newPassword,
    };

    setNewPassword(params).then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Password changed successfully",
          type: "success",
        });
        clearForm();
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return { onSetNewPassword };
};
