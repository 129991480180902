import styled from "styled-components";
import { Button } from "antd";

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const ApproveButton = styled(Button)`
  background: rgba(82, 196, 26, 0.2);
  border: 1px solid #52c41a;
  width: 310px;
  height: 32px;
  font-size: 14px;
`;

const CancelButton = styled(Button)`
  border: 1px solid #d9d9d9;
  width: 78px;
  height: 32px;
  margin-right: 10px;
  font-size: 14px;
`;

export const Styled = {
  ModalButtonWrapper,
  ApproveButton,
  CancelButton,
};
