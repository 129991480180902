import { Helmet } from "react-helmet";
import { PageHeader, Spin, Form as AntForm, notification } from "antd";
import { history } from "../../routes/AppRouter";
import { Styled } from "./styled";
import { Form } from "./form";
import { IMerchantDetail, MerchantCreateErrorResponse } from "./types";
import { useDictionary } from "../../data-layer/dictionary/hooks";
import { useDictionaryMcc } from "../../data-layer/dictionary/hooks/useDictionaryMcc";
import { useCreateMerchant } from "../../data-layer/merchant/hooks/useCreateMerchant";
import { usePartners } from "../../data-layer/partner/hooks/usePartners";
import { merchantSchema } from "../../common/merchantForm";

export const MerchantCreate: React.FC = () => {
  const { createMerchant } = useCreateMerchant();
  const { dictionaryData } = useDictionary();
  const { dictionaryMccData } = useDictionaryMcc();
  const partners = usePartners("name=&limit=1000&offset=0", []);

  const [form] = AntForm.useForm<IMerchantDetail>();

  const onCreateMerchant = async (data: IMerchantDetail) => {
    const result = await createMerchant({
      ...data,
      settlementDetailsData: data.settlementDetailsData && Object.values(data.settlementDetailsData).some((val) => val) ? data.settlementDetailsData : null,
    });
    if ("data" in result) {
      notification.open({
        message: "Request sent successfully",
        description: `Merchant is created with ID[${result.data}]`,
        type: "success",
      });
      history.back();
    } else if ("error" in result) {
      const error = result.error as MerchantCreateErrorResponse;
      console.error("ERROR: ", error);
      const keys: string[] = Object.keys(error.data.details);
      const values: string[] = Object.values(error.data.details);
      
      let description = `${error.data.error}.`;
      keys.forEach((key, index) => description += ` ${key}: ${values[index]}`)

      notification.open({
        message: "Something went wrong",
        description,
        type: "error",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Merchant Create</title>
      </Helmet>
      <Styled.HeaderWrapper>
        <PageHeader onBack={() => history.back()} title="Merchant Create" />
      </Styled.HeaderWrapper>
      {dictionaryData && dictionaryMccData && partners.isSuccess ? (
        <Styled.FormWrapper>
          <Form
            type="create"
            form={form}
            merchantSchema={merchantSchema}
            dictionaryData={dictionaryData}
            dictionaryMccData={dictionaryMccData}
            partners={partners.data}
            onProcessMerchant={onCreateMerchant}
          />
        </Styled.FormWrapper>
      ) : (
        <div
          style={{
            display: "flex",
            height: "90%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
};
