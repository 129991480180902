import React, { useState } from "react";
import { Filters } from "./Filters";
import { useSearchParams } from "react-router-dom";
import { PageHeader } from "antd";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { Helmet } from "react-helmet";
import { useFindTerminals } from "../../data-layer/terminals/hooks/useFindTerminals";
import { Table } from "./Table";

export const TerminalsSC: React.FC = () => {
  let [searchParams] = useSearchParams();

  const { changeSearchParams } = useChangeSearchParams();

  const limitFilter = searchParams.get("limit") || "";

  const handleLoadMoreTerminals = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };

  const { terminalsData, dataWithoutLastItem, isLoading, requestStatus } =
    useFindTerminals(searchParams.toString(), limitFilter);

  const isAllowPagination = parseInt(limitFilter) === terminalsData.length;

  return (
    <>
      <Helmet>
        <title>Terminals</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="Terminals" />
      <Filters />
      <Table
        isAllowPagination={isAllowPagination}
        tableData={dataWithoutLastItem}
        isLoading={isLoading}
        requestStatus={requestStatus}
        handleLoadMoreTerminals={handleLoadMoreTerminals}
      />
    </>
  );
};
