import { useFindTerminalsQuery } from "../api/terminalApi";

export const useFindTerminals = (searchParams: string, limitFilter: string) => {
  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess } = useFindTerminalsQuery(
    `${searchParams}`,
    {
      skip: skipRequest,
    }
  );

  const terminalsData = data ? data : [];

  let dataWithoutLastItem = [...terminalsData];
  limitFilter && dataWithoutLastItem.splice(parseInt(limitFilter) - 1, 1);

  return {
    terminalsData,
    dataWithoutLastItem,
    data,
    error,
    isLoading,
    isSuccess,
    requestStatus: status,
  };
};
