import styled from "styled-components";
import { Table as AntDTable } from "antd";

export const Table = styled(AntDTable)`
  ${(props) => {
    const onRowImplementation = props.onRow && props.onRow({});
    if (onRowImplementation?.onClick) {
      return `
        tbody tr:hover {
          cursor: pointer;
        }
        `;
    }
  }}
`;

export const Styled = {
  Table,
};
