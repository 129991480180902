import { DatePicker } from "antd";
import React from "react";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  FIRST_DATE_MOMENT_FORMAT,
  LAST_DATE_MOMENT_FORMAT,
} from "../../common/constants/constants";

interface IMerchantFindInput {
  onSelect?: () => void;
  disabled?: boolean;
}

export const RangePickerFilter: React.FC<IMerchantFindInput> = ({
  onSelect,
  disabled,
}) => {
  const { changeSearchParams } = useChangeSearchParams();

  let [searchParams] = useSearchParams();

  const { RangePicker } = DatePicker;

  const dateFromFilter = searchParams.get("from");
  const dateToFilter = searchParams.get("to");

  const rangeFilter = [
    dateFromFilter && dayjs(dateFromFilter).utc(),
    dateToFilter && dayjs(dateToFilter).utc(),
  ];

  const handleDateChange = (dateRange: any) => {
    console.log(dateRange === null);
    if (dateRange === null) {
      changeSearchParams({
        from: "",
        to: "",
      });
    } else {
      changeSearchParams({
        from: dateRange[0].utc().format(FIRST_DATE_MOMENT_FORMAT),
        to: dateRange[1].utc().format(LAST_DATE_MOMENT_FORMAT),
      });
    }
  };

  return (
    <RangePicker
      format={"DD MMM"}
      onChange={handleDateChange}
      // todo fix
      //@ts-ignore
      value={rangeFilter}
    />
  );
};
