import { useGetDictionaryQuery } from "../api/dictionaryApi";
import { IDictionaryResponse } from "../types/IDictionary";

export const useDictionary = () => {
  //todo убрать аргумент
  const { data, error, isLoading, isSuccess } = useGetDictionaryQuery("");

  return {
    dictionaryData: data as IDictionaryResponse,
    expectedTurnover,
    paymentHistory,
    averageTxValueMap,
    error,
    isLoading,
    isSuccess,
  };
};

const expectedTurnover = [
  { id: "SMALL", name: "£5,000 - £9,999" },
  { id: "MIDDLE", name: "£10,000 - £24,999" },
  { id: "LARGE", name: "£25,000 - 49,999" },
  { id: "EXTRA_LARGE", name: "50,000+" },
];

const averageTxValueMap = [
  { id: "SMALL", name: "Less than £50" },
  { id: "MIDDLE", name: "£50 - £500" },
  { id: "LARGE", name: "£500 +" },
];

const paymentHistory = [
  { id: "SMALL", name: "Less 6 month" },
  { id: "MIDDLE", name: "6 months - 1 year" },
  { id: "LARGE", name: "2-3 years" },
  { id: "NONE", name: "N/A" },
];
