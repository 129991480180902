import styled, { CSSProp } from "styled-components";

interface IFlex {
  alignContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "stretch"
    | "initial"
    | "inherit";
  alignItems?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "stretch"
    | "baseline"
    | "initial"
    | "inherit";
  alignSelf?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "stretch"
    | "baseline"
    | "auto"
    | "initial"
    | "inherit";
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "initial"
    | "inherit";
  basis?:
    | "none"
    | "auto"
    | "fill"
    | "content"
    | "fit-content"
    | "min-content"
    | "max-content";
  direction?: "row" | "column";
  gap?: number;
  width?: string;
  css?: CSSProp;
}

export const Flex = styled.div<IFlex>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.alignItems};
  align-self: ${(props) => props.alignSelf};
  align-content: ${(props) => props.alignContent};
  gap: ${(props) => props.gap}px;
  flex-basis: ${(props) => props.basis};
  width: ${(props) => props.width};
  ${(props) => props.css}
`;
