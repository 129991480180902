import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";

export const Filters: React.FC = () => {
  let [searchParams] = useSearchParams();

  const { changeSearchParams, setInitialParams } = useChangeSearchParams();

  const initialFilters = {
    limit: "11",
    offset: "0",
  };

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [searchParams]);

  return <></>;
};
