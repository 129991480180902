import {
  Drawer,
  Descriptions,
  Space,
  Button,
  List,
  Typography,
  Row,
  Col,
  Divider,
} from "antd";
import { IMerchantDetail } from "../../scenarios/merchant/types";

interface IMerchantDetailDrawer {
  open: boolean;
  onClose: () => void;
  data: IMerchantDetail | null;
  onEditMerchant: (id?: string) => void;
}

export const MerchantDetailDrawer: React.FC<IMerchantDetailDrawer> = ({
  open,
  data,
  onClose,
  onEditMerchant,
}) => {
  return (
    <Drawer
      title={`${data?.merchantName} Details`}
      placement={"right"}
      onClose={onClose}
      open={open}
      width="50%"
      headerStyle={{ border: "none" }}
      extra={
        <Space>
          <Button type="primary" onClick={() => onEditMerchant(data?.id)}>
            Edit
          </Button>
        </Space>
      }
    >
      <Space>
        <Descriptions
          title={
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              General Info
              <Divider style={{ marginTop: 10, marginBottom: 0 }} />
            </Typography.Title>
          }
          size="middle"
          style={{ marginBottom: 25 }}
          contentStyle={{ color: "rgba(0, 0, 0, 0.45)" }}
          labelStyle={{ color: "rgba(0, 0, 0, 0.45)" }}
        >
          <Descriptions.Item label="Number">
            {data?.merchantNumber}
          </Descriptions.Item>
          <Descriptions.Item label="DBA">{data?.dba}</Descriptions.Item>
          <Descriptions.Item label="Status">{data?.status}</Descriptions.Item>
          <Descriptions.Item label="Country">
            {data?.country ? data.country : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Currency">
            {data?.currency}
          </Descriptions.Item>
          <Descriptions.Item label="TimeZone">
            {data?.timeZone}
          </Descriptions.Item>
          <Descriptions.Item label="Kind">
            {data?.kind.replace("_", " ")}
          </Descriptions.Item>
          <Descriptions.Item label="Type">{data?.originType}</Descriptions.Item>
          <Descriptions.Item label="Fee">
            {data?.feePercent ? <>{data?.feePercent}%</> : <>N/A</>}
          </Descriptions.Item>
          <Descriptions.Item label="MCC">{data?.mcc}</Descriptions.Item>
          <Descriptions.Item label="TidPrefix">
            {data?.tidPrefix}
          </Descriptions.Item>
          <Descriptions.Item label="MID">{data?.mid}</Descriptions.Item>
          <Descriptions.Item label="CCTExternalID">
            {data?.cctExternalId}
          </Descriptions.Item>
          <Descriptions.Item label="FSRVExternalID">
            {data?.fsrvExternalId || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="CDXExternalID">
            {data?.cdxExternalId}
          </Descriptions.Item>
          <Descriptions.Item label="VatID">{data?.vatID}</Descriptions.Item>
          <Descriptions.Item label="TotalSales">
            {data?.onboardingDetailsTotalSales}
          </Descriptions.Item>
          <Descriptions.Item label="Partner">
            {data?.partner ? (
              <>
                {data?.partner?.name} {data?.partner?.feePercent || 0}%
              </>
            ) : (
              <>N/A</>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Space>

      <Space>
        <Descriptions
          title={
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              Settlement Details
              <Divider style={{ marginTop: 10, marginBottom: 0 }} />
            </Typography.Title>
          }
          size="small"
          style={{ marginBottom: 25 }}
          contentStyle={{ color: "rgba(0, 0, 0, 0.45)" }}
          labelStyle={{ color: "rgba(0, 0, 0, 0.45)" }}
        >
          <Descriptions.Item label="SortCode">
            {data?.settlementDetailsData?.sortCode || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="AccountNumber">
            {data?.settlementDetailsData?.bankAccountNumber || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Name">
            {data?.settlementDetailsData?.bankAccountHolderName || "N/A"}
          </Descriptions.Item>
        </Descriptions>
      </Space>

      <Row gutter={24}>
        {data?.registrationAddressData && (
          <Col span={12}>
            <List
              size={"small"}
              style={{ marginBottom: 25 }}
              itemLayout="horizontal"
              header={
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Registration Address
                </Typography.Title>
              }
              dataSource={[data?.registrationAddressData]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    description={
                      <div>
                        {item.addressLine1}, {item.postTown}, {item.city},{" "}
                        {item.postCode}, {item.state}, {item.country}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        )}
        {data?.officeAddressData && (
          <Col span={12}>
            <List
              size={"small"}
              style={{ marginBottom: 25 }}
              itemLayout="horizontal"
              header={
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Office Address
                </Typography.Title>
              }
              dataSource={[data?.officeAddressData]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    description={
                      <div>
                        {item.addressLine1}, {item.postTown}, {item.city},{" "}
                        {item.postCode}, {item.state}, {item.country}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        )}
        {data?.merchantFiservData && (
          <Col span={12}>
            <List
              size={"small"}
              style={{ marginBottom: 25 }}
              itemLayout="horizontal"
              header={
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Fiserv Details
                </Typography.Title>
              }
              dataSource={[data?.merchantFiservData]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    description={
                      <>
                        <div>Status: {item.status}</div>
                        <div>
                          Decline Reason Code: {item.resultDeclineReasonCode}
                        </div>
                        <div>GNF Status: {item.resultGNFStatus}</div>
                        <div>MC Match Status: {item.resultMCMatchStatus}</div>
                        <div>RNF Status: {item.resultRNFStatus}</div>
                        <div>VMAS Status: {item.resultVMASStatus}</div>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        )}
        {data?.merchantContactData && (
          <Col span={12}>
            <List
              size={"small"}
              style={{ marginBottom: 25 }}
              itemLayout="horizontal"
              header={
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Contacts
                </Typography.Title>
              }
              dataSource={[data?.merchantContactData]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    description={
                      <>
                        <div>Email: {item.email}</div>
                        <div>Phone: {item.phoneNumber}</div>
                        <div>Website: {item.siteUrl}</div>
                        <div>
                          Primary Contact Name: {item.primaryContactFirstName}{" "}
                          {item.primaryContactLastName}
                        </div>
                        <div>
                          Primary Contact Phone:{" "}
                          {item.primaryContactPhoneNumber}
                        </div>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        )}
        {data?.merchantPrincipalData &&
          data?.merchantPrincipalData.principals.length && (
            <Col span={12}>
              <List
                size={"small"}
                style={{ marginBottom: 15 }}
                header={
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    Principals
                  </Typography.Title>
                }
                itemLayout="vertical"
                dataSource={data?.merchantPrincipalData.principals}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={`${item.firstName} ${item.lastName} (${item.ownership}%)`}
                      description={
                        <>
                          <div>Email: {item.email}</div>
                          <div>Phone: {item.phoneNumber}</div>
                          <div>
                            Address: {item.addressLine1}, {item.city},{" "}
                            {item.postCode}
                          </div>
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            </Col>
          )}
      </Row>
    </Drawer>
  );
};
