import React from "react";
import {
  Form,
  Modal,
  Button,
  Space,
  Input,
  Form as AntForm,
  Select,
} from "antd";
import { LOCALE_MAP } from "../../common/constants/constants";

interface ICreateFormProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSubmit: (
    data: any,
    clearForm: (fields?: (string | number)[] | undefined) => void
  ) => void;
}

export const CreateLocaleModal: React.FC<ICreateFormProps> = ({
  visible,
  setVisible,
  onSubmit,
}) => {
  const [form] = AntForm.useForm();

  const { Option } = Select;

  return (
    <Modal
      open={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      title="Create locale"
    >
      <Form
        onFinish={(data) => {
          onSubmit(data, form.resetFields);
          setVisible(false);
        }}
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Id"
          name="id"
          rules={[{ required: true, message: "Please input id" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Locale"
          name="locale"
          rules={[{ required: true, message: "Please input locale" }]}
        >
          <Select placeholder={"None"}>
            {LOCALE_MAP.map((item) => (
              <Option key={item.value}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Value"
          name="value"
          rules={[{ required: true, message: "Please input fee value" }]}
        >
          <Input />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Space>
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button type="primary" htmlType={"submit"}>
              Create
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};
