import { useRef, useState } from "react";
import { Button, Form as AntForm, Tabs } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { MerchantDetailsTab } from "./details";
import { MerchantContactsTab } from "./contacts";
import { MerchantSettlementsTab } from "./merchantSettlements";
import { MerchantRegistrationDetailsTab } from "./registrationDetails";
import { IMerchantForm, IMerchantDetail } from "./types";
import { MerchantOfficeDetailsTab } from "./officeDetails";
import { MerchantPrincipalDetailsTab } from "./principalDetails";
import { Styled } from "./styled";
import { yupValidator } from "../../common/merchantForm";
import "./style.css";
import { MerchantIntegrationDetailsTab } from "./integrationDetails";
import {MerchantPricingModelsTab} from "./pricingModels";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

type TabsItem = {
  label: string;
  children: JSX.Element;
  key: string;
  closable?: boolean;
  disabled?: boolean;
};

const DEFAULT_INITIAL_ITEM_KEY = "details";

export const Form: React.FC<IMerchantForm> = ({
  type,
  form,
  merchantSchema,
  dictionaryData,
  dictionaryMccData,
  partners,
  onProcessMerchant,
  initialData,
}) => {
  const principalsCount =
    initialData?.merchantPrincipalData?.principals.length || 1;

  const [isButtonEnabled, setButtonState] = useState<boolean>(type === "edit");
  const [isSameAddress, setSameAddress] = useState<boolean>(false);

  const [activeKey, setActiveKey] = useState(DEFAULT_INITIAL_ITEM_KEY);
  const newPrincipalIndex = useRef(principalsCount);

  const merchantValidator = yupValidator(
    merchantSchema,
    form.getFieldsValue,
    setButtonState,
    type
  );

  const tabItems: TabsItem[] = [
    {
      label: "Details",
      children: (
        <MerchantDetailsTab
          countries={dictionaryData.countries}
          timezones={dictionaryData.timeZones}
          currencies={dictionaryData.currencies}
          mccList={dictionaryMccData.mccList}
          partners={partners}
          initialData={initialData}
          validator={merchantValidator}
        />
      ),
      key: "details",
      closable: false,
    },
    {
      label: "Integration Details",
      children: (
        <MerchantIntegrationDetailsTab
          validator={merchantValidator}
          initialData={initialData}
        />
      ),
      key: "integrationDetails",
      closable: false,
    },
    {
      label: "Registration Details",
      children: (
        <MerchantRegistrationDetailsTab
          type={type}
          countries={dictionaryData.countries}
          validator={merchantValidator}
          isAddressSameAsRegistration={isSameAddress}
          setSameRegistrationAddress={setSameAddress}
          initialData={initialData}
        />
      ),
      key: "registrationAddress",
      closable: false,
    },
    {
      label: "Office Details",
      children: (
        <MerchantOfficeDetailsTab
          countries={dictionaryData.countries}
          validator={merchantValidator}
          isAddressSameAsRegistration={false}
          initialData={initialData}
        />
      ),
      key: "officeAddress",
      closable: false,
    },
    {
      label: "Contacts",
      children: (
        <MerchantContactsTab
          validator={merchantValidator}
          initialData={initialData}
        />
      ),
      key: "contacts",
      closable: false,
    },
    {
      label: "Merchant Settlements",
      children: (
        <MerchantSettlementsTab
          validator={merchantValidator}
          initialData={initialData}
        />
      ),
      key: "settlement",
      closable: false,
    },
    {
      label: "Merchant Pricing Models",
      children: (
          <MerchantPricingModelsTab
              validator={merchantValidator}
              initialData={initialData}
          />
      ),
      key: "pricingModels",
      closable: false,
    },
    {
      label: "Principal Details 1",
      children: (
        <MerchantPrincipalDetailsTab
          countries={dictionaryData.countries}
          validator={merchantValidator}
          initialData={initialData}
          index={0}
        />
      ),
      key: "principals0",
      closable: false,
    },
  ];

  initialData?.merchantPrincipalData?.principals.forEach((_, index) => {
    if (index > 0) {
      tabItems.push({
        label: `Principal Details ${index + 1}`,
        children: (
          <MerchantPrincipalDetailsTab
            countries={dictionaryData.countries}
            validator={merchantValidator}
            initialData={initialData}
            index={index}
          />
        ),
        key: `principals${tabItems.length + index}`,
        closable: false,
      });
    }
  });

  const [items, setItems] = useState(tabItems);

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const add = () => {
    const newActiveKey = `principals${newPrincipalIndex.current}`;
    const newPanes: TabsItem[] = items.map((item) => {
      return {
        ...item,
        closable: false,
      };
    });

    newPanes.push({
      label: `Principal Details ${newPrincipalIndex.current + 1}`,
      children: (
        <MerchantPrincipalDetailsTab
          countries={dictionaryData.countries}
          validator={merchantValidator}
          initialData={initialData}
          index={newPrincipalIndex.current}
        />
      ),
      key: newActiveKey,
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
    newPrincipalIndex.current++;
  };

  const remove = (targetKey: TargetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });

    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length > tabItems.length) {
      newPanes[lastIndex].closable = true;
    }

    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
    newPrincipalIndex.current--;
  };

  const onEdit = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: "add" | "remove"
  ) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };

  const onFormSubmit = (data: IMerchantDetail) => {
    if (isSameAddress && type === "create") {
      data.officeAddressData = data.registrationAddressData;
    }

    onProcessMerchant(data);
  };

  return (
    <>
      <AntForm
        form={form}
        layout="vertical"
        scrollToFirstError={true}
        onFinish={(values) => onFormSubmit(values)}
        className="merchant-form"
      >
        <Styled.ButtonWrapper>
          <Button
            type="primary"
            htmlType={"submit"}
            size="large"
            icon={<SaveOutlined />}
            disabled={!isButtonEnabled}
          >
            save
          </Button>
        </Styled.ButtonWrapper>
        <Tabs
          type="editable-card"
          tabPosition="left"
          onChange={onChange}
          activeKey={activeKey}
          onEdit={onEdit}
          items={items.map((item) => {
            return {
              ...item,
              disabled: item.key === "officeAddress" && isSameAddress,
            };
          })}
          hideAdd={items.length >= 11}
        />
      </AntForm>
    </>
  );
};
