import React, { useMemo } from "react";
import {
  Button,
  Dropdown,
  Menu,
  Select,
  Switch,
  TablePaginationConfig,
} from "antd";
import { Styled as TableStyled } from "shared/style";
import { IRoles, IUserResponse } from "../../data-layer/user/types/IUser";
import moment from "moment";
import { SorterResult } from "antd/es/table/interface";
import { useSearchParams } from "react-router-dom";
import { usePasswordRecovery } from "../../data-layer/auth/hooks/usePasswordRecovery";
import { SearchTableFilter } from "../../components/SearchTableFilter";
import { SearchOutlined } from "@ant-design/icons";

interface ITableProps {
  tableData: IUserResponse[];
  isLoading: boolean;
  requestStatus: string;
  pagination: boolean;
  handleLoadMoreUsers: () => void;
  roles: IRoles[];
  addUserRole: any;
  deleteUserRole: any;
  handleSortColumn: (sortColumn: string, sortDirection: string) => void;
  setVisibleCreatePasswordModal: (visible: boolean) => void;
  setCurrentUserInfo: (data: IUserResponse) => void;
  onBlockUser: (block: boolean, data: IUserResponse) => void;
}

export const Table: React.FC<ITableProps> = ({
  tableData,
  isLoading,
  requestStatus,
  pagination,
  handleLoadMoreUsers,
  roles,
  addUserRole,
  deleteUserRole,
  handleSortColumn,
  setVisibleCreatePasswordModal,
  setCurrentUserInfo,
  onBlockUser,
}) => {
  let [searchParams] = useSearchParams();
  const { onPasswordRecovery } = usePasswordRecovery();

  const sortFilter = searchParams.get("orderBy[0].field");
  const sortDirectionFilter = searchParams.get("orderBy[0].direction");

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by name"}
            filterName={"name"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },

    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by surname"}
            filterName={"surname"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filterDropdown: ({ confirm, close }) => (
        <div style={{ padding: 8, width: 250 }}>
          <SearchTableFilter
            placeholder={"Search by email"}
            filterName={"email"}
            onSearch={() => {
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      defaultSortOrder: "descend",
      sorter: true,
      render: (item: string) => moment(item).format("DD MMM, YYYY"),
    },
    {
      title: "Roles",
      key: "roles",
      width: 500,
      render: (data: IUserResponse) => {
        return (
          <Select
            key={data.id}
            mode="multiple"
            placeholder="Roles"
            style={{ width: "100%" }}
            defaultValue={data.defaultSelectValue}
            onSelect={(value) =>
              addUserRole({ roleId: value, userId: data.id })
            }
            onDeselect={(value) =>
              deleteUserRole({ roleId: value, userId: data.id })
            }
          >
            {roles.map((role) => {
              return (
                <Select.Option key={role.id} value={role.id} title={role.name}>
                  {role.name}
                </Select.Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 89,
      render: (data: IUserResponse) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => onPasswordRecovery(data?.email)}>
                Reset password
              </Menu.Item>
              <Menu.Item onClick={() => setVisibleCreatePasswordModal(true)}>
                Create new password
              </Menu.Item>
              <Menu.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // width: 145,
                  }}
                >
                  <span>Block user</span>
                  <Switch
                    defaultChecked={data.status !== "ACTIVATED"}
                    onClick={(checked, event) => {
                      onBlockUser(checked, data);
                      event.stopPropagation();
                    }}
                  />
                </div>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          key={data.id}
        >
          <Button onClick={() => setCurrentUserInfo(data)}>...</Button>
        </Dropdown>
      ),
    },
  ];

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, any>,
    sorter: SorterResult<any>
  ) => {
    const { field, order } = sorter;
    // @ts-ignore
    handleSortColumn(field, order);
  };

  const columnsWithSort = useMemo(
    () =>
      setDefaultColumnsSort(
        sortFilter || "",
        sortDirectionFilter || "",
        columns
      ),
    [roles]
  );

  return (
    <>
      <TableStyled.Table
        loading={requestStatus === "pending" || isLoading}
        columns={columnsWithSort}
        dataSource={tableData}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
        onChange={handleTableChange}
      />
      {!isLoading && tableData.length > 0 && pagination && (
        <Button
          onClick={handleLoadMoreUsers}
          loading={requestStatus === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more users
        </Button>
      )}
    </>
  );
};

const setDefaultColumnsSort = (
  sortField: string,
  sortDirection: string,
  columns: any
) => {
  return columns.map((item) => {
    if (item.key === sortField) {
      return {
        ...item,
        defaultSortOrder: sortDirection === "ASC" ? "ascend" : "descend",
      };
    }
    return item;
  });
};
