import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";
import { ICrmResponse } from "../types";

export const crmApi = createApi({
  reducerPath: "crmApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getCrm: builder.query<ICrmResponse[], string>({
      query: (params) => `/crm?${params}`,
    }),
  }),
});
export const { useGetCrmQuery } = crmApi;
