import { history } from "../../../routes/AppRouter";
import { notification } from "antd";
import { notificationsConstants } from "../../../common/constants/notifications";

export const useLogout = () => {
  const logout = () => {
    localStorage.removeItem("authToken");
    history.push("/signin");
  };

  const sessionLogout = () => {
    logout();
    notification.open({
      message: "Session time expired",
      description: "Your session time expired, please log in again",
      type: "info",
      duration: 0,
      key: notificationsConstants.SESSION_EXPIRED_LOGOUT,
    });
  };

  return {
    logout,
    sessionLogout,
  };
};
