import { Fee } from "../../../pages/onboardingDetails/PricingModels/types";
import {
  usePostApplicationReviewMutation,
  usePostApplicationPersistMutation,
} from "../api/applicationApi";
import {
  IApplicationResponseData,
  IApplicationReviewRequest,
} from "../types/applicationTypes";
import { notification } from "antd";

export const useApplicationReview = (
  setIsDisableForm: (disable: boolean) => void,
  profileRefetch: () => void,
  applicationRefetch: () => void
) => {
  const [applicationPersistPost, { isLoading: isPersistLoading }] =
    usePostApplicationPersistMutation();
  const [applicationReviewPost, { isLoading: isReviewLoading }] =
    usePostApplicationReviewMutation();

  const approveLoading = Boolean(isPersistLoading) || Boolean(isReviewLoading);
  const rejectLoading = Boolean(isReviewLoading);
  const persistLoading = Boolean(isPersistLoading);

  const applicationApproveRequest = (
    fields: any,
    id: number,
    data: IApplicationResponseData
  ) => {
    const validFields = fields.hasOwnProperty("PERSONS")
      ? objectToArray(fields, "PERSONS")
      : fields;

    const persistParams = {
      id,
      version: 0,
      fields: mergeFormData(validFields, data),
    };

    const reviewParams = {
      approve: true,
      applicationId: id,
    };

    const persist = collectPricingModel(persistParams);
    applicationPersistPost(persist).then((result) => {
      if ("data" in result) {
        applicationRefetch();
        applicationReviewPost(reviewParams).then((info) => {
          if ("data" in info) {
            notification.open({
              message: "Status changed successfully",
              type: "success",
            });
            setIsDisableForm(true);
            profileRefetch();
          } else if ("error" in info) {
            notification.open({
              message: info.error?.data?.error,
              description: collectErrorMessage(info.error?.data?.details),
              type: "error",
            });
          }
        });
      } else if ("error" in result) {
        notification.open({
          message: result.error?.data?.error,
          description: collectErrorMessage(result.error?.data?.details),
          type: "error",
        });
      }
    });
  };

  const applicationPersist = (
    fields: any,
    id: number,
    data: IApplicationResponseData
  ) => {
    const validFields = fields.hasOwnProperty("PERSONS")
      ? objectToArray(fields, "PERSONS")
      : fields;

    const persistParams = {
      id,
      version: 0,
      fields: mergeFormData(validFields, data),
    };

    const persist = collectPricingModel(persistParams);
    applicationPersistPost(persist).then((info) => {
      if ("data" in info) {
        notification.open({
          message: "Changes saved successfully",
          type: "success",
        });
        applicationRefetch();
      } else if ("error" in info) {
        notification.open({
          message: info.error?.data?.error,
          description: collectErrorMessage(info.error?.data?.details),
          type: "error",
        });
      }
    });
  };

  const objectToArray = (data: any, fieldName: string) => {
    const result = [];
    for (let prop in data[fieldName]) {
      result.push(data[fieldName][prop]);
    }
    return {
      ...data,
      [fieldName]: result,
    };
  };

  const applicationRejectRequest = (params: IApplicationReviewRequest) => {
    applicationReviewPost(params).then((info) => {
      if ("data" in info) {
        notification.open({
          message: "Status changed successfully",
          type: "success",
        });
        profileRefetch();
      } else if ("error" in info) {
        notification.open({
          message: "Something went wrong",
          description: info.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    applicationApproveRequest,
    applicationRejectRequest,
    applicationPersist,
    approveLoading,
    persistLoading,
    rejectLoading,
  };
};

const collectErrorMessage = (error: Record<string, any>): string => {
  let message: string = "";

  try {
    const keys = Object.keys(error);
    keys.forEach((key) => {
      let value: string = error[key];
      if (value.includes("[") || value.includes("]")) {
        value = value.replaceAll("]", "").replaceAll("[", "");
      }
      if (message.length) {
        message += "\n";
      }
      message += `${key.replaceAll("_", " ")}: ${value.replaceAll("_", " ").toLowerCase()}`;
    });
  } catch (err: any) {
    console.error(err);
  }

  return message.length ? message : "Unknown error!";
}

const collectPricingModel = ({ id, version, fields }: { id: number; version: number; fields: any }): { id: number; version: number; fields: any} => {
  const pmInStoreFeeType = fields["PM_IN_STORE_FEE_TYPE"];
  if(pmInStoreFeeType === Fee.DISABLED) {
    fields["PM_IN_STORE"] = false;
    fields["PM_IN_STORE_FEE_TX_PERCENTAGE"] = 0;
    fields["PM_IN_STORE_FEE_TX_FIX"] = 0;
  } else {
    fields["PM_IN_STORE"] = true;
    fields["PM_IN_STORE_FEE_TX_PERCENTAGE"] = Number(fields["PM_IN_STORE_FEE_TX_PERCENTAGE"]);
    fields["PM_IN_STORE_FEE_TX_FIX"] = Number(fields["PM_IN_STORE_FEE_TX_FIX"]);
  }

  const pmOnlineFeeType = fields["PM_ONLINE_FEE_TYPE"];
  if (pmOnlineFeeType === Fee.DISABLED) {
    fields["PM_ONLINE"] = false;
    fields["PM_ONLINE_FEE_TX_PERCENTAGE"] = 0;
    fields["PM_ONLINE_FEE_TX_FIX"] = 0;
  } else {
    fields["PM_ONLINE"] = true;
    fields["PM_ONLINE_FEE_TX_PERCENTAGE"] = Number(fields["PM_ONLINE_FEE_TX_PERCENTAGE"]);
    fields["PM_ONLINE_FEE_TX_FIX"] = Number(fields["PM_ONLINE_FEE_TX_FIX"]);
  }

  delete fields["PM_ONLINE_FEE_TYPE"];
  delete fields["PM_IN_STORE_FEE_TYPE"];

  return {
    id,
    version,
    fields,
  }
}

//для того чтобы документы не терялись при отправке формы
//тк они вне формы
const mergeFormData = (fields: any, data: IApplicationResponseData) => {
  let OWNERS = [];

  let result = {
    ...data,
    ...fields,
  };

  if ("PERSONS" in data) {
    const PERSONS = data["PERSONS"].map((item: any, index: number) => {
      return {
        ...item,
        ...fields["PERSONS"][index],
      };
    });
    result = {
      ...result,
      PERSONS,
    };
  }
  if ("OWNERS" in data) {
    OWNERS = data["OWNERS"].map((item: any, index: number) => {
      return {
        ...item,
        ...fields["OWNERS"][index],
      };
    });
    result = {
      ...result,
      OWNERS,
    };
  }
  return result;
};
