import { Radio, RadioChangeEvent } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { useSearchParams } from "react-router-dom";

export const DateQuickFilters: React.FC = () => {
  const { changeSearchParams } = useChangeSearchParams();
  let [searchParams] = useSearchParams();

  const fromFilter = searchParams.get("from");
  const toFilter = searchParams.get("to");

  const [appliedFilterValue, setAppliedFilterValue] = useState<string | null>(
    null
  );

  const thisWeek = {
    from: dayjs().subtract(1, "week").toDate().toISOString(),
    to: dayjs().toDate().toISOString(),
  };

  const lastWeek = {
    from: dayjs().startOf("week").toDate().toISOString(),
    to: dayjs().endOf("week").toDate().toISOString(),
  };

  const thisMonth = {
    from: dayjs().subtract(1, "month").toDate().toISOString(),
    to: dayjs().toDate().toISOString(),
  };

  const lastMonth = {
    from: dayjs().startOf("month").toDate().toISOString(),
    to: dayjs().endOf("month").toDate().toISOString(),
  };

  const lastYear = {
    from: dayjs().startOf("year").toDate().toISOString(),
    to: dayjs().endOf("year").toDate().toISOString(),
  };

  const datePeriodFilters = {
    thisWeek,
    lastWeek,
    thisMonth,
    lastMonth,
    lastYear,
  };

  const getCurrentAppliedQuickFilter = () => {
    if (fromFilter && toFilter) {
      let result = "";
      for (let period in datePeriodFilters) {
        if (
          dayjs(fromFilter).isSame(datePeriodFilters[period]["from"], "day") &&
          dayjs(toFilter).isSame(datePeriodFilters[period]["to"], "day")
        ) {
          result = period;
          break;
        }
      }
      return result;
    } else return null;
  };

  const onFilterChange = (value: RadioChangeEvent) => {
    if (datePeriodFilters[value.target.value]) {
      changeSearchParams(datePeriodFilters[value.target.value]);
      setAppliedFilterValue(value.target.value);
    }
  };

  useEffect(() => {
    setAppliedFilterValue(getCurrentAppliedQuickFilter());
  }, [fromFilter, toFilter]);

  return (
    <>
      <Radio.Group
        onChange={(value) => onFilterChange(value)}
        value={appliedFilterValue}
      >
        <Radio value={"thisWeek"}>This week</Radio>
        <Radio value={"lastWeek"}>Last week</Radio>
        <Radio value={"thisMonth"}>This month</Radio>
        <Radio value={"lastMonth"}>Last month</Radio>
        <Radio value={"lastYear"}>Last year</Radio>
      </Radio.Group>
    </>
  );
};
