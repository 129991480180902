import { createApi } from "@reduxjs/toolkit/query/react";
import { IReports } from "../types";
import { baseQueryInterceptor } from "../../../services/api/config";

// Define a service using a base URL and expected endpoints
export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getReports: builder.query<IReports[], string>({
      query: (params) => `/report?${params}`,
    }),
    downloadReports: builder.mutation<{}, string>({
      query: (params) => `/report/download?${params}`,
    }),
  }),
});
export const { useGetReportsQuery, useDownloadReportsMutation } = reportApi;
