import styled from "styled-components";
import { Alert } from "antd";

const DevOptionsFooterWrapper = styled.div`
  position: fixed;
  height: 50px;
  right: 0;
  bottom: 0;
  opacity: 50;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 50px;
`;

const AlertWrapper = styled(Alert)`
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 10;
`;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const Styled = {
  DevOptionsFooterWrapper,
  AlertWrapper,
  SwitchWrapper,
};
