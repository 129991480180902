import React from "react";
import {
  Form,
  Modal,
  Button,
  Space,
  Input,
  Form as AntForm,
  Select,
} from "antd";
import { IPartnerCreateRequest, IPartnerForm } from "data-layer/partner/types/partnerTypes";
import { yupValidator } from "../../common/partnerForm";
import { NamePath } from "antd/lib/form/interface";

interface ICreateFormProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  currencyList: string[];
  onSubmit: (
    data: IPartnerCreateRequest,
    clearForm: (fields?: NamePath[] | undefined) => void
  ) => void;
}

export const CreateForm: React.FC<ICreateFormProps> = ({
  visible,
  setVisible,
  onSubmit,
  currencyList,
}) => {
  const [form] = AntForm.useForm<IPartnerForm>();
  const validator = yupValidator(form.getFieldsValue);

  const onCreatePartner = (fields: IPartnerForm) => {
    const partner: IPartnerCreateRequest = {
      name: fields.name,
      address: fields.address,
      feePercent: fields.feePercent,
      currencyId: fields.currency.id,
      logoImageFilename: fields.logoImageFilename,
      settlementDetailsData: fields.settlementDetailsData,
    }
    onSubmit(partner, form.resetFields);
    setVisible(false);
  }

  return (
    <Modal
      open={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      title="Create partner"
    >
      <Form
        onFinish={onCreatePartner}
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[validator]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[validator]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Fee percent"
          name="feePercent"
          rules={[validator]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Currency"
          name={["currency", "id"]}
          rules={[validator]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            placeholder="Currency"
            options={currencyList?.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Space>
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button type="primary" htmlType={"submit"}>
              Create
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};
