import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { SearchTableFilter } from "../../components/SearchTableFilter";

export const Filters: React.FC = () => {
  let [searchParams] = useSearchParams();

  const { setInitialParams } = useChangeSearchParams();

  const initialFilters = {
    name: "",
    limit: "11",
    offset: "0",
  };

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [searchParams]);

  return (
    <>
      <Row>
        <Col span={8}>
          <SearchTableFilter
            placeholder={"Search by name"}
            filterName={"name"}
          />
        </Col>
      </Row>
    </>
  );
};
