import { Modal, Spin } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { LoadingOutlined } from "@ant-design/icons";
import { useLogout } from "../../data-layer/auth/hooks/useLogout";

export const ActivityDetection = () => {
  const timeout =
    process.env.REACT_APP_ENVIRONMENT === "development" ? 300_500_500 : 300_500;
  const promptBeforeIdle = 9_000;

  const [state, setState] = useState<string>("Active");
  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);

  const { sessionLogout } = useLogout();

  const onIdle = () => {
    setState("Idle");
    setOpen(false);
    sessionLogout();
  };

  const onActive = () => {
    setState("Active");
    setOpen(false);
  };

  const onPrompt = () => {
    setState("Prompted");
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? "seconds" : "second";

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <>
      {/*<h1>React Idle Timer</h1>*/}
      {/*<h2>Confirm Prompt</h2>*/}
      {/*<br />*/}
      {/*<p>Current State: {state}</p>*/}
      {/*{timeTillPrompt > 0 && (*/}
      {/*  <p>*/}
      {/*    {timeTillPrompt} {seconds} until prompt*/}
      {/*  </p>*/}
      {/*)}*/}
      {/*<div*/}
      {/*  className="modal"*/}
      {/*  style={{*/}
      {/*    display: open ? "flex" : "none",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  /!*<h3>Are you still here?</h3>*!/*/}
      {/*  /!*<p>Logging out in {remaining} seconds</p>*!/*/}
      {/*  /!*<button onClick={handleStillHere}>Im still here</button>*!/*/}
      {/*</div>*/}
      <Modal
        title="Are you still here?"
        open={open}
        okText={"I'm still here"}
        onOk={handleStillHere}
        maskClosable={false}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>Confirm that you are still on the site</p>
        <p>
          Logging out in {remaining} {seconds}
        </p>
        <Spin indicator={antIcon} />
      </Modal>
    </>
  );
};
