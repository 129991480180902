import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";
import { IDLQResponse, IRunPullerByRequest, RunPullerByResponse } from "../types/ISystem";

export const systemApi = createApi({
  reducerPath: "systemApi",
  baseQuery: baseQueryInterceptor,
  tagTypes: ["DQLList"],
  endpoints: (builder) => ({
    getDLQMessages: builder.query<IDLQResponse[], string>({
      query: (params) => {
        return {
          url: `/system/dlq?${params}`,
        };
      },
      providesTags: ["DQLList"],
    }),
    deleteDLQMessages: builder.mutation<{}, number>({
      query: (credentials) => ({
        url: `/system/dlq/${credentials}`,
        method: "DELETE",
        responseHandler: (response) => response.text(),
        invalidatesTags: ["DQLList"],
      }),
    }),
    runPullerBy: builder.mutation<RunPullerByResponse, IRunPullerByRequest>({
      query: (pullerByDTO) => {
        return {
          url: `/system/fiserv/feeds/pull-by`,
          method: "POST",
          body: pullerByDTO,
          responseHandler: (response) => response.text(),
        }
      },
    }),
  }),
});
export const { useGetDLQMessagesQuery, useDeleteDLQMessagesMutation, useRunPullerByMutation } =
  systemApi;
