import { IApplicationResponse } from "data-layer/application/types/applicationTypes";
import { useAttachmentsListByIdsQuery } from "../api/attachmentApi";
import {
  getAttachmentIdsQueryParamFromArray,
  getAttachmentsArr,
} from "../utility/index.js";
import { IDocumentsData } from "pages/onboardingDetails/Documents";

export const usePersonAttachments = (
  applicationData: IApplicationResponse | null
): IDocumentsData[] => {
  const personDocumentIds = getAttachmentIds(applicationData);

  const { data } = useAttachmentsListByIdsQuery(
    getAttachmentIdsQueryParamFromArray(personDocumentIds)
  );

  const documentsData = parseToValidAttachmentsArrStructure({
    attachments: data,
    applicationData,
  });

  return documentsData;
};

const getAttachmentIds = (
  applicationData: IApplicationResponse | null
): number[] => {
  if (!applicationData || !applicationData.data) {
    return [];
  }

  if (
    applicationData &&
    applicationData.data &&
    applicationData.data.PERSONS?.length
  ) {
    return applicationData.data.PERSONS.flatMap((person) =>
      getAttachmentsArr(person, PERSON_DOCUMENTS)
    );
  }
  return getAttachmentsArr(applicationData.data, PERSON_DOCUMENTS);
};

const parseToValidAttachmentsArrStructure = ({
  attachments,
  applicationData,
}: {
  attachments?: IAttachmentsResponse[];
  applicationData: IApplicationResponse | null;
}): IDocumentsData[] => {
  const result: IDocumentsData[] = [];
  if (applicationData && applicationData.data) {
    const persons = applicationData.data.PERSONS;
    if (persons && persons.length) {
      persons.forEach((person) => {
        let addressProof;
        let identityProof;
        if (attachments && attachments.length) {
          addressProof = attachments.find(
            (attachment) => attachment.id === person.PROOF_ADDRESS
          );

          identityProof = attachments.find(
            (attachment) => attachment.id === person.PROOF_IDENTITY_FIRST
          );
        }

        result.push({
          PERSON_FIRST_NAME: person.PERSON_FIRST_NAME,
          PERSON_LAST_NAME: person.PERSON_LAST_NAME,
          PROOF_ADDRESS_ATTACHMENT: addressProof,
          PROOF_IDENTITY_FIRST_ATTACHMENT: identityProof,
        });
      });

      return result;
    }

    let addressProof;
    let identityProof;
    if (attachments && attachments.length) {
      addressProof = attachments.find(
        (attachment) => attachment.id === applicationData.data.PROOF_ADDRESS
      );

      identityProof = attachments.find(
        (attachment) =>
          attachment.id === applicationData.data.PROOF_IDENTITY_FIRST
      );
    }

    result.push({
      PERSON_FIRST_NAME: applicationData.data.PERSON_FIRST_NAME,
      PERSON_LAST_NAME: applicationData.data.PERSON_LAST_NAME,
      PROOF_ADDRESS_ATTACHMENT: addressProof,
      PROOF_IDENTITY_FIRST_ATTACHMENT: identityProof,
    });
  }

  return result;
};

const PERSON_DOCUMENTS = ["PROOF_ADDRESS", "PROOF_IDENTITY_FIRST"];
