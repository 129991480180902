import React from "react";
import {
    Form,
    Modal,
    Button,
    Space,
    Input,
    Form as AntForm,
    Select,
} from "antd";
import { EMAIL_SERVICES } from "../../common/constants/constants";

interface ICreateFormProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    onSubmit: (
        data: any,
        clearForm: (fields?: (string | number)[] | undefined) => void
    ) => void;
}

export const EmailTestModal: React.FC<ICreateFormProps> = ({
                                                                  visible,
                                                                  setVisible,
                                                                  onSubmit,
                                                              }) => {
    const [form] = AntForm.useForm();

    const { Option } = Select;

    return (
        <Modal
            open={visible}
            footer={null}
            onCancel={() => setVisible(false)}
            title="Email test"
        >
            <Form
                onFinish={(data) => {
                    onSubmit(data, form.resetFields);
                    setVisible(false);
                }}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="email"
                    name="email"
                    rules={[{ required: true, message: "Please input email" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="emailService"
                    name="emailService"
                    rules={[{ required: true, message: "Please select service" }]}
                >
                    <Select placeholder={"Email service"}>
                        {EMAIL_SERVICES.map((item) => (
                            <Option key={item.value}>{item.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <div style={{ display: "flex", justifyContent: "end" }}>
                    <Space>
                        <Button onClick={() => setVisible(false)}>Cancel</Button>
                        <Button type="primary" htmlType={"submit"}>
                            Send
                        </Button>
                    </Space>
                </div>
            </Form>
        </Modal>
    );
};

export class EmailTest {
}