import React from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { useLogout } from "../../data-layer/auth/hooks/useLogout";
import { Styled } from "./styles";

export const Logout: React.FC = () => {
  const { logout } = useLogout();

  return (
    <Styled.LogoutButton
      icon={<LogoutOutlined />}
      size={"large"}
      onClick={logout}
    >
      Logout
    </Styled.LogoutButton>
  );
};
