import React from "react";
import { Card, Col, DatePicker, Form, Input, Row } from "antd";
import moment from "moment";
import { PhoneInput } from "../../../common/phoneInput";

interface IPersonalDetails {
  personName: string;
  isDisableForm: boolean;
}

export const PersonalDetails: React.FC<IPersonalDetails> = ({
  personName,
  isDisableForm,
}) => {
  return (
    <Card title={personName}>
      <Row gutter={[110, 0]}>
        <Col span={12}>
          <Form.Item
            name={"PERSON_FIRST_NAME"}
            label={"First name"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            label={"Date of birth"}
            labelCol={{ span: 24 }}
            name={"PERSON_DOB"}
            getValueProps={(value: any) => ({
              value: moment(value),
            })}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} selected={moment()} />
          </Form.Item>
          <Form.Item
            name={"PERSON_EMAIL"}
            label={"Email"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
            name={"PERSON_PHONE_NUMBER"}
            label={"Phone number"}
            labelCol={{ span: 24 }}
          >
            {isDisableForm ? (
              <Input placeholder={"None"} />
            ) : (
              <PhoneInput country={"gb"} />
            )}
          </Form.Item>
          <Form.Item
            name={"PERSON_POSTAL_CODE"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
            label={"Postcode"}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={"None"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"PERSON_LAST_NAME"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
            label={"Surname"}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            name={"PERSON_ADDRESS_LINE_1"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
            label={"Address line 1"}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            name={"PERSON_CITY"}
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
            label={"City/Town"}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={"None"} />
          </Form.Item>
          <Form.Item
            name={"PERSON_ADDRESS_LINE_2"}
            label={"Address line 2"}
            labelCol={{ span: 24 }}
          >
            <Input placeholder={"None"} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};
