import React from "react";
import { Button, Collapse } from "antd";
import { Styled as TableStyled } from "shared/style";
import moment from "moment";
import { IStatementIndexesResponse } from "../../data-layer/statmentIndexes/types/statementIndexesTypes";

interface ITableProps {
  tableData: IStatementIndexesResponse[];
  isLoading: boolean;
  requestStatus: string;
  handleLoadMoreStatementIndexes: () => void;
  pagination: boolean;
  onDeleteStatementIndexes: (id: string) => void;
  isDeleteStatementIndexesLoading: boolean;
}

export const Table: React.FC<ITableProps> = ({
  tableData,
  isLoading,
  requestStatus,
  pagination,
  handleLoadMoreStatementIndexes,
  onDeleteStatementIndexes,
  isDeleteStatementIndexesLoading,
}) => {
  const columns = getColumns(
    onDeleteStatementIndexes,
    isDeleteStatementIndexesLoading
  );

  return (
    <>
      <TableStyled.Table
        loading={requestStatus === "pending" || isLoading}
        columns={columns}
        dataSource={tableData}
        style={{ marginTop: "35px" }}
        pagination={false}
        bordered
      />
      {!isLoading && tableData.length > 0 && pagination && (
        <Button
          onClick={handleLoadMoreStatementIndexes}
          loading={requestStatus === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more statement indexes
        </Button>
      )}
    </>
  );
};

const momentFormat = "DD MMM, YYYY";

export const getColumns = (
  onDeleteStatementIndexes: (id: string) => void,
  isDeleteStatementIndexesLoading: boolean
) => {
  return [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (value: string) => moment(value).format(momentFormat),
    },
    {
      title: "Filename",
      dataIndex: "fileName",
      key: "fileName",
    },

    {
      title: "Modified",
      dataIndex: "modified",
      key: "modified",
      render: (value: string) => moment(value).format(momentFormat),
    },
    {
      title: "ErrorData",
      dataIndex: ["errorData", "notFoundLocal"],
      key: ["errorData", "notFoundLocal"],
      width: 300,
      render: (info: string[]) =>
        info && (
          <Collapse>
            <Collapse.Panel header="Not found local" key="1">
              {info.map((item) => (
                <span>{`${item}, `}</span>
              ))}
            </Collapse.Panel>
          </Collapse>
        ),
    },
    {
      title: "Source external payment id",
      dataIndex: "sourceExternalPaymentId",
      key: "sourceExternalPaymentId",
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Actions",
      key: "actions",
      render: (data: IStatementIndexesResponse) => {
        return (
          <Button
            loading={isDeleteStatementIndexesLoading}
            onClick={() => onDeleteStatementIndexes(data.id.toString())}
          >
            delete
          </Button>
        );
      },
    },
  ];
};
