import { useGetDictionaryMccQuery } from "../api/dictionaryApi";
import { IDictionaryMccResponse } from "../types/IDictionary";

export const useDictionaryMcc = () => {
  const { data, error, isLoading, isSuccess } = useGetDictionaryMccQuery("");

  return {
    dictionaryMccData: (data as IDictionaryMccResponse) || [],
    error,
    isLoading,
    isSuccess,
  };
};
