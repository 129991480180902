import { createApi } from "@reduxjs/toolkit/query/react";
import { crateQuery } from "../../../services/api/config";
import { IStatementIndexesResponse } from "../types/statementIndexesTypes";

export const statementIndexesApi = createApi({
  reducerPath: "statementIndexesApi",
  baseQuery: crateQuery,
  endpoints: (builder) => ({
    statementIndexes: builder.query<IStatementIndexesResponse[], string>({
      query: (params) => `/statement-indexes?${params}`,
    }),
    runStatementPuller: builder.mutation<{}, any>({
      query: () => ({
        url: `/statement-indexes`,
        method: "POST",
      }),
    }),
    deleteStatementIndexes: builder.mutation<string, any>({
      query: (params) => ({
        url: `/statement-indexes?id=${params}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useStatementIndexesQuery,
  useRunStatementPullerMutation,
  useDeleteStatementIndexesMutation,
} = statementIndexesApi;
