import styled from "styled-components";
import { Button } from "antd";

type NavSubtitleTextType = {
  active?: boolean;
};

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const RejectReasonButton = styled(Button)`
  background: #fff1f0;
  border: 1px solid #ffa39e;
  color: black;
  border-radius: 2px;
  font-size: 14px;
`;

const CancelButton = styled(Button)`
  border: 1px solid #d9d9d9;
  width: 78px;
  height: 32px;
  margin-right: 10px;
  font-size: 14px;
`;

const FormWrapper = styled.div`
  margin-top: 41px;
  width: 750px;

  .ant-input[disabled] {
    border: none;
    background: inherit;
    cursor: auto;
    color: #1d1d1b;
    padding-left: 0;
  }

  .ant-input-number-disabled {
    width: 100%;
    background-color: #ffffff;
    color: #000000;
    border: none;
    margin-left: -10px;
  }

  .ant-input-number {
    width: 100%;
    background-color: #ffffff;
    color: #000000;
    border-radius: 10px 0 0 10px
  }

  .ant-picker.ant-picker-disabled {
    background: inherit;
    border: none;
    color: #1d1d1b;
    padding: 0;
  }
  .ant-picker-input > input[disabled] {
    color: #1d1d1b;
  }

  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    display: none;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: inherit;
    color: #1d1d1b;
    border: none;
    cursor: auto;
    padding: 0;
  }
  .ant-select-disabled {
    .ant-select-arrow {
      display: none;
    }
  }

  .ant-form-item-label {
    padding-bottom: 0;
    label {
      color: #80868b;
    }
  }

  .ant-card {
    margin-bottom: 22px;
  }
`;

const NavSubtitleWrapper = styled.div`
  display: flex;
`;

const NavSubtitleText = styled.span<NavSubtitleTextType>`
  color: ${(props) => (props.active ? "262626" : "8C8C8C")};
`;

const SectionTitle = styled.h3`
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  color: #f09982;
`;

const PageHeaderWrapper = styled.div`
  //margin-left: 35px;

  .ant-page-header {
    padding: 0;
    padding-bottom: 10px;
  }
`;

const HeaderWrapper = styled.div`
  margin-top: 33px;
  display: flex;
  justify-content: space-between;
`;

const ApproveButton = styled(Button)`
  background: rgba(82, 196, 26, 0.2);
  border: 1px solid #52c41a;
  width: 370px;
  height: 40px;
  margin-right: 10px;
`;

const RejectButton = styled(Button)`
  background: #fff1f0;
  border: 1px solid #ffa39e;
  width: 143px;
  height: 40px;
  margin-right: 10px;
`;

const ActionButton = styled(Button)`
  width: 143px;
  height: 40px;
  margin-right: 10px;
`;

export const Styled = {
  FormWrapper,
  SectionTitle,
  NavSubtitleWrapper,
  NavSubtitleText,
  PageHeaderWrapper,
  HeaderWrapper,
  ApproveButton,
  RejectButton,
  ActionButton,
  ModalButtonWrapper,
  RejectReasonButton,
  CancelButton,
};
