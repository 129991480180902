import styled from "styled-components";
import { Button, Menu as AntdMenu } from "antd";

const TitleNavWrapper = styled.div`
  margin: 30px 0 70px 25px;
  cursor: pointer;
  position: relative;
`;

const NavWrapper = styled.div`
  position: sticky;
  top: 0;
  border: solid 1px #e8eaed;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const LogoutButton = styled(Button)`
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 10;
`;

const Menu = styled(AntdMenu)`
  font-size: 16px;
`;

const NavLabelContainer = styled.div`
  position: absolute;
  top: 6px;
  left: 45px;
  line-height: 1;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  flex-grow: 1;
`;

export const Styled = {
  TitleNavWrapper,
  NavWrapper,
  LogoutButton,
  Menu,
  NavLabelContainer,
  MenuWrapper,
};
