import React, { useRef, useState } from "react";
import { Button, Form, Form as AntForm, Input, Typography } from "antd";
import { Styled } from "./styles";
import { usePasswordRecovery } from "../../data-layer/auth/hooks/usePasswordRecovery";
import ReCAPTCHA from "react-google-recaptcha";

export const PasswordRecoverySc: React.FC = () => {
  const [form] = AntForm.useForm();

  const recaptchaRef = useRef(null);
  const [isRecaptchaPending, setIsRecaptchaPending] = useState<boolean>(false);

  const onSubmitWithReCAPTCHA = async (email: string) => {
    const token = await recaptchaRef?.current?.executeAsync();
    setIsRecaptchaPending(true);
    onPasswordRecovery(email, token);
    setIsRecaptchaPending(false);
    recaptchaRef?.current?.reset();
  };

  const { onPasswordRecovery } = usePasswordRecovery();

  return (
    <>
      <Typography.Title
        style={{
          textAlign: "center",
          marginTop: 228,
          marginBottom: 59,
          fontWeight: 400,
        }}
      >
        Password recovery
      </Typography.Title>
      <Styled.Wrapper>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={(fields) => onSubmitWithReCAPTCHA(fields?.email)}
        >
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
            // sitekey={"6Lc1olklAAAAAP3yw_pKBfnfQrUeIKtpd0qCTkw-"}
            ref={recaptchaRef}
            size="invisible"
          />
          <Form.Item
            name={"email"}
            label={<Styled.FormLabel>Enter email</Styled.FormLabel>}
            rules={[
              { required: true },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input placeholder={"Email"} />
          </Form.Item>
          <Button
            loading={isRecaptchaPending}
            block
            size={"large"}
            type={"primary"}
            htmlType={"submit"}
            style={{ marginTop: 30 }}
          >
            Reset password
          </Button>
        </Form>
      </Styled.Wrapper>
    </>
  );
};
