import { useDictionary } from "../../../data-layer/dictionary/hooks";
import { useSearchParams } from "react-router-dom";
import { IDictionaryResponse } from "../../../data-layer/dictionary/types/IDictionary";
import { parseQueryParamsToArr } from "./parseQueryParamsToArr";
import { useEffect, useState } from "react";
import { useFindMerchantById } from "../../../data-layer/merchant/hooks/useFindMerchantById";
import { useFindMerchants } from "../../../data-layer/merchant/hooks/useFindMerchants";
import { useFindPartners } from "../../../data-layer/partner/hooks/useFindPartners";

export const useGetValidAppliedFilters = () => {
  let { dictionaryData } = useDictionary();
  const { findMerchantById } = useFindMerchantById();
  const { getFindPartners, searchData: partnersSearchData } = useFindPartners();

  const { searchData } = useFindMerchants();

  const [merchant, setMerchant] = useState<string | null>(null);
  const [partner, setPartner] = useState<string | null>(null);

  let [searchParams] = useSearchParams();

  const fetchFindMerchant = async () => {
    const currentMerchantId = parseQueryParamsToArr(searchParams).find(
      (item) => item.queryName === "merchantIds"
    );
    if (searchData.length > 0) {
      const merchantFromLoadedArray = searchData.find(
        (item) => item?.key === parseInt(currentMerchantId?.value)
      );

      if (merchantFromLoadedArray) {
        setMerchant(merchantFromLoadedArray.value);
      } else {
        const merchantFetchResult = await findMerchantById(
          currentMerchantId?.value
        );
        setMerchant(merchantFetchResult?.data?.merchantName);
      }
    }
  };

  const fetchFindPartner = async () => {
    const currentPartnerId = parseQueryParamsToArr(searchParams).find(
      (item) => item.queryName === "partnerIds"
    );

    if (partnersSearchData.length > 0) {
      const partnerFromLoadedArray = partnersSearchData.find(
        (item) => item?.key === parseInt(currentPartnerId?.value)
      );
      if (partnerFromLoadedArray) {
        setPartner(partnerFromLoadedArray.value);
      } else {
        const partnerFetchResult = await getFindPartners(
          currentPartnerId?.value
        );
        setPartner(partnerFetchResult?.data?.name);
      }
    }
  };

  useEffect(() => {
    if (
      parseQueryParamsToArr(searchParams).find(
        (item) => item.queryName === "merchantIds" && item.value !== ""
      )
    ) {
      fetchFindMerchant();
    }
  }, [searchParams, searchData]);

  useEffect(() => {
    if (
      parseQueryParamsToArr(searchParams).find(
        (item) => item.queryName === "partnerIds" && item.value !== ""
      )
    ) {
      fetchFindPartner();
    }
  }, [searchParams, searchData]);

  const getAcceptableOutputFilters = (
    filters: { queryName: string; value: any }[]
  ) => {
    return filters.filter((item) => {
      return (
        acceptableOutputFilters.includes(item.queryName) && Boolean(item.value)
      );
    });
  };

  const additionalValidateForAlternativeFilterName = (
    filters: { queryName: string; value: any }[],
    dictionaryData: IDictionaryResponse
  ) => {
    return filters.map((item) => {
      const { queryName, value } = item;
      if (queryName === "countryId" && dictionaryData) {
        return {
          queryName,
          value: dictionaryData?.countries.find(
            (country) => value === country.id.toString()
          )?.name,
        };
      }
      if (queryName === "merchantIds" && merchant) {
        return {
          queryName,
          value: merchant,
        };
      }
      if (queryName === "partnerIds" && partner) {
        return {
          queryName,
          value: partner,
        };
      } else return item;
    });
  };

  const validAppliedFilters = additionalValidateForAlternativeFilterName(
    getAcceptableOutputFilters(parseQueryParamsToArr(searchParams)),
    dictionaryData
  );

  return {
    validAppliedFilters,
  };
};

const acceptableOutputFilters = [
  "states",
  "statuses",
  "companyName",
  "countryId",
  "managerName",
  "merchantIds",
  "partnerIds",
  "employeeName",
  "types",
  "nameLike",
  "surnameLike",
  "emailLike",
  "tid",
  "name",
  "surname",
  "email",
  "id",
  "value",
  "locale",
];
