import { FormInstance, notification } from "antd";
import { useDocumentRequestMutation } from "../api/documentRequestApi";

interface IOnDocumentRequestSubmitProp {
  documentRequestTextArea: string;
  id: number;
  form: FormInstance<any>;
  documentRequestRefetch: any;
}

export const useDocumentRequest = () => {
  const [documentRequest] = useDocumentRequestMutation();

  const onDocumentRequestSubmit = ({
    documentRequestTextArea,
    id,
    form,
    documentRequestRefetch,
  }: IOnDocumentRequestSubmitProp) => {
    documentRequest({
      profileId: id,
      message: documentRequestTextArea,
    }).then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Request sent successfully",
          type: "success",
        });
        documentRequestRefetch();
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
    form.resetFields();
  };

  return {
    onDocumentRequestSubmit,
  };
};
