import React, { useEffect } from "react";
import { Button, Col, DatePicker, Row } from "antd";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import moment from "moment";
import { SearchTableFilter } from "../../components/SearchTableFilter";
import { FilterOutlined } from "@ant-design/icons";

export const Filters: React.FC = () => {
  let [searchParams] = useSearchParams();

  const { changeSearchParams, setInitialParams, resetToInitialParams } =
    useChangeSearchParams();

  const initialFilters = {
    email: "",
    name: "",
    limit: "11",
    offset: "0",
    from: "",
    to: "",
    "orderBy[0].field": "created",
    "orderBy[0].direction": "DESC",
  };

  const dateFromFilter = searchParams.get("from");
  const dateToFilter = searchParams.get("to");

  const rangeFilter = [
    dateFromFilter && moment(dateFromFilter),
    dateToFilter && moment(dateToFilter),
  ];

  const handleDateChange = (momentRange: any) => {
    if (momentRange === null) {
      changeSearchParams({
        from: "",
        to: "",
      });
    }
    changeSearchParams({
      from: momentRange[0].toDate().toISOString(),
      to: momentRange[1].toDate().toISOString(),
    });
  };

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [searchParams]);

  const { RangePicker } = DatePicker;

  return (
    <>
      <Row>
        <Col xxl={8} xl={10} lg={14}>
          <Row>
            <Col span={12}>
              <SearchTableFilter
                placeholder={"Search by name"}
                filterName={"name"}
              />
            </Col>
            <Col span={12}>
              {/*@ts-ignore*/}
              <RangePicker
                format={"DD MMM"}
                onChange={handleDateChange}
                //@ts-ignore
                value={rangeFilter}
                style={{ marginLeft: 25 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 16, marginBottom: 16 }}>
        <Col xxl={8} xl={10} lg={14}>
          <SearchTableFilter
            placeholder={"Search by email"}
            filterName={"email"}
          />
        </Col>
      </Row>
      <Col span={24}>
        <Row>
          <Col span={8}>
            <Button
              icon={<FilterOutlined />}
              style={{ marginRight: 15 }}
              onClick={() => resetToInitialParams(initialFilters)}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
};
