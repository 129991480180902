import { notification } from "antd";
import { useDeleteStatementIndexesMutation } from "../api/statementIndexesApi";

export const useDeleteStatementIndexes = (
  statementIndexesRefetch: () => void
) => {
  const [deleteStatementIndexes, { data, isLoading }] =
    useDeleteStatementIndexesMutation();

  const onDeleteStatementIndexes = (id: string) => {
    deleteStatementIndexes(id).then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Statement indexes deleted",
          type: "success",
        });
        statementIndexesRefetch();
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    onDeleteStatementIndexes,
    isLoading,
  };
};
