import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";

import { NavigationMenu } from "../../features/navigation";
import { ActivityDetection } from "../ActivityDetection/ActivityDetection";

export const MainLayout = () => {
  const { Sider, Content } = Layout;

  return (
    <>
      <ActivityDetection />
      <Layout style={{ background: "none" }}>
        <Sider theme={"light"} width={250}>
          <NavigationMenu />
        </Sider>
        <Content style={{ paddingLeft: "25px", paddingRight: "25px" }}>
          <Outlet />
        </Content>
      </Layout>
    </>
  );
};
