import {
  useUpdatePartnerMutation,
} from "../api/partnerApi";
import { notification } from "antd";
import { IPartnerUpdateRequest } from "../types/partnerTypes";

export const useUpdatePartner = () => {
  const [update] = useUpdatePartnerMutation();

  const onUpdatePartner = async (
    fields: IPartnerUpdateRequest
  ) => {
    const result = await update(fields);
    if ("data" in result) {
      notification.open({
        message: "Info updated",
        type: "success",
      });
    } else if ("error" in result) {
      notification.open({
        message: "Something went wrong",
        description: result.error?.data?.error,
        type: "error",
      });
    }
    return result;
  };

  return {
    onUpdatePartner,
  };
};
