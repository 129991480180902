import { AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { useFindMerchants } from "../../data-layer/merchant/hooks/useFindMerchants";
import { useSearchParams } from "react-router-dom";
import { useFindPartners } from "../../data-layer/partner/hooks/useFindPartners";

interface IPartnerFindInput {
  onSelect?: () => void;
  disabled?: boolean;
  filterName?: string
}

export const PartnersFindFilter: React.FC<IPartnerFindInput> = ({
  onSelect,
  disabled,
                                                                  filterName
}) => {
  const { changeSearchParams } = useChangeSearchParams();
  const { getFindPartners, searchData } = useFindPartners();

  let [searchParams] = useSearchParams();

  const partnersIdFilter = searchParams.get(`${filterName ? filterName : "partnerIds"}`);
  const partnerNameFilter = searchData.find(
    (item) => item.key == partnersIdFilter
  )?.value;

  const [partnersValue, setPartnersValue] = useState(partnerNameFilter);

  const onSearch = (value: string) => {
    getFindPartners(`name=${value}`);
  };

  const onSelectPartner = (
    res: string,
    { key, value }: { key: number; value: string }
  ) => {
    const paramsObj = filterName ? { [filterName]: key } : { partnerIds: key }
    changeSearchParams(paramsObj);
    onSelect && onSelect();
  };

  const onClearMerchantSelect = () => {
    const paramsObj = filterName ? { [filterName]: "" } : { partnerIds: "" }

    changeSearchParams(paramsObj);
  };

  useEffect(() => {
    setPartnersValue(partnerNameFilter);
  }, [partnerNameFilter]);

  useEffect(() => {
    getFindPartners(`name=`);
  }, []);

  return (
    <AutoComplete
      disabled={disabled}
      allowClear
      value={partnersValue}
      options={searchData}
      onSelect={onSelectPartner}
      onSearch={onSearch}
      onChange={setPartnersValue}
      placeholder="Enter partners name"
      style={{ width: "100%" }}
      onClear={onClearMerchantSelect}
    />
  );
};
