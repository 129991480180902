import { useGetUsersQuery } from "../api/userApi";
import { IRoles, IUserResponse } from "../types/IUser";
import { useChangeSearchParams } from "../../../services/searchParams/changeSearchParams";

export const useUser = (searchParams: string, limitFilter: string) => {
  const skipRequest = !Boolean(searchParams);
  const { changeSearchParams } = useChangeSearchParams();

  const { data, error, status, isLoading, isSuccess } = useGetUsersQuery(
    searchParams,
    {
      skip: skipRequest,
    }
  );

  const userData = data?.length ? setDefaultSelectValue(data) : [];
  //for pagination check
  let dataWithoutLastItem = [...userData];
  limitFilter && dataWithoutLastItem.splice(parseInt(limitFilter) - 1, 1);

  const handleSortColumn = (sortColumn: string, sortDirection: string) => {
    const direction = sortDirection === "ascend" ? "ASC" : "DESC";
    changeSearchParams({
      ["orderBy[0].field"]: sortColumn,
      ["orderBy[0].direction"]: direction,
    });
    if (sortDirection === undefined) {
      changeSearchParams({
        ["orderBy[0].field"]: "",
        ["orderBy[0].direction"]: "",
      });
    }
  };

  return {
    handleSortColumn,
    userData,
    dataWithoutLastItem,
    data,
    error,
    isLoading,
    isSuccess,
    requestStatus: status,
  };
};

const setDefaultSelectValue = (userData: IUserResponse[]) => {
  return userData.map((user) => {
    return {
      ...user,
      defaultSelectValue: getDefaultSelectValue(user.roles),
    };
  });
};

const getDefaultSelectValue = (userRolesData: IRoles[]) => {
  return userRolesData.map((role) => {
    return {
      key: role.id,
      value: role.id,
      label: role.name,
    };
  });
};
