import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../services/api/config";
import { I18settingsResponse } from "../types/i18settingsTypes";

export const i18settingsApi = createApi({
  reducerPath: "i18settingsApi",
  baseQuery,
  tagTypes: ["localesList"],
  endpoints: (builder) => ({
    getLocales: builder.query<I18settingsResponse[], string>({
      query: (params) => `/system/locales/find?${params}`,
      providesTags: ["localesList"],
    }),
    updateLocale: builder.mutation<I18settingsResponse[], string>({
      query: (credential) => ({
        url: `/system/locales`,
        body: credential,
        method: "PUT",
      }),
      invalidatesTags: (result) => (result ? ["localesList"] : []),
    }),
    createLocale: builder.mutation<I18settingsResponse[], string>({
      query: (credential) => ({
        url: `/system/locales`,
        body: credential,
        method: "POST",
      }),
      invalidatesTags: (result) => (result ? ["localesList"] : []),
    }),
    deleteLocales: builder.mutation<{}, string>({
      query: (id) => ({
        url: `/system/locales/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error) =>
        result !== undefined ? ["localesList"] : [],
    }),
  }),
});
export const {
  useCreateLocaleMutation,
  useDeleteLocalesMutation,
  useGetLocalesQuery,
  useUpdateLocaleMutation,
} = i18settingsApi;
