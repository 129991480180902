import { downloadApi } from "../api/downloadApi";
import { useAttachmentByIdsMutation } from "../api/attachmentApi";
import { notification } from "antd";
export const useDownload = () => {
  const [getAttachmentById] = useAttachmentByIdsMutation();
  const token = localStorage.getItem("authToken");

  const downloadFile = (id: number) => {
    getAttachmentById(id.toString()).then((result) => {
      if ("data" in result) {
        const { hashFileName, originalFileName } = result.data;
        startDownloading(hashFileName, originalFileName);
      }
    });
  };

  const startDownloading = (downloadPath: string, filename: string) => {
    downloadWaitingNotify();
    downloadApi(downloadPath, token)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        downloadCompleteNotify();
      })
      .catch(console.error);

    //создать компонент с отдельными нотификациями
  };

  return {
    downloadFile,
    startDownloading,
  };
};

const waitingNotifyKey = "waiting";

const downloadWaitingNotify = () => {
  notification.open({
    message: "Download is about to start",
    description: "Please wait a bit",
    type: "info",
    duration: 0,
    key: waitingNotifyKey,
  });
};

const downloadCompleteNotify = () => {
  notification.close(waitingNotifyKey);
  notification.open({
    message: "Success",
    description: "Download complete",
    type: "success",
  });
};
