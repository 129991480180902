import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";
import {
  IDictionaryMccResponse,
  IDictionaryResponse,
} from "../types/IDictionary";

// Define a service using a base URL and expected endpoints
export const dictionaryApi = createApi({
  reducerPath: "dictionaryApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getDictionary: builder.query<IDictionaryResponse, string>({
      query: () => `/dictionary`,
    }),
    getDictionaryMcc: builder.query<IDictionaryMccResponse, string>({
      query: () => `/dictionary/mcc`,
    }),
  }),
});
export const { useGetDictionaryQuery, useGetDictionaryMccQuery } =
  dictionaryApi;
