import styled from "styled-components";

const CardContainer = styled.div`
  background: rgba(240, 240, 240, 0.3);
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  width: 350px;
  padding: 27px 12px 14px 12px;
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: #262626;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const ListTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin: 0;
`;

const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 2px;
  padding: 2px 8px;
  height: fit-content;
`;

const CounterPosition = styled(Counter)`
  margin-left: 10px;
`;

export const Styled = {
  CardContainer,
  Label,
  Text,
  FlexWrapper,
  ListTitle,
  Counter,
  CounterPosition,
};
