import React from "react";
import { Helmet } from "react-helmet";
import { Button, PageHeader } from "antd";
import { Filters } from "./Filters";
import { useSearchParams } from "react-router-dom";
import { Table } from "./Table";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { useGetStatementIndexes } from "../../data-layer/statmentIndexes/hooks/useGetStatementIndexes";
import { useRunStatementIndexesPuller } from "../../data-layer/statmentIndexes/hooks/useRunStatementIndexesPuller";
import { useDeleteStatementIndexes } from "../../data-layer/statmentIndexes/hooks/useDeleteStatementIndexes";

export const StatementIndexes: React.FC = () => {
  let [searchParams] = useSearchParams();
  const { changeSearchParams } = useChangeSearchParams();

  const limitFilter = searchParams.get("limit") || "";

  const { data, isLoading, status, refetch } = useGetStatementIndexes(
    searchParams.toString(),
    limitFilter
  );

  const { onRunStatementIndexesPuller, isLoading: isRunPullerLoading } =
    useRunStatementIndexesPuller(refetch);

  const {
    onDeleteStatementIndexes,
    isLoading: isDeleteStatementIndexesLoading,
  } = useDeleteStatementIndexes(refetch);

  const isAllowPagination = parseInt(limitFilter) === data.length;

  const handleLoadMoreStatementIndexes = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };

  return (
    <>
      <Helmet>
        <title>Statement Indexes</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="Statement Indexes" />
      <Filters />
      <Button
        type="primary"
        style={{ marginTop: 25 }}
        onClick={() => onRunStatementIndexesPuller()}
        loading={isRunPullerLoading}
      >
        Run settlement statement puller
      </Button>
      <Table
        isLoading={isLoading}
        tableData={data}
        pagination={isAllowPagination}
        handleLoadMoreStatementIndexes={handleLoadMoreStatementIndexes}
        requestStatus={status}
        onDeleteStatementIndexes={onDeleteStatementIndexes}
        isDeleteStatementIndexesLoading={isDeleteStatementIndexesLoading}
      />
    </>
  );
};
