import { createApi } from "@reduxjs/toolkit/query/react";
import { crateQuery } from "../../../services/api/config";
import { IPayoutResponse, IPayoutUpdateRequest, RetryExportStatement } from "../types/payoutTypes";

export const payoutApi = createApi({
  reducerPath: "payoutApi",
  baseQuery: crateQuery,
  endpoints: (builder) => ({
    reconciliationPayouts: builder.mutation<IPayoutResponse[], string>({
      query: () => ({
        url: `/payouts/reconciliation`,
        method: "POST",
      }),
    }),
    reconciliationPayoutsPartner: builder.mutation<IPayoutResponse[], string>({
      query: () => ({
        url: `/payouts/partner-reconciliation`,
        method: "POST",
      }),
    }),
    updatePayouts: builder.mutation<IPayoutResponse[], IPayoutUpdateRequest>({
      query: (credential) => ({
        url: `/payouts/update`,
        body: credential,
        method: "PUT",
      }),
    }),
    getPayouts: builder.query<IPayoutResponse[], string>({
      query: (params) => `/payouts/admin?${params}`,
    }),
    downloadSettlement: builder.query<string, number>({
      query: (payoutId: number) => ({
        url: `/payouts/download-statement/${payoutId}`,
        method: "GET",
      }),
    }),
    retryExportStatement: builder.mutation<RetryExportStatement, number>({
      query: (payoutId: number) => ({
        url: `/payouts/re-export-statement/${payoutId}`,
        method: "POST",
      }),
    }),
  }),
});
export const {
  useReconciliationPayoutsMutation,
  useReconciliationPayoutsPartnerMutation,
  useUpdatePayoutsMutation,
  useGetPayoutsQuery,
  useDownloadSettlementQuery,
  useRetryExportStatementMutation,
} = payoutApi;
