import React from "react";
import { Styled } from "./style";
import { Col, Row } from "antd";
import { ITransactionsAggregate } from "../../data-layer/transaction/types/ITransaction";
import getSymbolFromCurrency from "currency-symbol-map";
import { addTrailingZeros } from "../../utils/addTrailingZeros";

interface ITotalTurnover {
  transactionsAggregateData: ITransactionsAggregate;
}

export const TotalTurnover: React.FC<ITotalTurnover> = ({
  transactionsAggregateData,
}) => {
  if (transactionsAggregateData === undefined) return <></>;
  const { currency, sum, sumExcludeRefund } = transactionsAggregateData;

  return (
    <Styled.TotalTurnoverWrapper>
      <Row>
        <Col span={24}>
          <Styled.TotalTurnoverMainTitleWrapper>
            Total turnover:
          </Styled.TotalTurnoverMainTitleWrapper>
          <Styled.TotalTurnoverContainer>
            <Styled.TotalTurnoverInfoBlock>
              <Styled.TotalTurnoverTitle>
                Selected filters
              </Styled.TotalTurnoverTitle>
              <Styled.TotalTurnoverInfoValue>
                {sum !== 0
                  ? `${getSymbolFromCurrency(currency)}${addTrailingZeros(sum)}`
                  : sum}
              </Styled.TotalTurnoverInfoValue>
            </Styled.TotalTurnoverInfoBlock>
            <Styled.TotalTurnoverInfoBlock>
              <Styled.TotalTurnoverTitle>
                Except refunds
              </Styled.TotalTurnoverTitle>
              <Styled.TotalTurnoverInfoValue>
                {sumExcludeRefund !== 0
                  ? `${getSymbolFromCurrency(currency)}${addTrailingZeros(
                      sumExcludeRefund
                    )}`
                  : sumExcludeRefund}
              </Styled.TotalTurnoverInfoValue>
            </Styled.TotalTurnoverInfoBlock>
          </Styled.TotalTurnoverContainer>
        </Col>
      </Row>
    </Styled.TotalTurnoverWrapper>
  );
};
