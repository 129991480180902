import { useGetPayoutsQuery } from "../api/payoutApi";
import { IPayoutResponse } from "../types/payoutTypes";
import dayjs from "dayjs";

export const useGetPayout = (searchParams: string, limitFilter: string) => {
  const skipRequest = !Boolean(searchParams);
  const { data, error, status, isLoading, isSuccess, refetch } =
    useGetPayoutsQuery(`${searchParams}`, {
      skip: skipRequest,
    });

  const validatePayouts = (data: IPayoutResponse[]) => {
    let result = data.map((item) => {
      const { created, modified } = item;
      const dateFormat = "DD MMM, YYYY";

      return {
        ...item,
        merchantName: item?.merchant?.merchantName,
        created: dayjs(created).format(dateFormat),
        modified: dayjs(modified).format(dateFormat),
      };
    });

    return result;
  };

  const payoutData = data?.length ? validatePayouts(data) : [];

  let dataWithoutLastItem = [...payoutData];
  limitFilter && dataWithoutLastItem.splice(parseInt(limitFilter) - 1, 1);

  return {
    payoutData,
    dataWithoutLastItem,
    data,
    error,
    isLoading,
    isSuccess,
    refetch,
    requestStatus: status,
  };
};
