import {useDownloadReportsMutation} from "./api";
import {notification} from "antd";

export const useDownloadReports = () => {
    const [ download ] = useDownloadReportsMutation()

    const downloadReport = (params: string, format: string) => {

        const validFormat = format.toLowerCase()
        downloadWaitingNotify()

        download(params).then((response) => {
            const blob = new Blob([response.error.data]);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `reports.${validFormat}`
            link.click();
            downloadCompleteNotify();
        })
            .catch(console.error);
    }

    return {
        downloadReport
    }
}

const downloadWaitingNotify = () => {
    notification.open({
        message: "Download is about to start",
        description: "Please wait a bit",
        type: "info",
        duration: 0,
        key: waitingNotifyKey,
    });
};

const waitingNotifyKey = "waiting";


const downloadCompleteNotify = () => {
    notification.close(waitingNotifyKey);
    notification.open({
        message: "Success",
        description: "Download complete",
        type: "success",
    });
};