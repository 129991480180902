import React from "react";
import { PageHeader } from "antd";
import { history } from "../../../routes/AppRouter";
import { Styled } from "../styled";

export const NavHeader: React.FC = () => {
  return (
    <>
      <Styled.PageHeaderWrapper>
        <PageHeader
          onBack={() => history.back()}
          title="Onboarding info"
          style={{ position: "relative" }}
        />

      </Styled.PageHeaderWrapper>
    </>
  );
};
