import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, PageHeader } from "antd";

import { CreateEmployee } from "./components/CreateEmployee";

import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { useEmployee } from "../../data-layer/employee/hooks";
import { IEmployeeResponse } from "../../data-layer/employee/types/IEmployee";
import { ITransactionResponse } from "../../data-layer/transaction/types/ITransaction";
import { Helmet } from "react-helmet";
import { useSetStatusEmployee } from "../../data-layer/employee/hooks/useSetStatusEmployee";
import { Filters } from "./components/Filters";
import { AppliedFilters } from "../../features/appliedFilters";
import { Table } from "./components/Table";
import { EmployeeInfo } from "./components/EmployeeInfo";
import {
  Authorization,
  ROLES,
} from "../../providers/authorization/Authorization";
import { NotFound } from "../notFound";

export const Employees: React.FC = () => {
  let [searchParams] = useSearchParams();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [employeeItemInfo, setEmployeeItemInfo] = useState<
    IEmployeeResponse | {}
  >({});
  const [visibleCreateEmployeeModal, setVisibleCreateEmployeeModal] =
    useState(false);

  const { onBlockEmployee } = useSetStatusEmployee();

  const { changeSearchParams } = useChangeSearchParams();

  const limitFilter = searchParams.get("limit") || "";

  const handleLoadMoreEmployee = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };

  const onDrawerClose = () => {
    setVisibleDrawer(false);
  };

  const onDrawerOpen = (transactionInfo: ITransactionResponse) => {
    setEmployeeItemInfo(transactionInfo);
    setVisibleDrawer(true);
  };

  const { employeeData, dataWithoutLastItem, isLoading, requestStatus } =
    useEmployee(searchParams.toString(), limitFilter);

  const isAllowPagination = parseInt(limitFilter) === employeeData.length;

  //todo переместить всю бизнес логику в табле

  return (
    <>
      <Authorization
        allowedRoles={[
          ROLES.PARTNER,
          ROLES.EMPLOYEE,
          ROLES.EMPLOYEE,
          ROLES.ADMIN,
          ROLES.COMPLIANCE_OFFICER,
        ]}
        forbiddenFallback={<NotFound />}
      >
        <Helmet>
          <title>Employees</title>
        </Helmet>
        <PageHeader style={{ paddingLeft: 0 }} title="Employee" />
        <Filters />
        <AppliedFilters />
        <Button
          type="primary"
          onClick={() => setVisibleCreateEmployeeModal(true)}
          style={{ marginTop: 15 }}
        >
          Create employee
        </Button>
        <Table
          pagination={isAllowPagination}
          tableData={dataWithoutLastItem}
          isLoading={isLoading}
          requestStatus={requestStatus}
          handleLoadMoreEmployee={handleLoadMoreEmployee}
          onRowClick={onDrawerOpen}
          onBlockEmployee={onBlockEmployee}
        />
        <EmployeeInfo
          data={employeeItemInfo}
          visible={visibleDrawer}
          onClose={onDrawerClose}
        />
        <CreateEmployee
          visible={visibleCreateEmployeeModal}
          setVisible={setVisibleCreateEmployeeModal}
        />
      </Authorization>
    </>
  );
};
