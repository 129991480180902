export const getAttachmentIdsQueryParamFromArray = (idsArray: number[]): string => {
  return `?ids=${idsArray.join(",")}`;
};

export const getAttachmentsArr = (
  data: Record<string, any>,
  fieldList: string[]
): number[] => {
  const attachmentsArr: number[] = [];
  fieldList.forEach((item) => {
    if (
      data &&
      data.hasOwnProperty(item) &&
      Number.isInteger(data[item])
    ) {
      attachmentsArr.push(data[item]);
    }
  });
  return attachmentsArr;
};
