import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryInterceptor } from "../../../services/api/config";
import { ITerminalsResponse } from "../types/ITerminals";

// Define a service using a base URL and expected endpoints
export const terminalApi = createApi({
  reducerPath: "terminalApi",
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    findTerminals: builder.query<ITerminalsResponse[], string>({
      query: (params) => `/terminals/admin?${params}`,
    }),
  }),
});
export const { useFindTerminalsQuery } = terminalApi;
