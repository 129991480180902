import { useDeleteLocalesMutation } from "../api/i18settingsApi";
import { notification } from "antd";

export const useDeleteLocale = () => {
  const [deleteLocale] = useDeleteLocalesMutation();

  const onDeleteLocale = (id: string) => {
    deleteLocale(id).then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Locale deleted",
          type: "success",
        });
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    onDeleteLocale,
  };
};
