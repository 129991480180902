import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, PageHeader } from "antd";
import { CreateForm } from "./CreateForm";
import { useCreatePartner } from "../../data-layer/partner/hooks/useCreatePartner";
import { usePartners } from "../../data-layer/partner/hooks/usePartners";
import { Filters } from "./Filters";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Table } from "./Table";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { IPartnerWithMerchants } from "../../data-layer/partner/types/partnerTypes";
import { PartnerInfoDrawer } from "./PartnerInfoDrawer";
import { InviteMerchantsModal } from "./InviteMerchantsModal";
import { useMerchants } from "../../data-layer/merchant/hooks/useMerchants";
import { useConnectMerchantsToPartner } from "../../data-layer/partner/hooks/useConnectMerchantsToPartner";
import { useDictionary } from "../../data-layer/dictionary/hooks";
import { useUpdatePartner } from "../../data-layer/partner/hooks/useUpdatePartner";
import { AppliedFilterInput } from "../../components/AppliedFiltersInput";
import {
  Authorization,
  ROLES,
} from "../../providers/authorization/Authorization";
import { NotFound } from "../../pages/notFound";

export const Partners: React.FC = () => {
  const [visibleCreatePartnerModal, setVisibleCreatePartnerModal] =
    useState(false);
  const [visibleInviteMerchantsModal, setVisibleInviteMerchantsModal] =
    useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const navigate = useNavigate();

  const [partnerDrawerInfo, setPartnerDrawerInfo] = useState<
    IPartnerWithMerchants | {}
  >({});

  const { onSubmitCreateForm } = useCreatePartner();
  let [searchParams] = useSearchParams();
  const { changeSearchParams } = useChangeSearchParams();
  const { connectMerchants } = useConnectMerchantsToPartner();

  const limitFilter = searchParams.get("limit") || "";

  const merchantsData = useMerchants("name=&limit=1000&offset=0");

  const { data, isLoading, status } = usePartners(
    searchParams.toString(),
    merchantsData?.data
  );

  const { dictionaryData } = useDictionary();

  const { onUpdatePartner } = useUpdatePartner();

  const isAllowPagination = parseInt(limitFilter) === data.length;

  const handleLoadMorePartners = () => {
    changeSearchParams({ limit: parseInt(limitFilter) + 10 });
  };

  const onDrawerOpen = (partnerInfo: IPartnerWithMerchants) => {
    setPartnerDrawerInfo(partnerInfo);
    setVisibleDrawer(true);
  };

  const onDrawerClose = () => {
    setVisibleDrawer(false);
  };

  const onSubmitConnectMerchants = (info: any) => {
    connectMerchants(info, partnerDrawerInfo.id);
  };

  const onOverviewButtonClick = () => {
    navigate("/dashboard/overview");
  };

  return (
    <Authorization
      allowedRoles={[
        ROLES.PARTNER,
        ROLES.EMPLOYEE,
        ROLES.EMPLOYEE,
        ROLES.ADMIN,
        ROLES.COMPLIANCE_OFFICER,
      ]}
      forbiddenFallback={<NotFound />}
    >
      <Helmet>
        <title>Partners</title>
      </Helmet>
      <PageHeader style={{ paddingLeft: 0 }} title="Partners" />
      <Filters />
      <Button
        type="primary"
        style={{ marginTop: 25, marginRight: 20 }}
        onClick={() => setVisibleCreatePartnerModal(true)}
      >
        Create partner
      </Button>
      <Button onClick={onOverviewButtonClick}>Overview</Button>

      <AppliedFilterInput />
      <Table
        isLoading={isLoading}
        tableData={data}
        pagination={isAllowPagination}
        handleLoadMorePartners={handleLoadMorePartners}
        requestStatus={status}
        onRowClick={onDrawerOpen}
      />
      <CreateForm
        visible={visibleCreatePartnerModal}
        setVisible={setVisibleCreatePartnerModal}
        onSubmit={onSubmitCreateForm}
        currencyList={dictionaryData?.currencies}
      />
      <InviteMerchantsModal
        visible={visibleInviteMerchantsModal}
        setVisible={setVisibleInviteMerchantsModal}
        onSubmit={(info) => onSubmitConnectMerchants(info)}
        merchantList={merchantsData.data}
      />
      <PartnerInfoDrawer
        visible={visibleDrawer}
        onClose={onDrawerClose}
        data={partnerDrawerInfo}
        onInviteMerchant={() => setVisibleInviteMerchantsModal(true)}
        currencyList={dictionaryData?.currencies}
        onSubmitUpdatePartner={onUpdatePartner}
      />
    </Authorization>
  );
};
