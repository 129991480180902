import { Divider, Form, Switch } from "antd";
import { IPricingModels } from "./types";
import { useEffect, useState } from "react";

export const OnlineGlobalPricingModel: React.FC<IPricingModels> = ({
  data,
  form,
  onChangeData
}) => {
  const [isSwitched, setIsSwitched] = useState<boolean>(data.PM_ONLINE_GLOBAL !== undefined ? data.PM_ONLINE_GLOBAL : false);
  const onSwitcherChange = (isChecked: boolean) => {
    form.setFieldValue("PM_ONLINE_GLOBAL", isChecked);
    onChangeData(form.getFieldsValue());
  }

  useEffect(() => {
    setIsSwitched(data.PM_ONLINE_GLOBAL);
  }, [data]);

  return (
    <>
      <Divider orientation="center" plain>
        Online Global e-Com Payments AMEX DCI JCB UPI 80+ APMs
      </Divider>
      <Form.Item labelCol={{ span: 24 }} name={"PM_ONLINE_GLOBAL"}>
        <Switch onChange={onSwitcherChange} checked={isSwitched} />
      </Form.Item>
    </>
  );
};
