import React from "react";
import { Helmet } from "react-helmet";
import { AppProvider } from "./providers/app";

function App() {
  return (
    <AppProvider>
      <div className="App">
        <Helmet>
          <title>Admin</title>
        </Helmet>
      </div>
    </AppProvider>
  );
}

export default App;
