import React from "react";
import { Styled } from "./styles";
import { CardProps } from "antd";

interface IFormCardProps extends Omit<CardProps, "size"> {
  size?: "small" | "large";
}

export const FormCard: React.FC<IFormCardProps> = (props: IFormCardProps) => {
  const { size, ...restProps } = props;
  return (
    <Styled.CardWrapper size={size} {...restProps}>
      {props.children}
    </Styled.CardWrapper>
  );
};
