import styled from "styled-components";
import { Table as AntDTable } from "antd";

const Table = styled(AntDTable)`
  td {
    vertical-align: top;
  }
`;

export const Styled = {
  Table,
};
