import React from "react";
import { Button, Form as AntForm, Form, Input, Modal } from "antd";
import { IUserResponse } from "../../data-layer/user/types/IUser";

interface ICreateNewPasswordModal {
  visibleModal: boolean;
  setVisibleModal: (visible: boolean) => void;
  currentUserInfo?: IUserResponse;
  onSetNewPassword: (
    userInfo: IUserResponse,
    newPassword: string,
    clearForm: (fields?: (string | number)[] | undefined) => void
  ) => void;
}

export const CreateNewPasswordModal: React.FC<ICreateNewPasswordModal> = ({
  visibleModal,
  setVisibleModal,
  currentUserInfo,
  onSetNewPassword,
}) => {
  const [form] = AntForm.useForm();

  return (
    <Modal
      open={visibleModal}
      title={"Create new password"}
      onCancel={() => setVisibleModal(false)}
      footer={[
        <Button
          form="createNewPasswordForm"
          key="submit"
          htmlType="submit"
          onClick={() => setVisibleModal(false)}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id={"createNewPasswordForm"}
        form={form}
        layout={"vertical"}
        onFinish={(fields) =>
          onSetNewPassword(currentUserInfo, fields?.password, form.resetFields)
        }
      >
        <Form.Item
          name={"password"}
          label={"New password"}
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input placeholder={"None"} />
        </Form.Item>
        <Form.Item
          name={"repeatPassword"}
          label={"Repeat password"}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input placeholder={"None"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
