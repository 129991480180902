import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table as AntTable,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useUpdateLocale } from "../../data-layer/i18settings/hooks/useUpdateLocales";
import { I18settingsResponse } from "../../data-layer/i18settings/types/i18settingsTypes";
import { useDeleteLocale } from "../../data-layer/i18settings/hooks/useDeleteLocale";
import { LOCALE_MAP } from "../../common/constants/constants";

interface Item extends I18settingsResponse {}

interface ITableProps {
  data: I18settingsResponse[];
  isLoading: boolean;
  handleLoadMoreLocale: () => void;
  requestStatus: string;
  pagination: boolean;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  editableComponent,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {editableComponent ? editableComponent() : inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const Table: React.FC<ITableProps> = ({
  data,
  isLoading,
  handleLoadMoreLocale,
  pagination,
  requestStatus,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const { onUpdateLocale } = useUpdateLocale();
  const { onDeleteLocale } = useDeleteLocale();

  const isEditing = (record: Item) => record.id === editingKey;

  const edit = (record: Partial<Item> & { id: string }) => {
    form.setFieldsValue({ name: "", age: "", address: "", ...record });
    setEditingKey(record.id);
  };

  const onDelete = (record: Partial<Item> & { id: string }) => {
    onDeleteLocale(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id: string) => {
    try {
      const row = (await form.validateFields()) as Item;
      const updatedRes = await onUpdateLocale(row);

      if ("data" in updatedRes) {
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      width: "25%",
      editable: true,
    },
    {
      title: "Locale",
      dataIndex: "locale",
      width: "15%",
      editable: true,
      editableComponent: () => {
        return (
          <Select placeholder={"None"}>
            {LOCALE_MAP.map((item) => (
              <Select.Option key={item.value}>{item.name}</Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Value",
      dataIndex: "value",
      width: "40%",
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Typography.Link onClick={cancel}>Cancel</Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              style={{ marginRight: 15 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => onDelete(record)}
            >
              <Typography.Link disabled={editingKey !== ""}>
                Delete
              </Typography.Link>{" "}
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        editableComponent: col?.editableComponent,
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <AntTable
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        pagination={false}
        dataSource={data}
        columns={mergedColumns}
        loading={isLoading}
        rowClassName="editable-row"
      />
      {!isLoading && data.length > 0 && pagination && (
        <Button
          onClick={handleLoadMoreLocale}
          loading={requestStatus === "pending"}
          block
          size={"large"}
          type="primary"
          style={{ marginTop: 15, marginBottom: 20 }}
        >
          Show more locale
        </Button>
      )}
    </Form>
  );
};
