import { FormInstance } from "antd";
import { IApplicationResponseData } from "data-layer/application/types/applicationTypes";

export type CurrencyType = "GBP" | "EUR" | "USD";
export enum Currency {
  GBP = "GBP",
  EUR = "EUR",
  USD = "USD",
}

export type FeeType = "BLENDED_FEE" | "DETAILED_FEE" | "DISABLED";
export enum Fee {
  BLENDED_FEE = "BLENDED_FEE",
  DETAILED_FEE = "DETAILED_FEE",
  DISABLED = "DISABLED",
}

export interface IPricingModels {
    form: FormInstance<any>;
    data: IApplicationResponseData;
    onChangeData: (data: IApplicationResponseData | null) => void
}

export interface IInStoreModel extends IPricingModels {
    inStoreFeeType: FeeType;
    handleChangeInStoreFeeType: (value: FeeType) => void;
}