import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../services/api/config";
import { IMerchantResponse } from "./types";

export const merchantApi = createApi({
  reducerPath: "merchantApi",
  baseQuery,
  endpoints: (builder) => ({
    findMerchants: builder.mutation<IMerchantResponse[], string>({
      query: (params) => ({
        url: `/merchants/find?${params}&limit=20&offset=0`,
        method: "GET",
      }),
    }),
    updateMerchantsData: builder.mutation<IMerchantResponse, IMerchantResponse>(
      {
        query: (params) => ({
          url: `/merchants/admin`,
          method: "PUT",
          body: params,
        }),
      }
    ),
    getMerchantById: builder.query<IMerchantResponse, string>({
      query: (params) => ({
        url: `/merchants/${params}`,
        method: "GET",
      }),
    }),
    findMerchantById: builder.mutation<IMerchantResponse, string>({
      query: (params) => ({
        url: `/merchants/${params}`,
        method: "GET",
      }),
    }),
    getMerchants: builder.query<IMerchantResponse[], string>({
      query: (params) => ({
        url: `/merchants/find?${params}`,
      }),
    }),
  }),
});
export const {
  useFindMerchantsMutation,
  useFindMerchantByIdMutation,
  useGetMerchantsQuery,
  useGetMerchantByIdQuery,
  useUpdateMerchantsDataMutation,
} = merchantApi;
