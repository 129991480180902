import { Form as AntForm, Select, Input, Row, Col } from "antd";
import { IMerchantOfficeDetailsFormPart } from "./types";

export const MerchantOfficeDetailsTab: React.FC<IMerchantOfficeDetailsFormPart> = ({
  countries,
  validator,
  initialData
}) => {
  return (
    <Row gutter={24}>
      <Col span={16}>
        <AntForm.Item
          rules={[validator]}
          name={["officeAddressData", "addressLine1"]}
          id={"officeAddressLine1"}
          label={"Address Line 1"}
          initialValue={initialData?.officeAddressData?.addressLine1}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["officeAddressData", "addressLine2"]}
          id={"officeAddressLine2"}
          label={"Address Line 2"}
          initialValue={initialData?.officeAddressData?.addressLine2}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["officeAddressData", "country"]}
          id={"officeCountryId"}
          label={"Country"}
          initialValue={initialData?.officeAddressData?.country}
        >
          <Select
              showSearch
              optionFilterProp="label"
              placeholder="Country"
              options={countries?.map((item) => ({
                value: item.name,
                label: item.name,
              }))}
          ></Select>
        </AntForm.Item>
        <AntForm.Item
            rules={[validator]}
            name={["officeAddressData", "county"]}
            id={"officeAddressCounty"}
            label={"County"}
            initialValue={initialData?.officeAddressData?.county}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["officeAddressData", "city"]}
          id={"officeCity"}
          label={"City"}
          initialValue={initialData?.officeAddressData?.city}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["officeAddressData", "state"]}
          id={"officeState"}
          label={"State"}
          initialValue={initialData?.officeAddressData?.state}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["officeAddressData", "postCode"]}
          id={"officePostCode"}
          label={"Post Code"}
          initialValue={initialData?.officeAddressData?.postCode}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          rules={[validator]}
          name={["officeAddressData", "postTown"]}
          id={"officePostTown"}
          label={"Post Town"}
          initialValue={initialData?.officeAddressData?.postTown}
        >
          <Input />
        </AntForm.Item>
      </Col>
    </Row>
  );
};
