import {notification} from "antd";
import {useReCreateMSAMutation} from "../api/merchantApi";

export const useReCreateMSA = () => {
    const [reCreateMSA] = useReCreateMSAMutation();

    const onReCreateMSA = (merchantId: number) => {
        reCreateMSA(merchantId).then((result) => {
            if ("data" in result) {
                notification.open({
                    message: "MSA ReCreated",
                    type: "success",
                });
            } else if (result.error) {
                notification.open({
                    message: "Something went wrong",
                    description: result.error?.data?.error,
                    type: "error",
                });
            }
        });
    };

    return {
        onReCreateMSA,
    };
};