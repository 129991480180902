import { useEmailTestMutation } from "../api/emailTestApi";
import { notification } from "antd";
import { EmailTestResponse } from "../types/emailTestTypes";


export const useEmailTest = () => {
    const [emailTest] = useEmailTestMutation();

    const onEmailTest = (
        params: EmailTestResponse,
        clearForm: (fields?: (string | number)[] | undefined) => void
    ) => {
        emailTest(params).then((result) => {
            if ("data" in result) {
                notification.open({
                    message: "Email test sent",
                    type: "success",
                });
                clearForm();
            } else if (result.error) {
                notification.open({
                    message: "Something went wrong",
                    description: result.error?.data?.error,
                    type: "error",
                });
            }
        });
    };

    return {
        onEmailTest,
    };
};
