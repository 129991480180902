import React, { useState } from "react";
import { Form } from "./Form/Form";
import { useGetMerchantById } from "../../data-layer/merchant/hooks/useGetMerchantById";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, PageHeader, Spin } from "antd";
import { history } from "../../routes/AppRouter";
import { useDictionary } from "../../data-layer/dictionary/hooks";
import { useUpdateMerchantsData } from "../../data-layer/merchant/hooks/useUpdateMerchantsData";
import { ConnectPartnerModal } from "./ConnectPartnerModal";
import { usePartners } from "../../data-layer/partner/hooks/usePartners";
import { useConnectMerchantsToPartner } from "../../data-layer/partner/hooks/useConnectMerchantsToPartner";

export const MerchantEdit: React.FC = () => {
  const [visibleConnectPartnerModal, setVisibleConnectPartnerModal] =
    useState(false);
  const { id } = useParams();

  const { connectMerchantsToPartner } = useConnectMerchantsToPartner();

  const { data } = useGetMerchantById(id || "");
  let { dictionaryData } = useDictionary();
  const { onSaveMerchantForm } = useUpdateMerchantsData();

  const partnersData = usePartners("name=&limit=1000&offset=0", []);

  const onSubmitConnectPartner = (data: any) => {
    connectMerchantsToPartner({
      merchantIds: [parseInt(id)],
      partnerId: data.partner,
    });
  };

  return (
    <>
      <Helmet>
        <title>Merchant edit</title>
      </Helmet>
      <PageHeader onBack={() => history.back()} title="Merchant edit" />
      <Button
        onClick={() => setVisibleConnectPartnerModal(true)}
        style={{ marginBottom: 15 }}
      >
        Connect with partner
      </Button>
      <ConnectPartnerModal
        visible={visibleConnectPartnerModal}
        setVisible={setVisibleConnectPartnerModal}
        onSubmit={onSubmitConnectPartner}
        partnerList={partnersData.data}
      />
      {data ? (
        <Form
          data={data}
          dictionaryData={dictionaryData}
          onUpdateMerchants={onSaveMerchantForm}
        />
      ) : (
        <div
          style={{
            display: "flex",
            height: "90%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
};
