import { useGetMerchantsQuery } from "../api/merchantApi";

export const useMerchants = (searchParams: string) => {
  const { data, error, status, isLoading, isSuccess } =
    useGetMerchantsQuery(searchParams);

  const merchantsData = data || [];

  return { data: merchantsData, error, status, isLoading, isSuccess };
};
