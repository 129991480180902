import React from "react";
import { Tag } from "antd";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import { useGetValidAppliedFilters } from "./utils/useGetValidAppliedFilters";

interface IAppliedFilters {}
export const AppliedFilters: React.FC<IAppliedFilters> = ({}) => {
  const { changeSearchParams } = useChangeSearchParams();

  const { validAppliedFilters } = useGetValidAppliedFilters();

  return (
    <>
      {validAppliedFilters.length ? (
        <div style={{ marginTop: 15 }}>
          {validAppliedFilters.map((filter) => {
            if (Array.isArray(filter.value)) {
              return filter.value.map((arrItemFilter, index) => {
                return (
                  <Tag
                    color="success"
                    key={filter.queryName + arrItemFilter}
                    closable
                    onClose={() =>
                      changeSearchParams({
                        [filter.queryName]: filter.value.filter(
                          (item: string) => item !== arrItemFilter
                        ),
                      })
                    }
                  >
                    {arrItemFilter}
                  </Tag>
                );
              });
            } else
              return (
                <Tag
                  color="success"
                  key={filter.queryName}
                  closable
                  onClose={() =>
                    changeSearchParams({
                      [filter.queryName]: "",
                    })
                  }
                >
                  {filter.value}
                </Tag>
              );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
