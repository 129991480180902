import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
} from "antd";
import { useSearchParams } from "react-router-dom";
import { useChangeSearchParams } from "../../services/searchParams/changeSearchParams";
import moment from "moment";
import { useFindMerchants } from "../../data-layer/merchant/hooks/useFindMerchants";

export const Filters: React.FC = () => {
  const { Search } = Input;
  const { Option } = Select;

  let [searchParams] = useSearchParams();
  const { changeSearchParams, setInitialParams } = useChangeSearchParams();

  const initialFilters = {
    tid: "",
    // statuses: "ACTIVATED",
    serialNumber: "",
    cctTid: "",
    limit: 10,
    offset: 0,
  };

  const statusFilter = searchParams.get("status");
  const tidFilter = searchParams.get("tid");
  const serialNumberFilter = searchParams.get("serialNumber");
  const cctTidFilter = searchParams.get("cctTid");

  const [searchTid, setSearchTid] = useState(tidFilter || undefined);
  const [searchCctTid, setSearchCctTid] = useState(cctTidFilter || undefined);
  const [searchSerialNumber, setSearchSerialNumber] = useState(
    serialNumberFilter || undefined
  );

  const handleStatusFilter = (value: string) => {
    changeSearchParams({ status: value });
  };

  const handleSearchTidFilter = (search: string) => {
    changeSearchParams({ tid: search });
  };
  const handleSearchCctTidFilter = (search: string) => {
    changeSearchParams({ cctTid: search });
  };
  const handleSearchSerialNumberFilter = (search: string) => {
    changeSearchParams({ serialNumber: search });
  };

  useEffect(() => {
    setInitialParams(initialFilters);
  }, [searchParams]);

  return (
    <>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Row gutter={20}>
                <Col span={12}>
                  <Select
                    placeholder="Filter by status"
                    allowClear
                    style={{ width: "100%" }}
                    onChange={handleStatusFilter}
                    defaultValue={statusFilter}
                    value={statusFilter || null}
                  >
                    <Option value={"ACTIVATED"}>Activated</Option>
                    <Option value={"EXTERNAL_SYSTEM_NOT_READY"}>
                      External system not ready
                    </Option>
                    <Option value={"NEW"}>New</Option>
                    <Option value={"SUSPENDED"}>Suspend</Option>
                    <Option value={"ZERO_TRANSACTION"}>Zero transaction</Option>
                  </Select>
                </Col>
                <Col span={12}>
                  <Search
                    placeholder={"Search by serial number"}
                    onSearch={handleSearchSerialNumberFilter}
                    enterButton={false}
                    defaultValue={searchSerialNumber || ""}
                    value={searchSerialNumber}
                    onChange={(e) => setSearchSerialNumber(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Row gutter={20}>
                <Col span={12}>
                  <Search
                    placeholder={"Search by tid"}
                    onSearch={handleSearchTidFilter}
                    enterButton={false}
                    defaultValue={tidFilter || ""}
                    value={searchTid}
                    onChange={(e) => setSearchTid(e.target.value)}
                  />
                </Col>
                <Col span={12}>
                  <Search
                    placeholder={"Search by cct tid"}
                    onSearch={handleSearchCctTidFilter}
                    enterButton={false}
                    defaultValue={searchCctTid || ""}
                    value={searchCctTid}
                    onChange={(e) => setSearchCctTid(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
