import { useUploadFileMutation } from "../api/sekumpulUpload";
import { UploadFile } from "antd";

export const useUpload = () => {
  const [onUpload] = useUploadFileMutation();

  const upload = (file: UploadFile) => {
    const formData = new FormData();
    formData.append("file", new Blob([file]), file.name);
    return onUpload(formData);
  };

  return {
    upload,
  };
};
