import { useDeleteDLQMessagesMutation } from "../api/systemApi";
import { notification } from "antd";

export const useDeleteDLQ = (dlqRefetch: () => void) => {
  const [deleteDLQ] = useDeleteDLQMessagesMutation();

  const onDeleteDLQ = (id: number) => {
    deleteDLQ(id).then((result) => {
      if ("data" in result) {
        notification.open({
          message: "Deleted successfully",
          type: "success",
        });
        dlqRefetch();
      } else if (result.error) {
        notification.open({
          message: "Something went wrong",
          description: result.error?.data?.error,
          type: "error",
        });
      }
    });
  };

  return {
    onDeleteDLQ,
  };
};
