import React from "react";
import { Form, Modal, Button, Space, List, Checkbox } from "antd";
import { IMerchantResponse } from "../../data-layer/merchant/types/merchantTypes";
import { Styled } from "./styled";

interface IInviteMerchantModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSubmit: (data: any) => void;
  merchantList: IMerchantResponse[];
}

export const InviteMerchantsModal: React.FC<IInviteMerchantModal> = ({
  visible,
  setVisible,
  onSubmit,
  merchantList,
}) => {
  return (
    <Modal
      open={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      title="Invite merchants"
    >
      <Form
        onFinish={(data) => {
          onSubmit(data);
          setVisible(false);
        }}
      >
        <List
          itemLayout="horizontal"
          dataSource={merchantList}
          renderItem={(item) => (
            <List.Item>
              <Styled.InviteModalItemListWrapper>
                <Form.Item name={item.id} valuePropName="checked">
                  <Checkbox style={{ marginRight: 10 }} />
                </Form.Item>
                <List.Item.Meta
                  title={<a href="https://ant.design">{item.merchantName}</a>}
                  description={`${item.kind}, ${item.email}`}
                />
              </Styled.InviteModalItemListWrapper>
            </List.Item>
          )}
        />
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Space>
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button type="primary" htmlType={"submit"}>
              Invite
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};
