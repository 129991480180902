import React from "react";
import { Modal } from "antd";
import { Styled } from "../styled";
import { Popover } from "../../../components/Popover";
import {
  Authorization,
  ROLES,
} from "../../../providers/authorization/Authorization";

interface IActionButtons {
  onEdit: () => void;
  onCancel: () => void;
  onRejectButtonClick: () => void;
  onApprove: () => void;
  onSave: () => void;
  isDisableForm: boolean;
  profileStatus?: string;
  persistLoading: boolean;
  rejectLoading: boolean;
  verifiedEmail: boolean;
}

export const ActionButtons: React.FC<IActionButtons> = ({
  onEdit,
  onCancel,
  onSave,
  onRejectButtonClick,
  isDisableForm,
  onApprove,
  profileStatus,
  persistLoading,
  rejectLoading,
  verifiedEmail,
}) => {
  const approveButtonTooltipText = verifiedEmail
    ? `Onboarding status is ${profileStatus}`
    : "Email is not verified";

  const isEnableApprove =
    Boolean(verifiedEmail) && Boolean(profileStatus === "UNDER_REVIEW");

  return (
    <>
      <Authorization
        allowedRoles={[
          ROLES.ADMIN,
          ROLES.COMPLIANCE_OFFICER,
          ROLES.PARTNER,
          ROLES.MERCHANT,
          ROLES.EMPLOYEE,
        ]}
      >
        <div>
          {isDisableForm && !persistLoading ? (
            <>
              <Styled.ActionButton
                onClick={onEdit}
                disabled={
                  profileStatus === "APPROVED" || profileStatus === "REJECTED"
                }
              >
                Edit
              </Styled.ActionButton>
              <Popover
                content="Onboarding rejected"
                trigger="hover"
                disabled={profileStatus !== "REJECTED"}
              >
                <Styled.RejectButton
                  onClick={() => onRejectButtonClick()}
                  loading={rejectLoading}
                  disabled={
                    profileStatus === "REJECTED" || profileStatus === "APPROVED"
                  }
                >
                  Reject
                </Styled.RejectButton>
              </Popover>
            </>
          ) : (
            <>
              <Styled.ActionButton onClick={onCancel}>
                Cancel
              </Styled.ActionButton>
              <Styled.ActionButton
                loading={persistLoading}
                onClick={onSave}
                type={"primary"}
              >
                Save
              </Styled.ActionButton>
            </>
          )}
          <Popover
            content={approveButtonTooltipText}
            trigger={"hover"}
            disabled={isEnableApprove}
          >
            <Styled.ApproveButton
              onClick={onApprove}
              disabled={!isEnableApprove}
            >
              Approve and send email with commercials
            </Styled.ApproveButton>
          </Popover>
        </div>
      </Authorization>
    </>
  );
};
